import { useFirebaseApp } from "reactfire";
import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from "react";
import { Transition, Popover } from "@headlessui/react";
import { Link } from "react-router-dom";

import { useProfile } from "../../hooks/queries/useProfile";
import { ChevronDown } from "react-feather";
import { useSwitchOrganization } from "../../hooks/mutations/useSwitchOrganization";

import CreateOrganizationModal from "./CreateOrganizationModal";
import { useLogout } from "../../hooks/mutations/useLogout";
import { useRecoilState, useRecoilValue } from "recoil";
import { isEditorActiveState } from "../../layouts/Editor/editor.atom";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";

const Header = () => {
  const firebase = useFirebaseApp();
  const isEditorActive = useRecoilValue(isEditorActiveState);
  const [organizationId, setOrganizationId] = useRecoilState(
    activeOrganizationIdState
  );

  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const { data: profile, isFetching: isFetchingProfile } = useProfile();
  const { mutate: switchOrganization } = useSwitchOrganization();
  const { mutate } = useLogout();

  const handleLogout = useCallback(
    async (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      mutate();
    },
    [firebase]
  );

  useEffect(() => {
    if (profile && !organizationId) {
      if (sessionStorage.getItem("activeOrganizationId")) {
        setOrganizationId(
          sessionStorage.getItem("activeOrganizationId") as string
        );
      } else {
        setOrganizationId(profile.organizations?.[0]?.organizationId);
      }
    }
  }, [profile]);

  if (isEditorActive) {
    return null;
  }

  return (
    <>
      <nav className="bg-gray-800 fixed w-full z-10 top-0">
        <Popover>
          <div className="px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img className="block h-6 w-auto" src="/logo.svg" />
                  <span className="text-lg ml-3 text-white tracking-wider">
                    Export SDK
                  </span>
                </div>
              </div>
              <Popover.Group as="nav" className="flex items-center">
                <Popover className="relative">
                  <Popover.Button>
                    <span className="sr-only">Open organization menu</span>
                    {profile?.organizations.map(
                      (organization) =>
                        organization.organizationId === organizationId && (
                          <div
                            key={organization.organizationId}
                            className="flex space-x-2 text-white items-center"
                          >
                            <span className="font-normal">
                              {organization.organization.name}
                            </span>
                            <ChevronDown
                              aria-hidden="false"
                              className="h-5 w-5"
                            />
                          </div>
                        )
                    )}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 px-2 w-64 sm:px-0 lg:ml-0 right-0">
                      <div className="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="border-b border-gray-200">
                          {profile?.organizations.map(
                            (organization) =>
                              organization.organizationId !==
                                organizationId && (
                                <Popover.Button
                                  className="w-full text-left"
                                  key={organization.organizationId}
                                >
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      switchOrganization(
                                        organization.organizationId
                                      );
                                    }}
                                    href="#"
                                    className="block px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-100"
                                  >
                                    {organization.organization.name}
                                  </a>
                                </Popover.Button>
                              )
                          )}
                        </div>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal();
                          }}
                          className="block px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-100"
                        >
                          Create new organization
                        </a>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
                <div className="w-px h-6 bg-gray-600 mx-4"></div>
                <Popover className="relative">
                  <Popover.Button>
                    <span className="sr-only">Open user menu</span>
                    <div className="flex items-center justify-center h-8 w-8 rounded-full bg-gray-200">
                      <span className="text-gray-700 font-bold">
                        {profile?.firstName?.[0]}
                      </span>
                    </div>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 px-2 w-64 sm:px-0 lg:ml-0 right-0">
                      <div className="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="px-4 py-2 border-b border-gray-200">
                          <p className="text-sm font-medium leading-5 text-gray-900">
                            {profile?.firstName} {profile?.lastName}
                          </p>
                          <p className="text-sm font-normal leading-5 text-gray-900">
                            {profile?.email}
                          </p>
                        </div>
                        <div className="border-b border-gray-200">
                          <Popover.Button className="text-left w-full">
                            <Link
                              to="/account-settings"
                              className="block px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-100"
                            >
                              Account Settings
                            </Link>
                          </Popover.Button>
                        </div>
                        <a
                          href="/#"
                          className="block px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          onClick={handleLogout}
                        >
                          Sign out
                        </a>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </Popover.Group>
            </div>
          </div>
        </Popover>
      </nav>

      <CreateOrganizationModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};

export default Header;
