import { useQuery } from "react-query";
import { useSigninCheck, useUser } from "reactfire";
import { Profile } from "../../models/api.model";
import { range } from "lodash";
import { useIdToken } from "./useIdToken";

const getProfile = async (token: string) => {
  let json: any;

  for (const interval of range(5)) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    json = await response.json();
    if (json.user) {
      break;
    }
    await new Promise((resolve, reject) =>
      setInterval(() => resolve(undefined), interval * 1000)
    );
  }

  return json.user as Profile;
};

export const useProfile = () => {
  const user = useUser();
  const { data: token } = useIdToken();
  const { data: signInCheckResult } = useSigninCheck();

  return useQuery(
    ["profile", user?.data?.uid],
    () => getProfile(token as string),
    {
      enabled: !!user?.data?.uid && signInCheckResult.signedIn && !!token,
      staleTime: Infinity,
    }
  );
};
