import { ChevronLeft, ChevronRight } from "react-feather";

export default function Pagination({
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
}: any) {
  return (
    <div className="flex items-center space-x-3">
      <button
        className="focus:outline-none flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        <ChevronLeft size={14} />
        Previous
      </button>
      <button
        className="focus:outline-none flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        Next
        <ChevronRight size={14} />
      </button>
    </div>
  );
}
