import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Check } from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Clipboard } from "react-feather";
import { motion } from "framer-motion";
import { useToggle } from "react-use";
import { useEffect } from "react";
import { delay } from "lodash";
import ReactTooltip from "react-tooltip";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  value: string;
}

const APIKeyModal = ({ isOpen, closeModal, value }: ModalProps) => {
  const [isCopied, toggleCopied] = useToggle(false);

  useEffect(() => {
    if (isCopied) {
      delay(() => toggleCopied(false), 400);
    }
  }, [isCopied]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-90"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:max-w-sm sm:align-middle p-6 my-8 transition-all transform bg-white shadow-xl rounded-md">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <Check className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    API key created!
                  </Dialog.Title>
                  <div className="mt-2 text-sm space-y-2">
                    <p className="font-light text-gray-500"><span className="font-medium">This API key will only be visible once.</span> Please copy this key and save it somewhere safe.</p>
                  </div>
                </div>
                <div className="justify-center">
                  <p className="mt-6 text-sm font-medium bg-gray-100 p-1">{value}</p>
                </div>
                <CopyToClipboard text={value} onCopy={toggleCopied}>
                  <motion.button
                    className="mt-4 inline-flex justify-center items-center space-x-1 w-full rounded-md text-sm px-4 py-2 bg-red-500 font-medium text-white hover:bg-red-400 focus:outline-none"
                    animate={{ opacity: isCopied ? 0.5 : 1 }}
                    data-tip="copy"
                    data-for="copy"
                  >
                    <span>Copy to clipboard</span>
                    <Clipboard size={16} />
                  </motion.button>
                </CopyToClipboard>
                <ReactTooltip
                  id="copy"
                  effect="solid"
                  place="top"
                  type="dark"
                  backgroundColor="rgba(21, 21, 21, .7)"
                  className="font-light"
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default APIKeyModal;