import { useState } from "react";
import { Link } from "react-router-dom";
import { useLogin } from "../../hooks/mutations/useLogin";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutate, error } = useLogin();

  return (
    <section className="bg-gray-100 h-screen flex items-center justify-center">
      <div className="container px-4 mx-auto">
        <a
          href="/"
          title="ExportSDK Home Page"
          className="flex items-center justify-start sm:justify-center"
        >
          <svg
            width="60"
            height="28"
            viewBox="0 0 238 124"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M237.541 89.8852C237.541 108.562 222.369 123.693 203.641 123.693H45.8725C20.6248 123.693 0 103.125 0 77.9462C0 52.6495 20.5063 32.1994 45.8725 32.1994C49.547 32.1994 53.1031 32.6723 56.5405 33.3815C56.6591 33.1451 56.6591 32.9087 56.7776 32.6723C59.5039 24.6341 64.1267 16.4777 69.6978 10.0945C82.9735 -5.15444 110.118 -1.72639 111.422 21.2061V61.8698H96.7234C96.6049 61.8698 96.6049 61.8698 96.4864 61.8698C96.3678 61.8698 96.3678 61.8698 96.2493 61.8698C93.9971 61.988 92.3377 63.7611 92.3377 66.0071C92.3377 67.071 92.8118 68.1348 93.523 68.8441L93.6415 68.9623L119.126 94.1407C119.837 94.85 120.904 95.3228 122.09 95.3228C123.275 95.3228 124.342 94.85 125.053 94.1407L150.538 68.9623C151.249 68.253 151.723 67.1892 151.723 66.0071C151.723 63.7611 149.945 61.988 147.811 61.8698C147.693 61.8698 147.693 61.8698 147.574 61.8698C147.456 61.8698 147.456 61.8698 147.337 61.8698H132.758V22.5063C132.758 11.9858 140.225 3.00195 150.538 0.519568C162.154 -2.19923 172.585 6.19357 178.749 15.4138C186.453 26.8801 191.076 41.0651 191.076 54.7773C191.076 55.9594 191.076 57.2597 190.958 58.4417C194.869 56.905 199.136 55.9594 203.641 55.9594C210.753 55.9594 217.272 58.0871 222.725 61.8698C231.615 68.0166 237.541 78.3008 237.541 89.8852Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0.00995682"
                y1="61.8757"
                x2="237.508"
                y2="61.8757"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FE4F4F" />
                <stop offset="1" stopColor="#F5716D" />
              </linearGradient>
            </defs>
          </svg>

          <span className="ml-2">ExportSDK</span>
        </a>
        <div className="w-full px-0 pt-5 pb-6 mx-auto mt-4 mb-0 space-y-4 bg-transparent border-0 border-gray-200 rounded-lg md:bg-white md:border sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 md:px-6 sm:mt-8 sm:mb-5">
          <h1 className="mb-8 text-xl font-bold text-left text-gray-700 sm:text-center">
            Log in to your account
          </h1>
          <form
            className="pb-1 space-y-8"
            onSubmit={(e) => {
              e.preventDefault();
              mutate({ email, password });
            }}
          >
            <label className="block">
              <span className="block mb-2 text-xs font-medium text-gray-700">
                Your Email
              </span>
              <input
                className="py-2 px-4 focus:ring-red-200 focus:border-red-200 w-full sm:text-sm border-gray-300 rounded-md"
                type="email"
                placeholder="Ex. james@bond.com"
                inputMode="email"
                required
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </label>
            <label className="block">
              <span className="block mb-2 text-xs font-medium text-gray-700">
                Your Password
              </span>
              <input
                className="py-2 px-4 focus:ring-red-200 focus:border-red-200 w-full sm:text-sm border-gray-300 rounded-md"
                type="password"
                required
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
              />
            </label>
            <div className="flex items-center justify-between">
              <input
                type="submit"
                className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                value="Login"
              />
            </div>
          </form>
        </div>
        <div className="mb-5 text-red-600 text-center">
          {(error as Error)?.message}
        </div>
        <p className="mb-4 space-y-2 text-sm text-left text-gray-600 sm:text-center sm:space-y-0">
          <Link
            to="/forgot-password"
            className="w-full btn btn-sm btn-link sm:w-auto ml-2 text-gray-600"
          >
            Forgot password
          </Link>
          <Link
            to="/signup"
            className="w-full btn btn-sm btn-link sm:w-auto ml-2 text-blue-500"
          >
            Create an account
          </Link>
        </p>
      </div>
    </section>
  );
};

export default Login;
