import { some } from "lodash";
import { View, Text, ViewStyle } from "react-native";

const activeStyles = {
  borderColor: "#F87171",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
} as ViewStyle;

const hoveredStyles = {
  borderColor: "#F59E0B",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
} as ViewStyle;

const additionalSelectedStyles = {
  borderColor: "#FCA5A5",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
} as ViewStyle;

const addStyles = {
  borderColor: "#10B981",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
} as ViewStyle;

const emptyStyles = {
  borderColor: "#E5E7EB",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
} as ViewStyle;

const dropStyles = {
  borderColor: "#3B82F6",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
} as ViewStyle;

const gridStyles = {
  borderColor: "#93C5FD",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
} as ViewStyle;

interface UserActiveProps {
  isHovered?: boolean;
  isActive?: boolean;
  isAdd?: boolean;
  isAdditional?: boolean;
  isEmpty?: boolean;

  isPushOutside?: boolean;
  isParent?: boolean;
}

export const UserActive = ({
  isHovered,
  isActive,
  isAdd,
  isAdditional,
  isEmpty,
  isPushOutside,
  isParent,
}: UserActiveProps) => {
  if (!some([isHovered, isActive, isAdd, isAdditional, isEmpty])) {
    return null;
  }

  return (
    <View
      //@ts-ignore
      className="pointer-events-none"
      style={[
        {
          position: "absolute",
          top: isPushOutside ? -2 : 0,
          left: isPushOutside ? -2 : 0,
          bottom: isPushOutside ? -2 : 0,
          right: isPushOutside ? -2 : 0,
          zIndex: -1,
        },
        // isParent && { margin: -1 },
        isEmpty && emptyStyles,
        isHovered && hoveredStyles,
        isActive && activeStyles,
        isAdd && addStyles,
        isAdditional && additionalSelectedStyles,
      ]}
    ></View>
  );
};
