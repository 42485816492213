import { motion, AnimatePresence } from "framer-motion";
import { UseMutateFunction } from "react-query";
import { Dispatch, useEffect, useState } from "react";
import { XCircle } from "react-feather";
import { GenericButton } from "../../components/GenericButton";

interface RenameTemplateModalProps {
  isVisible: boolean;
  setActiveRename: Dispatch<any>;
  activeRename: any;
  handleUpdate: UseMutateFunction<any, unknown, any, unknown>;
}

const RenameTemplateModal = ({
  isVisible,
  setActiveRename,
  activeRename,
  handleUpdate,
}: RenameTemplateModalProps) => {
  const [localName, updateLocalName] = useState("");

  useEffect(() => {
    if (isVisible) {
      updateLocalName(activeRename?.name);
    }
  }, [isVisible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-10"
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.a
            className="fixed top-0 bottom-0 left-0 right-0 bg-gray-700 z-20"
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: {
                opacity: 0.5,
              },
            }}
            onClick={() => setActiveRename(undefined)}
          />
          <motion.div
            className="w-1/3 max-w-2xl bg-white rounded-lg shadow-lg z-30"
            variants={{
              visible: {
                y: 0,
                opacity: 1,
              },
              hidden: {
                y: "50%",
                opacity: 0,
              },
            }}
          >
            <div className="flex items-center justify-between bg-gray-50 h-12 rounded-t-lg px-4">
              <h3 className="text-lg text-gray-600">Rename template</h3>
              <button
                className="focus:outline-none"
                onClick={() => setActiveRename(undefined)}
              >
                <XCircle size={20} className="text-gray-500" />
              </button>
            </div>

            <div className="p-4">
              <input
                type="text"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-1/2 sm:text-sm border-gray-300 rounded-md"
                value={localName}
                onChange={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  updateLocalName(evt.target.value);
                }}
              />

              <div className="flex items-center mt-8">
                <GenericButton
                  onClick={() =>
                    handleUpdate(
                      { updatedName: localName, activeRename },
                      { onSuccess: (data: any) => setActiveRename(undefined) }
                    )
                  }
                >
                  Update
                </GenericButton>
                <button
                  className="inline-flex justify-center py-2 px-4 ml-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                  onClick={() => setActiveRename(undefined)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RenameTemplateModal;
