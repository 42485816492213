import { useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/outlined/Search.svg";

interface Props {
  onChangeText: any;
  placeholder?: string;
}

export const SearchInput = ({ onChangeText, placeholder }: Props) => {
  const onChange = useCallback((event) => onChangeText(event.target.value), [
    onChangeText,
  ]);
  return (
    <div className="relative text-gray-500 bg-white py-1 px-2 rounded-md">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <SearchIcon style={{ opacity: 0.5 }} />
      </span>
      <input
        type="search"
        name="q"
        className="py-2 pr-5 text-sm text-gray-800 border-0 focus:ring-0 bg-white pl-10 focus:outline-none"
        placeholder={placeholder ?? "Search..."}
        onChange={onChange}
        defaultValue=""
      />
    </div>
  );
};
