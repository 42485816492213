import { useMemo, useState } from "react";
import { ReactComponent as Delete } from "../../assets/icons/outlined/Delete.svg";
import { PlusCircle } from "react-feather";

import { GenericTable } from "../../components/GenericTable";

import InviteModal from "./InviteModal";
import { useToggle } from "react-use";
import { useOrganizationInvites } from "../../hooks/queries/useOrganizationInvites";
import dayjs from "dayjs";
import { useDeleteInvite } from "../../hooks/mutations/useDeleteInvite";
import { useOrganization } from "../../hooks/queries/useOrganization";
import { User } from "../../models/api.model";
import { useDeleteUserFromOrg } from "../../hooks/mutations/useDeleteUserFromOrg";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../Home/home.atom";
import { useProfile } from "../../hooks/queries/useProfile";
import { useIsOrganizationOwner } from "../../hooks/queries/useIsOrganizationOwner";

const ACCOUNT_OWNER_COLUMNS = [
  {
    id: "name",
    Header: "Name",
    accessor: (originalRow: User) =>
      `${originalRow?.firstName ?? ""} ${originalRow?.lastName ?? ""}`,
  },
  { id: "email", Header: "email", accessor: "email" },
];

const COLUMNS = [
  {
    id: "name",
    Header: "Name",
    accessor: (originalRow: any) =>
      `${originalRow.firstName ?? ""} ${originalRow.lastName ?? ""}`,
  },
  { id: "email", Header: "email", accessor: "email" },
  {
    id: "actions",
    accessor: (originalRow: any) => originalRow,
    Cell: ({ cell: { value } }: any) => {
      const { mutate } = useDeleteUserFromOrg();
      const { data: organization } = useOrganization();
      const { data: profile } = useProfile();
      const organizationId = useRecoilValue(
        activeOrganizationIdState
      ) as string;
      const owner = organization?.users.find(
        () => profile?.id === organization.ownerId
      );
      return (
        <div className="flex items-center justify-end">
          {!!owner && (
            <Delete
              className="hover:text-gray-900 cursor-pointer mr-4"
              height={20}
              width={20}
              onClick={() => mutate({ organizationId, userId: value.id })}
            />
          )}
        </div>
      );
    },
  },
];

const INVITE_COLUMNS = [
  { id: "email", Header: "email", accessor: "email" },
  {
    id: "inviteeFirstName",
    Header: "Invited by",
    accessor: "invitedBy.firstName",
  },
  {
    id: "createdAt",
    Header: "Invited date",
    accessor: "createdAt",
    Cell: ({ value }: any) => `${dayjs(value).format("LL")}`,
  },
  {
    id: "actions",
    accessor: (originalRow: any) => originalRow,
    Cell: ({ cell: { value } }: any) => {
      const { mutate } = useDeleteInvite();
      return (
        <div className="flex items-center justify-end">
          <Delete
            className="hover:text-gray-900 cursor-pointer mr-4"
            height={20}
            width={20}
            onClick={() => mutate({ inviteId: value.id })}
          />
        </div>
      );
    },
  },
];

const Collaborators = () => {
  const { data: organization } = useOrganization();
  const { data: invites } = useOrganizationInvites();
  const isOrgOwner = useIsOrganizationOwner();
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const ownerList = useMemo(() => {
    if (!organization) {
      return [];
    }

    const owner = organization.users.find(
      (user) => user.userId === organization.ownerId
    );

    return [owner].filter(Boolean).map((userObj) => userObj?.user);
  }, [organization]);

  const collaboratorsList = useMemo(() => {
    if (!organization) {
      return [];
    }
    return organization.users
      .filter((user) => user.userId !== organization.ownerId)
      .map((userObj) => userObj.user);
  }, [organization]);

  return (
    <>
      <GenericTable
        columns={ACCOUNT_OWNER_COLUMNS}
        data={ownerList}
        headerText="Account Owner"
      />

      <div className="mb-10" />

      <GenericTable
        columns={COLUMNS}
        data={collaboratorsList}
        headerText="Additional Users"
      />

      {invites?.length ? (
        <>
          <div className="mb-10" />

          <GenericTable
            columns={INVITE_COLUMNS}
            data={invites || []}
            headerText="Invites"
          />
        </>
      ) : null}

      {isOrgOwner && (
        <div className="px-6 mt-4">
          <button
            className="focus:outline-none flex space-x-1 items-center text-blue-500"
            onClick={openModal}
          >
            <PlusCircle size={16} />
            <span>Invite a collaborator</span>
          </button>
        </div>
      )}

      <InviteModal closeModal={closeModal} isOpen={isOpen} />
    </>
  );
};

export default Collaborators;
