import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
  useSigninCheck,
  useUser,
} from "reactfire";
import { RecoilRoot, useRecoilValue } from "recoil";
import { ThemeProvider } from "theme-ui";
import Header from "./components/Header";
import NoOrganizationModal from "./components/NoOrganizationModal";
import { RedirectComponent } from "./components/RedirectComponent";
import SideNavigation from "./components/SideNavigation";
import { VerifyEmail } from "./components/VerifyEmail";
import WelcomeModal from "./components/WelcomeModal";
import { useProfile } from "./hooks/queries/useProfile";
import Collaborators from "./layouts/Collaborators";
import DebugConsole from "./layouts/DebugConsole";
import Editor from "./layouts/Editor";
import { isEditorActiveState } from "./layouts/Editor/editor.atom";
import ForgotPassword from "./layouts/ForgotPassword";
import Home from "./layouts/Home";
import Login from "./layouts/Login";
import Account from "./layouts/Settings/Account";
import Keys from "./layouts/Settings/Keys";
import Organization from "./layouts/Settings/Organization";
import Signup from "./layouts/Signup";
import Manage from "./layouts/Subscription/Manage";
import Plan from "./layouts/Subscription/Plan";
import Templates from "./layouts/Templates";
import theme from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main = () => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const isEditorActive = useRecoilValue(isEditorActiveState);
  const { data: profile } = useProfile();
  const { data: user } = useUser();

  if (status === "loading" || (signInCheckResult.signedIn && !profile)) {
    return <span>loading...</span>;
  }

  if (signInCheckResult.signedIn && profile) {
    if (!user?.emailVerified) {
      return (
        <>
          <BrowserRouter>
            <Routes>
              <Route path="verify-email" element={<VerifyEmail />} />
              <Route
                path="*"
                element={<RedirectComponent to="verify-email" />}
              />
            </Routes>
          </BrowserRouter>
        </>
      );
    }

    return (
      <>
        <BrowserRouter>
          <div className="bg-gray-50 flex flex-col">
            <Header />
            <SideNavigation />
            <div
              className={`fixed ${
                isEditorActive ? "left-0" : "left-0 sm:left-60"
              } ${isEditorActive ? "top-0" : "top-16"} bottom-0 right-0 ${
                isEditorActive ? "overflow-y-hidden" : "overflow-y-auto"
              } bg-gray-50`}
            >
              {/* <div className="bg-white h-80 w-full" /> */}
              <div className={isEditorActive ? "p-0" : "p-8 min-w-full"}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="templates" element={<Templates />} />
                  <Route path="editor/:id" element={<Editor />} />
                  <Route path="console" element={<DebugConsole />} />
                  <Route path="collaborators" element={<Collaborators />} />
                  <Route path="account-settings" element={<Account />} />
                  <Route path="settings">
                    <Route index element={<Organization />} />
                    <Route path="keys" element={<Keys />} />
                  </Route>
                  <Route path="subscription">
                    <Route index element={<Plan />} />
                    <Route path="manage" element={<Manage />} />
                  </Route>

                  <Route path="*" element={<RedirectComponent to="/" />} />
                </Routes>
              </div>
            </div>
          </div>
        </BrowserRouter>

        <NoOrganizationModal />
        <WelcomeModal />
      </>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<RedirectComponent to="login" />} />
      </Routes>
    </BrowserRouter>
  );
};

const App = () => {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);
  const functions = getFunctions(app);

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functions}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <RecoilRoot>
                <Suspense fallback={<div />}>
                  <Main />
                  <Toaster
                    position="bottom-center"
                    toastOptions={{
                      // Define default options
                      duration: 5000,
                      style: {
                        background: "#363636",
                        color: "#fff",
                      },
                      // Default options for specific types
                      success: {
                        duration: 3000,
                        theme: {
                          primary: "green",
                          secondary: "black",
                        },
                      },
                    }}
                  />
                </Suspense>
              </RecoilRoot>
            </ThemeProvider>

            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </FunctionsProvider>
      </AuthProvider>
    </FirestoreProvider>
  );
};

export default App;
