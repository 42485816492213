import { useMutation, useQueryClient } from "react-query";
import { useProfile } from "../queries/useProfile";
import { Profile, User } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const updateProfile = async (
  token: string,
  profile: Profile | undefined,
  update: Partial<User>
): Promise<User> => {
  if (!profile) {
    throw Error("No found profile.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users/${profile.id}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    }
  );

  const json = await response.json();
  return json.user;
};

export const useUpdateProfile = () => {
  const { data: token } = useIdToken();
  const { data: profile } = useProfile();
  const queryClient = useQueryClient();

  return useMutation(
    (update: any) => updateProfile(token as string, profile, update),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("profile");
      },
    }
  );
};
