import { Info } from "react-feather";
import { useRecoilState } from "recoil";
import { set } from "monolite";

import TBLRGrid from "../../../components/TBLRGrid";
import EditorColorPicker from "../../../components/EditorColorPicker";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { elementPageSettingsState } from "../editor.atom";

const Page = () => {
  const [pageSettings, updatePageSettings] = useRecoilState(
    elementPageSettingsState
  );

  return (
    <EditorAccordion label="Page Settings">
      <div className="p-4">
        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Padding</span>
          <EditorInfo helpLabel="Page Padding" />
        </div>

        <TBLRGrid
          topValue={pageSettings.paddingTop}
          leftValue={pageSettings.paddingLeft}
          rightValue={pageSettings.paddingRight}
          bottomValue={pageSettings.paddingBottom}
          handleTop={(value) =>
            updatePageSettings(set(pageSettings, ["paddingTop"], value))
          }
          handleLeft={(value) =>
            updatePageSettings(set(pageSettings, ["paddingLeft"], value))
          }
          handleRight={(value) =>
            updatePageSettings(set(pageSettings, ["paddingRight"], value))
          }
          handleBottom={(value) =>
            updatePageSettings(set(pageSettings, ["paddingBottom"], value))
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Background Color</span>
          <EditorInfo helpLabel="Page Background Color" />
        </div>

        <EditorColorPicker
          value={
            pageSettings?.backgroundColor
              ? pageSettings?.backgroundColor.replace("#", "")
              : ""
          }
          onChange={(value) =>
            updatePageSettings(
              set(
                pageSettings,
                ["backgroundColor"],
                value !== "transparent" ? `#${value}` : value
              )
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Defaults</span>
          <EditorInfo helpLabel="Page Defaults" />
        </div>

        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400 font-light">Font family</span>
          <select
            value={pageSettings.fontFamily ?? ""}
            onChange={(evt) =>
              updatePageSettings(
                set(pageSettings, ["fontFamily"], evt.target.value)
              )
            }
            className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
          >
            <option value="Abril Fatface">Abril Fatface</option>
            <option value="Alegreya">Alegreya</option>
            <option value="Archivo">Archivo</option>
            <option value="Arvo">Arvo</option>
            <option value="B612">B612</option>
            <option value="BioRhyme">BioRhyme</option>
            <option value="Cairo">Cairo</option>
            <option value="Cardo">Cardo</option>
            <option value="Concert One">Concert One</option>
            <option value="Cormorant">Cormorant</option>
            <option value="Crimson Text">Crimson Text</option>
            <option value="Exo 2">Exo 2</option>
            <option value="Fira Sans">Fira Sans</option>
            <option value="Fjalla One">Fjalla One</option>
            <option value="Frank Ruhl Libre">Frank Ruhl Libre</option>
            <option value="IBM Plex Serif">IBM Plex Serif</option>
            <option value="Karla">Karla</option>
            <option value="Lato">Lato</option>
            <option value="Lora">Lora</option>
            <option value="Merriweather">Merriweather</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Noto Sans">Noto Sans</option>
            <option value="Old Standard TT">Old Standard TT</option>
            <option value="Open Sans">Open Sans</option>
            <option value="Oswald">Oswald</option>
            <option value="Oxygen">Oxygen</option>
            <option value="Playfair Display">Playfair Display</option>
            <option value="Poppins">Poppins</option>
            <option value="PT Sans">PT Sans</option>
            <option value="PT Serif">PT Serif</option>
            <option value="Rakkas">Rakkas</option>
            <option value="Roboto">Roboto</option>
            <option value="Rubik">Rubik</option>
            <option value="Source Sans Pro">Source Sans Pro</option>
            <option value="Spectral">Spectral</option>
            <option value="Titillium Web">Titillium Web</option>
            <option value="Ubuntu">Ubuntu</option>
            <option value="Varela">Varela</option>
            <option value="Vollkorn">Vollkorn</option>
            <option value="Work Sans">Work Sans</option>
            <option value="Yatra One">Yatra One</option>
          </select>
        </div>
      </div>
    </EditorAccordion>
  );
};

export default Page;
