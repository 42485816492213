import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { ApiKey } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getApiKeys = async (
  token: string,
  organizationId: string | undefined
): Promise<ApiKey[]> => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/api-keys`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.apiKeys;
};

export const useApiKeys = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useQuery(
    ["api-keys", organizationId],
    () => {
      return getApiKeys(token as string, organizationId);
    },
    { enabled: !!organizationId && !!token }
  );
};
