import { Lock, Unlock, Move, Trash } from "react-feather";
import { motion, useAnimation } from "framer-motion";
import { range, sortBy, isNumber, round } from "lodash";
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilCallback,
} from "recoil";
import { set } from "monolite";
import {
  activeElementIdState,
  addElementIdState,
  elementLayoutState,
  elementsDataState,
  elementsLayoutState,
  elementsState,
  flatElementsState,
  flatElementState,
  dropElementIdState,
  elementDataState,
  globalFloatingGridState,
  elementPageSettingsState,
  activeElementTypeState,
  elementParentLayoutState,
  globalZoomState,
  isUserActionState,
} from "./editor.atom";
import { deleteNode, floatAdjust } from "../../utils/editor.util";
import { useToggle } from "react-use";
import { useMemo, useState } from "react";
import { TreeItemTypes } from "../../models/editor.model";

const startDimension = (
  object: any,
  parent: any,
  side: "height" | "width",
  zoomRatio: number
) => {
  const elSide = object?.[side];

  if (isNumber(elSide)) {
    return elSide;
  }

  if (elSide?.includes?.("%")) {
    const parentSide = parent[side];
    return ((Number(elSide.replace("%", "")) / 100) * parentSide) / zoomRatio;
  }

  return 0;
};

export const ActiveFloatingToolbar = () => {
  const globalGrid = useRecoilValue(globalFloatingGridState);
  const pageSettings = useRecoilValue(elementPageSettingsState);
  const [activeElementId, updateActiveElementId] =
    useRecoilState(activeElementIdState);
  const elementLayout = useRecoilValue(elementLayoutState(activeElementId));
  const element = useRecoilValue(flatElementState(activeElementId));
  const mainLayout = useRecoilValue(elementLayoutState("*"));
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );
  const updateElements = useSetRecoilState(elementsState);
  const updateElementsData = useSetRecoilState(elementsDataState);
  const globalZoom = useRecoilValue(globalZoomState);

  const animateDrag = useAnimation();
  const animatePlaceholder = useAnimation();
  const animateResizeX = useAnimation();
  const animateResizeY = useAnimation();
  const [isDragging, toggleDragging] = useToggle(false);
  const [isResizing, toggleResizing] = useToggle(false);
  const itemY = useMemo(
    () =>
      round(
        (elementLayout.top - mainLayout.top - pageSettings.paddingTop) /
          globalGrid.gridHeight,
        2
      ),
    [
      elementLayout.top,
      mainLayout.top,
      pageSettings.paddingTop,
      globalGrid.gridHeight,
    ]
  );
  const itemX = useMemo(
    () =>
      round(
        (elementLayout.left - mainLayout.left - pageSettings.paddingLeft) /
          globalGrid.gridWidth,
        2
      ),
    [
      elementLayout.left,
      mainLayout.left,
      pageSettings.paddingLeft,
      globalGrid.gridWidth,
    ]
  );
  const itemWidth = useMemo(
    () => round(elementLayout.width / globalGrid.gridWidth, 2),
    [elementLayout.width, globalGrid.gridWidth]
  );
  const itemHeight = useMemo(
    () => round(elementLayout.height / globalGrid.gridHeight, 2),
    [elementLayout.height, globalGrid.gridHeight]
  );

  const [draggedX, setDraggedX] = useState(0);
  const [draggedY, setDraggedY] = useState(0);
  const [resizedWidth, setResizedWidth] = useState(elementData.styles.width);
  const [resizedHeight, setResizedHeight] = useState(elementData.styles.height);

  return (
    <>
      <div
        className="absolute flex justify-between"
        style={{
          // top:
          //   elementLayout.top -
          //   mainLayout.top -
          //   30 +
          //   (document?.getElementById("main-scrollable")?.scrollTop ?? 0),
          top: elementLayout.top - mainLayout.top - 30,
          left: elementLayout.left - mainLayout.left,
          height: "32px",
          width: elementLayout.width,
        }}
      >
        <div className="bg-red-400 rounded-t-md h-full px-2 flex items-center">
          <motion.button
            drag
            onDragStart={(event, info) => {
              toggleDragging(true);
            }}
            onDragEnd={async (event: any, info) => {
              toggleDragging(false);
              updateElementData(
                set(elementData, ["styles"], {
                  ...elementData.styles,
                  top: draggedY,
                  left: draggedX,
                })
              );
              setDraggedY(0);
              setDraggedX(0);
              animateDrag.start({ x: 0, y: 0, transition: { type: "just" } });
              // updateActiveElementId(undefined);
            }}
            onDrag={(event, info) => {
              const pointY =
                (info.point.y -
                  mainLayout.top -
                  pageSettings.paddingTop +
                  (document?.getElementById("main-scrollable")?.scrollTop ??
                    0)) /
                globalZoom.zoomRatio;
              const pointX =
                (info.point.x - mainLayout.left - pageSettings.paddingLeft) /
                globalZoom.zoomRatio;

              const distances: any[] = [];

              range(globalGrid.columns).forEach((column) => {
                range(globalGrid.rows).forEach((row) => {
                  // const centerX =
                  //   (column * globalGrid.gridWidth + globalGrid.gridWidth) / 2;
                  // const centerY =
                  //   (row * globalGrid.gridHeight + globalGrid.gridHeight) / 2;
                  const centerX =
                    (column * globalGrid.gridWidth +
                      (column + 1) * globalGrid.gridWidth) /
                    2 /
                    globalZoom.zoomRatio;
                  const centerY =
                    (row * globalGrid.gridHeight +
                      (row + 1) * globalGrid.gridHeight) /
                    2 /
                    globalZoom.zoomRatio;

                  distances.push({
                    column,
                    row,
                    distance: Math.sqrt(
                      Math.pow(centerX - pointX, 2) +
                        Math.pow(centerY - pointY, 2)
                    ),
                  });
                });
              });

              const sortedDistances = sortBy(
                distances,
                (d) => d.distance
              ).filter((d) => {
                if (d.column + itemWidth > globalGrid.columns) {
                  return false;
                }
                if (d.row + itemHeight > globalGrid.rows) {
                  return false;
                }
                return true;
              });

              if (sortedDistances.length) {
                // const updatedX =
                //   sortedDistances[0].column * globalGrid.gridWidth +
                //   pageSettings.paddingLeft +
                //   2;
                // const updatedY =
                //   sortedDistances[0].row * globalGrid.gridHeight +
                //   pageSettings.paddingTop +
                //   2;
                // const updatedX = `${
                //   (sortedDistances[0].column / globalGrid.columns) * 100
                // }%`;
                // const updatedY = `${
                //   (sortedDistances[0].row / globalGrid.rows) * 100
                // }%`;
                const updatedX =
                  (sortedDistances[0].column * globalGrid.gridWidth) /
                  globalZoom.zoomRatio;
                const updatedY =
                  (sortedDistances[0].row * globalGrid.gridHeight) /
                  globalZoom.zoomRatio;
                if (draggedX !== updatedX) {
                  console.log("updatedX", updatedX);
                  setDraggedX(updatedX);
                }
                if (draggedY !== updatedY) {
                  console.log("updatedY", updatedY);
                  setDraggedY(updatedY);
                }
              }
            }}
            dragMomentum={false}
            initial={false}
            animate={animateDrag}
            className="mx-1 focus:outline-none"
          >
            <Move
              className={isDragging ? "text-red-400" : "text-white"}
              size={16}
            />
          </motion.button>
          <button
            className="mx-1 focus:outline-none"
            onClick={() =>
              deleteNode(
                element?.node,
                element?.path as string[],
                updateElements,
                updateElementsData,
                updateActiveElementId
              )
            }
          >
            <Trash className="text-white" size={16} />
          </button>
        </div>
      </div>

      {isDragging ? (
        <motion.div
          layoutId="placeholder"
          className="absolute bg-gray-500 bg-opacity-25"
          style={{
            width: elementLayout.width,
            height: elementLayout.height,
          }}
          initial={{
            top: elementLayout.top - mainLayout.top,
            left: elementLayout.left - mainLayout.left,
          }}
          animate={{
            top:
              floatAdjust(globalGrid, pageSettings, "top", draggedY) *
              globalZoom.zoomRatio,
            left:
              floatAdjust(globalGrid, pageSettings, "left", draggedX) *
              globalZoom.zoomRatio,
          }}
        />
      ) : null}

      {isResizing ? (
        <motion.div
          layoutId="placeholder"
          className="absolute bg-gray-500 bg-opacity-25"
          style={{
            top: elementLayout.top - mainLayout.top,
            left: elementLayout.left - mainLayout.left,
          }}
          initial={{
            width: elementLayout.width,
            height: elementLayout.height,
          }}
          animate={{
            height:
              floatAdjust(globalGrid, pageSettings, "height", resizedHeight) *
              globalZoom.zoomRatio,
            width:
              floatAdjust(globalGrid, pageSettings, "width", resizedWidth) *
              globalZoom.zoomRatio,
          }}
        />
      ) : null}

      {!isDragging ? (
        <>
          <motion.button
            className="absolute w-3 h-3 rounded-full bg-blue-500"
            style={{
              top:
                elementLayout.top -
                mainLayout.top +
                elementLayout.height / 2 -
                6,
              left:
                elementLayout.left - mainLayout.left + elementLayout.width + 6,
            }}
            drag="x"
            onDragStart={() => toggleResizing(true)}
            onDragEnd={() => {
              toggleResizing(false);
              updateElementData(
                set(elementData, ["styles", "width"], resizedWidth)
              );
              animateResizeX.start({
                x: 0,
                y: 0,
              });
            }}
            onDrag={(event, info) => {
              const offsetX = info.offset.x;
              const blocks = round(offsetX / globalGrid.gridWidth, 2);
              setResizedWidth(
                (elementLayout.width + blocks * globalGrid.gridWidth) /
                  globalZoom.zoomRatio
              );
              // setResizedWidth(
              //   `${((itemWidth + blocks) / globalGrid.columns) * 100}%`
              // );
            }}
            animate={animateResizeX}
          />

          <motion.button
            className="absolute w-3 h-3 rounded-full bg-blue-500"
            style={{
              top:
                elementLayout.top - mainLayout.top + elementLayout.height + 6,
              left:
                elementLayout.left -
                mainLayout.left +
                elementLayout.width / 2 -
                6,
            }}
            drag="y"
            onDragStart={() => toggleResizing(true)}
            onDragEnd={() => {
              toggleResizing(false);
              updateElementData(
                set(elementData, ["styles", "height"], resizedHeight)
              );
              // animateResizeY.start({
              //   x: 0,
              //   y: 0,
              //   transition: { type: "just" },
              // });
              animateResizeY.start({ x: 0, y: 0 });
            }}
            onDrag={(event, info) => {
              const offsetY = info.offset.y;
              const blocks = round(offsetY / globalGrid.gridHeight, 2);
              setResizedHeight(
                (elementLayout.height + blocks * globalGrid.gridHeight) /
                  globalZoom.zoomRatio
              );
              // setResizedHeight(
              //   `${((itemHeight + blocks) / globalGrid.rows) * 100}%`
              // );
            }}
            animate={animateResizeY}
          />
        </>
      ) : null}
    </>
  );
};

export const ActiveBoxHelper = () => {
  const activeElementType = useRecoilValue(activeElementTypeState);
  const activeElementId = useRecoilValue(activeElementIdState);
  const elementLayout = useRecoilValue(elementLayoutState(activeElementId));
  const globalZoom = useRecoilValue(globalZoomState);
  const mainLayout = useRecoilValue(elementLayoutState("*"));
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );
  const globalGrid = useRecoilValue(globalFloatingGridState);
  const parentLayout = useRecoilValue(
    elementParentLayoutState(activeElementId)
  );
  const toggleIsUserAction = useSetRecoilState(isUserActionState);
  // console.log(parentLayout);

  const xPercentage = useMemo(
    () => (parentLayout?.width ?? globalGrid.width) / 100,
    [parentLayout, globalGrid]
  );
  const yPercentage = useMemo(
    () => (parentLayout?.height ?? globalGrid.height) / 100,
    [parentLayout, globalGrid]
  );

  const [isLocked, toggleLocked] = useToggle(false);
  const [isDraggingX, toggleDraggingX] = useToggle(false);
  const [isDraggingY, toggleDraggingY] = useToggle(false);

  const [heightPlaceholder, setHeightPlaceholder] = useState(
    startDimension(
      elementData.styles,
      parentLayout,
      "height",
      globalZoom.zoomRatio
    )
  );
  const [widthPlaceholder, setWidthPlaceholder] = useState(
    startDimension(
      elementData.styles,
      parentLayout,
      "width",
      globalZoom.zoomRatio
    )
  );

  if (activeElementType !== TreeItemTypes.BOX) {
    return null;
  }

  return (
    <>
      <div
        className="absolute flex items-center justify-center"
        style={{
          top: elementLayout.top - mainLayout.top + elementLayout.height + 6,
          left: elementLayout.left - mainLayout.left,
          width: elementLayout.width,
        }}
      >
        {isDraggingX ? (
          <div className="bg-blue-500 h-3 w-3 rounded-full" />
        ) : (
          <motion.button
            layoutId="vertical-drag"
            className="bg-blue-500 h-3 w-3 rounded-full"
            drag="y"
            onDragStart={() => {
              toggleIsUserAction(true);
              toggleDraggingY(true);
              setWidthPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "width",
                  globalZoom.zoomRatio
                )
              );
              setHeightPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "height",
                  globalZoom.zoomRatio
                )
              );
            }}
            onDragEnd={() => {
              toggleDraggingY(false);
              toggleIsUserAction(false);
            }}
            onDrag={(event, info) => {
              const update = {
                ...elementData.styles,
                height:
                  (isNumber(heightPlaceholder) ? heightPlaceholder : 0) +
                  info.offset.y / globalZoom.zoomRatio,
              };

              if (update.height > parentLayout.height / globalZoom.zoomRatio)
                return;

              if (isLocked) {
                update.width =
                  info.offset.y * (widthPlaceholder / heightPlaceholder) +
                  widthPlaceholder;

                if (update.width > parentLayout.width / globalZoom.zoomRatio)
                  return;
              }
              updateElementData(set(elementData, ["styles"], update));
            }}
            dragMomentum={false}
          />
        )}
      </div>
      <div
        className="absolute flex items-center justify-center"
        style={{
          top: elementLayout.top - mainLayout.top,
          left: elementLayout.left - mainLayout.left + elementLayout.width + 6,
          height: elementLayout.height,
        }}
      >
        {isDraggingY ? (
          <div className="bg-blue-500 h-3 w-3 rounded-full" />
        ) : (
          <motion.button
            layoutId="horizontal-drag"
            className="bg-blue-500 h-3 w-3 rounded-full"
            drag="x"
            onDragStart={() => {
              toggleIsUserAction(true);
              toggleDraggingX(true);
              setHeightPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "height",
                  globalZoom.zoomRatio
                )
              );
              setWidthPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "width",
                  globalZoom.zoomRatio
                )
              );
            }}
            onDragEnd={() => {
              toggleDraggingX(false);
              toggleIsUserAction(false);
            }}
            onDrag={(event, info) => {
              const update = {
                ...elementData.styles,
                width:
                  (isNumber(widthPlaceholder) ? widthPlaceholder : 0) +
                  info.offset.x / globalZoom.zoomRatio,
              };

              // console.log(
              //   update.width,
              //   parentLayout.width,
              //   update.width > parentLayout.width
              // );
              if (update.width > parentLayout.width / globalZoom.zoomRatio)
                return;

              if (isLocked) {
                update.height =
                  info.offset.x * (heightPlaceholder / widthPlaceholder) +
                  heightPlaceholder;

                if (update.height > parentLayout.height / globalZoom.zoomRatio)
                  return;
              }
              updateElementData(set(elementData, ["styles"], update));
            }}
            dragMomentum={false}
          />
        )}
      </div>
      <motion.button
        className="absolute h-6 w-6 bg-blue-500 rounded-full flex items-center justify-center"
        initial={{
          top: elementLayout.top - mainLayout.top + elementLayout.height - 12,
          left: elementLayout.left - mainLayout.left + elementLayout.width - 12,
        }}
        animate={{
          top: elementLayout.top - mainLayout.top + elementLayout.height - 12,
          left: elementLayout.left - mainLayout.left + elementLayout.width - 12,
        }}
        // initial={{
        //   top: elementLayout.top - mainLayout.top + elementLayout.height + 6,
        //   left: elementLayout.left - mainLayout.left + elementLayout.width + 6,
        // }}
        // animate={{
        //   top: elementLayout.top - mainLayout.top + elementLayout.height + 6,
        //   left: elementLayout.left - mainLayout.left + elementLayout.width + 6,
        // }}
        onClick={toggleLocked}
      >
        {isLocked ? (
          <Lock color="white" size={12} />
        ) : (
          <Unlock color="white" size={12} />
        )}
      </motion.button>
    </>
  );
};

export const ActiveImageHelper = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const elementLayout = useRecoilValue(elementLayoutState(activeElementId));
  const mainLayout = useRecoilValue(elementLayoutState("*"));
  const globalZoom = useRecoilValue(globalZoomState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );
  const globalGrid = useRecoilValue(globalFloatingGridState);
  const parentLayout = useRecoilValue(
    elementParentLayoutState(activeElementId)
  );
  const toggleIsUserAction = useSetRecoilState(isUserActionState);

  const xPercentage = useMemo(
    () => (parentLayout?.width ?? globalGrid.width) / 100,
    [parentLayout, globalGrid]
  );
  const yPercentage = useMemo(
    () => (parentLayout?.height ?? globalGrid.height) / 100,
    [parentLayout, globalGrid]
  );

  const [isLocked, toggleLocked] = useToggle(false);
  const [isDraggingX, toggleDraggingX] = useToggle(false);
  const [isDraggingY, toggleDraggingY] = useToggle(false);

  const [heightPlaceholder, setHeightPlaceholder] = useState(
    startDimension(
      elementData.styles,
      parentLayout,
      "height",
      globalZoom.zoomRatio
    )
  );
  const [widthPlaceholder, setWidthPlaceholder] = useState(
    startDimension(
      elementData.styles,
      parentLayout,
      "width",
      globalZoom.zoomRatio
    )
  );

  if (!elementData.imageUrl) return null;

  return (
    <>
      <div
        className="absolute flex items-center justify-center"
        style={{
          top: elementLayout.top - mainLayout.top + elementLayout.height + 6,
          left: elementLayout.left - mainLayout.left,
          width: elementLayout.width,
        }}
      >
        {isDraggingX ? (
          <div className="bg-blue-500 h-3 w-3 rounded-full" />
        ) : (
          <motion.button
            layoutId="vertical-drag"
            className="bg-blue-500 h-3 w-3 rounded-full"
            drag="y"
            onDragStart={() => {
              toggleIsUserAction(true);
              toggleDraggingY(true);
              setHeightPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "height",
                  globalZoom.zoomRatio
                )
              );
              setWidthPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "width",
                  globalZoom.zoomRatio
                )
              );
            }}
            onDragEnd={() => {
              toggleDraggingY(false);
              toggleIsUserAction(false);
            }}
            onDrag={(event, info) => {
              const update = {
                ...elementData.styles,
                height:
                  (isNumber(heightPlaceholder) ? heightPlaceholder : 0) +
                  info.offset.y / globalZoom.zoomRatio,
              };
              if (isLocked) {
                update.width =
                  info.offset.y /
                    (widthPlaceholder / heightPlaceholder) /
                    globalZoom.zoomRatio +
                  widthPlaceholder;
              }
              updateElementData(set(elementData, ["styles"], update));
            }}
            dragMomentum={false}
          />
        )}
      </div>
      <div
        className="absolute flex items-center justify-center"
        style={{
          top: elementLayout.top - mainLayout.top,
          left: elementLayout.left - mainLayout.left + elementLayout.width + 6,
          height: elementLayout.height,
        }}
      >
        {isDraggingY ? (
          <div className="bg-blue-500 h-3 w-3 rounded-full" />
        ) : (
          <motion.button
            layoutId="horizontal-drag"
            className="bg-blue-500 h-3 w-3 rounded-full"
            drag="x"
            onDragStart={() => {
              toggleIsUserAction(true);
              toggleDraggingX(true);
              setWidthPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "width",
                  globalZoom.zoomRatio
                )
              );
              setHeightPlaceholder(
                startDimension(
                  elementData.styles,
                  parentLayout,
                  "height",
                  globalZoom.zoomRatio
                )
              );
            }}
            onDragEnd={() => {
              toggleDraggingX(false);
              toggleIsUserAction(false);
            }}
            onDrag={(event, info) => {
              const update = {
                ...elementData.styles,
                width:
                  (isNumber(widthPlaceholder) ? widthPlaceholder : 0) +
                  info.offset.x / globalZoom.zoomRatio,
              };
              if (isLocked) {
                update.height =
                  info.offset.x /
                    (heightPlaceholder / widthPlaceholder) /
                    globalZoom.zoomRatio +
                  heightPlaceholder;
              }
              updateElementData(set(elementData, ["styles"], update));
            }}
            dragMomentum={false}
          />
        )}
      </div>
      <button
        className="absolute h-6 w-6 bg-blue-500 rounded-full flex items-center justify-center"
        style={{
          top: elementLayout.top - mainLayout.top + elementLayout.height - 12,
          left: elementLayout.left - mainLayout.left + elementLayout.width - 12,
        }}
        onClick={toggleLocked}
      >
        {isLocked ? (
          <Lock color="white" size={12} />
        ) : (
          <Unlock color="white" size={12} />
        )}
      </button>
    </>
  );
};
