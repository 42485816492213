import { useQuery } from "react-query";
import { useFirestore, useUser } from "reactfire";
import { useRecoilValue } from "recoil";
import { editorActiveTemplateId } from "../../layouts/Editor/editor.atom";
import { doc, Firestore, getDoc } from "firebase/firestore";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Template } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getTemplate = async (
  token: string,
  organizationId: string | undefined,
  templateId: string
): Promise<Template> => {
  if (!organizationId) {
    throw Error("No active organization found.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/templates/${templateId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.template;
};

export const useTemplate = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const templateId = useRecoilValue(editorActiveTemplateId);

  return useQuery(
    ["template", templateId],
    () => {
      return getTemplate(token as string, organizationId, templateId as string);
    },
    {
      enabled:
        !!organizationId && !!templateId && templateId !== "new" && !!token,
      staleTime: 5000,
    }
  );
};
