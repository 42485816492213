import { xor } from "lodash";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateOrganization } from "../../hooks/mutations/useCreateOrganization";
import { useRespondInvites } from "../../hooks/mutations/useRespondInvites";
import { Invite } from "../../models/api.model";
import { GenericButton } from "../GenericButton";

interface InvitesProps {
  invites: Invite[];
  onClose: () => void;
}

export const Invites = ({ invites, onClose }: InvitesProps) => {
  const [accepted, setAccepted] = useState<string[]>([]);
  const [rejected, setRejected] = useState<string[]>([]);

  const { mutate: mutateRespondInvites } = useRespondInvites();
  const { mutate: mutateCreateOrganization } = useCreateOrganization();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const nameStatus = watch("name");

  const onSubmit = (values: { name: string }) => {
    mutateCreateOrganization({
      organizationName: values.name,
    });
    onClose();
  };

  return (
    <>
      <div className="h-14 px-4 bg-gray-100 flex items-center justify-between rounded-t-lg">
        <span className="text-gray-700">Accept invites</span>
      </div>
      <div className="px-4 py-4 font-light text-sm">
        <h6 className="font-semibold text-gray-600 text-lg">
          You have organization invites waiting.
        </h6>
        <p className="mt-2">
          Did you sign up with for ExportSDK because you were invited to join a
          organization? If so, your invitations are below.
        </p>

        <div className="grid grid-cols-6 gap-4 mt-8 w-full">
          <div className="col-span-4">
            <label className="block text-sm font-medium text-gray-500">
              Organization name
            </label>
          </div>
          <div className="col-span-1 text-center">
            <span className="block text-sm font-medium text-gray-500">
              Accept
            </span>
          </div>
          <div className="col-span-1 text-center">
            <span className="block text-sm font-medium text-gray-500">
              Decline
            </span>
          </div>

          {invites.map((invite) => (
            <Fragment key={invite.id}>
              <div className="col-span-4">
                <span className="font-normal text-lg">
                  {invite.organization?.name}
                </span>
              </div>
              <div className="col-span-1 text-center">
                <input
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  checked={accepted.includes(invite.id)}
                  onChange={(evt) => {
                    setAccepted(xor(accepted, [invite.id]));
                    setRejected(rejected.filter((id) => id !== invite.id));
                  }}
                />
              </div>
              <div className="col-span-1 text-center">
                <input
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  checked={rejected.includes(invite.id)}
                  onChange={(evt) => {
                    setRejected(xor(rejected, [invite.id]));
                    setAccepted(accepted.filter((id) => id !== invite.id));
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>

        <div className="mt-8">
          <GenericButton
            type="button"
            onClick={() =>
              mutateRespondInvites({
                accepted: invites.filter((invite) =>
                  accepted.includes(invite.id)
                ),
                rejected: invites.filter((invite) =>
                  rejected.includes(invite.id)
                ),
              })
            }
          >
            Respond to invites
          </GenericButton>
        </div>

        <h6 className="font-semibold text-gray-600 text-lg mt-20">
          Want to start your own organization instead?
        </h6>

        <form
          className="grid grid-cols-4 gap-x-4 mt-6 w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-span-3">
            <label className="block font-medium text-gray-700">
              Organization name
            </label>
            <input
              type="text"
              className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
              {...register("name", {
                required: {
                  value: true,
                  message: "Organization name is required.",
                },
              })}
            />
          </div>
          <div className="col-span-1 self-end">
            <GenericButton
              type="submit"
              disabled={!nameStatus.length || isSubmitting}
            >
              Create organization
            </GenericButton>
          </div>
        </form>
      </div>
    </>
  );
};
