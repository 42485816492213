import { Link2, Upload, XCircle } from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useRef, useState } from "react";
import { set } from "monolite";

import {
  activeElementIdState,
  elementDataState,
  elementsLayoutState,
  flatElementsState,
  flatElementState,
  globalFloatingGridState,
  globalZoomState,
} from "../editor.atom";
import { useDebounce } from "react-use";

import EditorAccordion from "../../../components/EditorAccordion";
import { useRecoilCallback } from "recoil";

const Image = () => {
  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      updateElementData(set(elementData, ["imageUrl"], reader.result));
      setLocalImageUrl(reader?.result as string);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg, image/png",
    maxSize: 1e7,
  });

  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  const [localImageUrl, setLocalImageUrl] = useState<string>(
    elementData?.imageUrl
  );
  const [naturalHeight, setNaturalHeight] = useState<number>();
  const [naturalWidth, setNaturalWidth] = useState<number>();

  const handleLoadImage = useRecoilCallback(
    ({ snapshot, set: recoilSet }) =>
      async (height: number, width: number) => {
        const aspectRatio = height / width;
        const [
          elementsLayout,
          flatElement,
          elementData,
          globalGrid,
          globalZoom,
        ] = await Promise.all([
          snapshot.getPromise(elementsLayoutState),
          snapshot.getPromise(flatElementState(activeElementId)),
          snapshot.getPromise(elementDataState(activeElementId)),
          snapshot.getPromise(globalFloatingGridState),
          snapshot.getPromise(globalZoomState),
        ]);

        const parentHeight =
          elementsLayout?.[flatElement?.parentNode?.id]?.height;
        const parentWidth =
          elementsLayout?.[flatElement?.parentNode?.id]?.width;
        // console.log(
        //   globalZoom.adjustedHeight,
        //   globalZoom.adjustedWidth,
        //   height,
        //   width
        // );

        if (!parentHeight || !parentWidth) {
          if (
            globalZoom.adjustedWidth / globalZoom.adjustedHeight >
            width / height
          ) {
            recoilSet(
              elementDataState(activeElementId),
              set(elementData, ["styles"], {
                ...elementData.styles,
                width: width * (globalZoom.adjustedHeight / height),
                height: globalZoom.adjustedHeight,
              })
            );
          } else {
            recoilSet(
              elementDataState(activeElementId),
              set(elementData, ["styles"], {
                ...elementData.styles,
                width: globalZoom.adjustedWidth,
                height: height * (globalZoom.adjustedWidth / width),
              })
            );
          }

          return;
        }

        const rs = parentWidth / parentHeight;
        const ri = width / height;
        const imageWidth =
          rs > ri ? width * (parentHeight / height) : parentWidth;
        const imageHeight =
          rs > ri ? parentHeight : height * (parentWidth / width);

        recoilSet(
          elementDataState(activeElementId),
          set(elementData, ["styles"], {
            ...elementData.styles,
            width: imageWidth / globalZoom.zoomRatio,
            height: imageHeight / globalZoom.zoomRatio,
          })
        );

        // if (rs > ri) {
        //   const imageWidth = width * (parentHeight / height);
        //   const imageHeight = parentHeight;

        //   recoilSet(
        //     elementDataState(activeElementId),
        //     set(elementData, ["styles"], {
        //       ...elementData.styles,
        //       width: imageWidth,
        //       height: imageHeight,
        //     })
        //   );
        // } else {
        //   const imageWidth = parentWidth;
        //   const imageHeight = height * (parentWidth / width);

        //   recoilSet(
        //     elementDataState(activeElementId),
        //     set(elementData, ["styles"], {
        //       ...elementData.styles,
        //       width: parentWidth,
        //       height: height * (parentWidth / width),
        //     })
        //   );
        // }

        // if (parentWidth > parentHeight) {
        //   console.log(height > width, width > parentHeight);
        //   recoilSet(
        //     elementDataState(activeElementId),
        //     set(elementData, ["styles"], {
        //       ...elementData.styles,
        //       // height: height * ratio,
        //       // width: width * ratio,
        //       height:
        //         height > width ? parentHeight : height / (width / parentWidth),
        //       width:
        //         width > parentWidth
        //           ? parentWidth
        //           : width / (height / parentHeight),
        //     })
        //   );
        // } else {
        //   recoilSet(
        //     elementDataState(activeElementId),
        //     set(elementData, ["styles"], {
        //       ...elementData.styles,
        //       // height: height * ratio,
        //       // width: width * ratio,
        //       height:
        //         height > parentHeight
        //           ? parentHeight
        //           : height / (width / parentWidth),
        //       width:
        //         width > height ? parentWidth : width / (height / parentHeight),
        //     })
        //   );
        // }

        // if (parentHeight * aspectRatio < parentWidth) {
        //   recoilSet(
        //     elementDataState(activeElementId),
        //     set(elementData, ["styles"], {
        //       ...elementData.styles,
        //       height: "100%",
        //       width: `${100 / aspectRatio}%`,
        //     })
        //   );
        // } else {
        //   recoilSet(
        //     elementDataState(activeElementId),
        //     set(elementData, ["styles"], {
        //       ...elementData.styles,
        //       height: `${parentWidth * aspectRatio}%`,
        //       width: "100%",
        //     })
        //   );
        // }
      },
    [activeElementId]
  );

  useDebounce(
    () => {
      updateElementData(set(elementData, ["imageUrl"], localImageUrl));
    },
    500,
    [localImageUrl]
  );

  return (
    <EditorAccordion label="Image">
      <div className="p-4">
        <div className="w-full border border-gray-200 py-1 px-2 rounded-sm flex items-center mb-4">
          <Link2 className="text-gray-300 mr-2" size={16} />
          <input
            className="text-sm font-light flex-1 focus:outline-none"
            placeholder={
              localImageUrl?.startsWith("data:")
                ? "Image uploaded"
                : "Image url"
            }
            value={
              localImageUrl && !localImageUrl.startsWith("data:")
                ? localImageUrl
                : ""
            }
            onChange={(evt) => setLocalImageUrl(evt.target.value)}
          />
        </div>
        {elementData.imageUrl ? (
          <div className="w-full relative">
            <img
              onLoad={(img) => {
                const height = img.currentTarget.naturalHeight;
                const width = img.currentTarget.naturalWidth;

                setNaturalHeight(height);
                setNaturalWidth(width);

                if (elementData.styles.width || elementData.styles.height) {
                  return;
                }

                handleLoadImage(height, width);
              }}
              src={elementData.imageUrl}
              className="w-full h-auto border-2 border-gray-300 border-dashed"
            />
            <div className="mt-2">
              <span className="text-gray-400">{`${naturalWidth} x ${naturalHeight}`}</span>
            </div>

            <button
              className="absolute top-4 right-4"
              onClick={() => {
                setLocalImageUrl("");
                updateElementData(set(elementData, ["imageUrl"], ""));
                setNaturalHeight(undefined);
                setNaturalWidth(undefined);
              }}
            >
              <XCircle size={24} className="text-gray-600" />
            </button>
          </div>
        ) : (
          <div
            {...getRootProps({ className: "dropzone" })}
            className="w-full h-32 flex flex-col items-center justify-center border-2 border-gray-300 border-dashed focus:outline-none rounded-sm"
          >
            <input {...getInputProps()} />
            <Upload className="text-gray-300 mb-2" size={24} />
            <p className="text-gray-300 text-sm">Upload file</p>
          </div>
        )}
      </div>
    </EditorAccordion>
  );
};

export default Image;
