import faker from "faker";
import { range } from "lodash";

const makeDebug = () => ({
  date: faker.date.past(),
  templateId: faker.datatype.uuid(),
  source: faker.internet.url(),
});

export default range(10).map(() => makeDebug());
