import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";

const handleDeleteInvite = async (
  token: string,
  organizationId: string | undefined,
  inviteId: string
): Promise<boolean> => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/invites/${inviteId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.success;
};

export const useDeleteInvite = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    ({ inviteId, orgId }: { inviteId: string; orgId?: string }) =>
      handleDeleteInvite(token as string, orgId ?? organizationId, inviteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organization-invites");
      },
    }
  );
};
