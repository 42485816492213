import { useNavigate } from "react-router";
import { useRecoilCallback } from "recoil";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import {
  editorPagesState,
  elementPageSettingsState,
  elementsDataState,
  elementsState,
} from "../Editor/editor.atom";
import { checkPageIds } from "../../utils/editor.util";
import { templates, tutorials } from "../../assets/prebuilt";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export default function PrebuiltModal({ isOpen, closeModal }: ModalProps) {
  const navigate = useNavigate();
  const prehydrateState = useRecoilCallback(
    ({ set }) =>
      (template: any) => {
        set(editorPagesState, checkPageIds(template?.pages));
        set(elementsState, template?.pages[0].tree);
        set(elementsDataState, template?.pages[0].data);
        set(elementPageSettingsState, template?.pages[0].pageSettings);
        navigate("/editor/new");
      },
    []
  );

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-90"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full h-80vh overflow-y-scroll max-w-7xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
              <h3 className="font-medium mb-6 text-lg pb-4 border-b border-gray-200">Select a template to get started</h3>
                <div className="grid grid-cols-5 gap-x-6 gap-y-8 mb-4">
                    {templates.map((template) => {
                        return (
                          <div className="col-span-1">
                              <button onClick={() => prehydrateState(template.json)}>
                              <img
                                  src={template.img}
                                  alt="blank-white"
                                  className="w-full h-auto border border-gray-200 hover:border-2 hover:border-blue-500"
                              />
                              </button>
                              <p className="text-gray-700 font-light">
                                  {template.name}
                              </p>
                          </div>
                        )
                    })}
                </div>
                <div className="p-2 rounded-lg bg-gray-900 opacity-90 sm:p-3 my-12">
                <div className="flex items-center justify-between flex-wrap">
                  <div className="w-0 flex-1 flex items-center text-white font-regular">
                    😕 Didn't find a template for your use case? Let us know! Email us
                    at&nbsp;
                    <a
                        className="text-blue-500"
                        href="mailto:hello@blanklabs.dev"
                    >
                        hello@blanklabs.dev
                    </a>
                    .
                  </div>
                </div>
              </div>
                <h3 className="font-medium mb-6 text-lg pb-4 border-b border-gray-200">Check out a template tutorial</h3>
                <div className="grid grid-cols-5 gap-6 mb-4">
                    {tutorials.map((template) => {
                        return (
                          <div className="col-span-1">
                              <button onClick={() => prehydrateState(template.json)}>
                              <img
                                  src={template.img}
                                  alt="blank-white"
                                  className="w-full h-auto border border-gray-200 hover:border-2 hover:border-blue-500"
                              />
                              </button>
                              <p className="text-gray-700 font-light">
                                  {template.name}
                              </p>
                          </div>
                        )
                    })}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}