import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Template } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const updateTemplatePages = async (
  token: string,
  organizationId: string | undefined,
  templateId: string,
  pages: any
): Promise<Template> => {
  if (!organizationId) {
    throw Error("Unable to locate active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/templates/${templateId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pages,
      }),
    }
  );

  const json = await response.json();
  return json.template;
};

export const useUpdateTemplatePages = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    ({ templateId, pages }: any) =>
      updateTemplatePages(
        token as string,
        organizationId,
        templateId,
        JSON.parse(JSON.stringify(pages))
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("template");
      },
    }
  );
};
