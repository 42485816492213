import { Box, Flex, Text, Card } from "theme-ui";
import { sample, range } from "lodash";
import { ResponsiveBar } from "@nivo/bar";

import { colors } from "../../theme";
import dayjs from "dayjs";

interface ActivityChartProps {
  data: any[];
}

const ActivityChart = ({ data }: ActivityChartProps) => {
  return (
    <div className="p-4 h-480px bg-white rounded-md border border-gray-200 mb-8">
      <Flex sx={{ alignItems: "center" }}>
        <Text variant="sizeS" sx={{ fontWeight: "700" }}>
          Requests
        </Text>
        <Flex
          bg="bg"
          p={2}
          sx={{ alignItems: "center", borderRadius: 8 }}
          ml={4}
        >
          <Box
            bg="primaryYellow"
            mr={2}
            sx={{ height: 10, width: 10, borderRadius: 5 }}
          />
          <Text variant="sizeXxs">Successful</Text>
        </Flex>
        <Flex
          bg="bg"
          p={2}
          sx={{ alignItems: "center", borderRadius: 8 }}
          ml={2}
        >
          <Box
            bg="primaryRed"
            mr={2}
            sx={{ height: 10, width: 10, borderRadius: 5 }}
          />
          <Text variant="sizeXxs">Failed</Text>
        </Flex>
      </Flex>

      <ResponsiveBar
        data={data}
        keys={["success", "failure"]}
        indexBy="date"
        margin={{ top: 40, right: 40, bottom: 80, left: 60 }}
        padding={0.7}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={[colors.primaryYellow, colors.primaryRed]}
        labelTextColor="white"
        theme={{
          textColor: "#6B7280",
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            borderRadius: 8,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
          {
            id: "cap",
            type: "patternCap",
            borderRadius: 8,
          },
          {
            id: "successGradient",
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#FCD34D" },
              { offset: 100, color: "#FBBF24" },
            ],
          },
        ]}
        fill={[
          {
            match: {
              id: "success",
            },
            id: "successGradient",
          },
        ]}
        borderRadius={2}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Day",
          legendPosition: "middle",
          legendOffset: 32,
          format: (d) => dayjs(d).format("MM/DD"),
        }}
        axisLeft={{
          tickSize: 1,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Requests",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        animate={false}
      />
    </div>
  );
};

export default ActivityChart;
