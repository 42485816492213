import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useProfile } from "./useProfile";

export const useIsOrganizationOwner = () => {
  const { data: profile } = useProfile();
  const activeOrganization = useRecoilValue(activeOrganizationIdState);

  return profile?.organizations?.find(
    (org) =>
      org.organizationId === activeOrganization &&
      org.organization.ownerId === profile.id
  )
    ? true
    : false;
};
