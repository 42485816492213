import { ReactNode } from "react";
import ButtonSpinner from "../ButtonSpinner";

interface Props {
  children: ReactNode | string;
  onClick?: () => any;
  type?: "button" | "submit" | "reset";
  isPrimary?: boolean;
  shouldShowBorder?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}
export const GenericButton = ({
  children,
  onClick,
  type,
  disabled = false,
  isLoading = false,
}: Props) => {
  return (
    <button
      type={type}
      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200 disabled:opacity-50 disabled:cursor-not-allowed"
      disabled={isLoading || disabled}
      onClick={onClick}
    >
      <div className="flex flex-row items-center">
        <ButtonSpinner isVisible={isLoading} />
        {children}
      </div>
    </button>
  );
};
