import { Plus, Copy, Trash, Move, Feather } from "react-feather";
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilCallback,
} from "recoil";
import { isNumber, sortBy } from "lodash";
import { set } from "monolite";
import {
  activeElementIdState,
  addElementIdState,
  elementLayoutState,
  elementsDataState,
  elementsLayoutState,
  elementsState,
  flatElementsState,
  flatElementState,
  dropElementIdState,
  elementDataState,
  activeElementTypeState,
  globalZoomState,
  additionalElementIdsState,
} from "./editor.atom";
import { deleteNode, duplicateNode } from "../../utils/editor.util";
import { useCallback, useMemo } from "react";
import { TreeItemTypes } from "../../models/editor.model";
import { motion, Point, useAnimation } from "framer-motion";
import { useToggle } from "react-use";
import {
  removeNodeAtPath,
  map,
  TreeNode,
  isDescendant,
} from "react-sortable-tree";

import {
  ActiveImageHelper,
  ActiveFloatingToolbar,
  ActiveBoxHelper,
} from "./ActiveHelper.components";
import { useComboKeys } from "react-combo-keys";

const ActiveHelper = () => {
  const [activeElementId, updateActiveElementId] =
    useRecoilState(activeElementIdState);
  const elementLayout = useRecoilValue(elementLayoutState(activeElementId));
  const mainLayout = useRecoilValue(elementLayoutState("*"));
  const element = useRecoilValue(flatElementState(activeElementId));
  const updateElements = useSetRecoilState(elementsState);
  const updateElementsData = useSetRecoilState(elementsDataState);
  const updateElementData = useSetRecoilState(
    elementDataState(activeElementId)
  );
  const updateAddElementId = useSetRecoilState(addElementIdState);
  const [dropElementId, updateDropElementId] =
    useRecoilState(dropElementIdState);
  const activeElementType = useRecoilValue(activeElementTypeState);
  const additionalElementIds = useRecoilValue(additionalElementIdsState);

  const findNearestNode = useRecoilCallback(
    ({ snapshot }) =>
      async (point: Point) => {
        const flatElements = await snapshot.getPromise(flatElementsState);
        const elementsLayout = await snapshot.getPromise(elementsLayoutState);
        const scrollTop = document.getElementById("main-scrollable")?.scrollTop;
        const pointX = point.x;
        const pointY = point.y;

        const targets = flatElements
          .filter((comparison) => {
            if (
              element?.node.type === "row" &&
              comparison.node.type === "row"
            ) {
              return false;
            }
            if (
              element?.node.type === "column" &&
              comparison.node.type === "column"
            ) {
              return false;
            }
            if (element?.node && isDescendant(element?.node, comparison.node)) {
              return false;
            }

            if (!elementsLayout[comparison.node.id]) {
              return false;
            }

            return ["row", "column", "box"].includes(comparison.node.type);
          })
          .map((element) => {
            const layout = elementsLayout[element.node.id];

            return {
              elementId: element.node.id,
              type: element.node.type,
              distance:
                layout.left < pointX &&
                layout.top < pointY &&
                layout.left + layout.width > pointX &&
                layout.top + layout.height > pointY
                  ? pointX -
                    layout.left +
                    (pointY - layout.top) +
                    (layout.left + layout.width - pointX) +
                    (layout.top + layout.height - pointY)
                  : Infinity,
            };
          });

        const sortedTargets = sortBy(targets, (t) => t.distance);

        if (sortedTargets.length && sortedTargets[0].distance !== Infinity) {
          return sortedTargets[0].elementId;
        }

        return undefined;
      }
  );

  const [isDragging, toggleDragging] = useToggle(false);
  const animateDrag = useAnimation();

  const handleDelete = useCallback(() => {
    if (!element) return;

    deleteNode(
      element?.node,
      element?.path as string[],
      updateElements,
      updateElementsData,
      updateActiveElementId
    );
  }, [element]);

  useComboKeys({
    backspace: handleDelete,
  });

  if (!activeElementId || additionalElementIds.length) return null;
  if (!isNumber(elementLayout?.top) || !isNumber(mainLayout?.top)) return null;

  if (activeElementType === TreeItemTypes.FLOAT) {
    return <ActiveFloatingToolbar />;
  }

  return (
    <>
      <div
        className="absolute flex justify-between"
        style={{
          top: elementLayout.top - mainLayout.top - 30,
          left: elementLayout.left - mainLayout.left,
          height: "32px",
          width: elementLayout.width,
        }}
      >
        {elementLayout.width >= 200 ? (
          <div className="bg-red-400 rounded-t-md h-full px-2 flex items-center">
            <span className="text-white text-sm">{element?.node.title}</span>
          </div>
        ) : null}
        <div className="bg-red-400 rounded-t-md h-full px-2 flex items-center">
          <motion.button
            drag
            onDragStart={(event, info) => {
              toggleDragging(true);
            }}
            onDrag={async (event, info) => {
              const elementId = await findNearestNode(info.point);

              if (elementId !== dropElementId) {
                updateDropElementId(elementId);
              }
            }}
            onDragEnd={async (event: any, info) => {
              toggleDragging(false);

              const elementId = await findNearestNode(info.point);
              updateDropElementId(undefined);
              updateActiveElementId(elementId);
              if (!elementId) return;

              updateElements((elements) => {
                const removed = removeNodeAtPath({
                  treeData: elements,
                  getNodeKey: ({ node }) => node.id,
                  ignoreCollapsed: false,
                  path: element?.path as string[],
                });

                const updated = map({
                  treeData: removed,
                  getNodeKey: ({ node }) => node.id,
                  ignoreCollapsed: false,
                  callback: ({ node }: TreeNode) =>
                    node.id !== elementId
                      ? node
                      : {
                          ...node,
                          children: [
                            ...((node as any)?.children ?? []),
                            element?.node,
                          ],
                        },
                });

                return updated;
              });

              setTimeout(() => {
                animateDrag.start({ x: 0, y: 0 });
              }, 0);
            }}
            dragMomentum={false}
            animate={animateDrag}
            className="mx-1 focus:outline-none hidden"
          >
            <Move
              className={isDragging ? "text-red-400" : "text-white"}
              size={16}
            />
          </motion.button>
          <button
            className="mx-1 focus:outline-none"
            onClick={() => {
              updateActiveElementId(undefined);
              updateAddElementId(activeElementId);
            }}
          >
            <Plus className="text-white" size={16} />
          </button>
          <button
            className="mx-1 focus:outline-none"
            onClick={() =>
              duplicateNode(
                element?.node,
                element?.parentNode,
                updateElements,
                updateElementsData,
                updateActiveElementId
              )
            }
          >
            <Copy className="text-white" size={16} />
          </button>
          <button className="mx-1 focus:outline-none" onClick={handleDelete}>
            <Trash className="text-white" size={16} />
          </button>
        </div>
      </div>

      <ActiveImageHelper />
      <ActiveBoxHelper />
    </>
  );
};

export default ActiveHelper;
