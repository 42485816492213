import { useCallback, useEffect, useMemo, memo } from "react";
import { View, Text } from "react-native";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useDebouncedCallback } from "use-debounce";
import {
  map,
  TreeNode,
  GetNodeKeyFunction,
  TreeItem,
  find,
} from "react-sortable-tree";
import seedrandom from "seedrandom";
import { Plus, AlertTriangle } from "react-feather";
import { motion } from "framer-motion";

import { range, debounce, xor, isEqual } from "lodash";
import {
  activeElementIdState,
  activeElementTypeState,
  activeSimiliarElementTypeState,
  addElementIdState,
  additionalElementIdsState,
  dropElementIdState,
  elementDataState,
  elementLayoutState,
  elementPageSettingsState,
  elementsDataState,
  elementsState,
  globalFloatingGridState,
  globalZoomState,
  hoveredElementIdState,
  isUserActionState,
} from "../editor.atom";
import { TreeItemTypes } from "../../../models/editor.model";
import {
  adjustOverlayByZoom,
  createNewElement,
  floatAdjust,
  generateDefaultData,
  generateId,
  getTopOffset,
  getTypeById,
} from "../../../utils/editor.util";
import { useCounter, useDebounce } from "react-use";
import { EmptyPageQuickActions } from "../Element.components";
import { UserActive } from "./UserActive";
import { findNodeById, walkPath } from "../elements-utils";

const activeStyles = {
  borderColor: "#F87171",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
};

const hoveredStyles = {
  borderColor: "#F59E0B",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
};

const additionalSelectedStyles = {
  borderColor: "#D1D5DB",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
};

const addStyles = {
  borderColor: "#10B981",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
};

const dropStyles = {
  borderColor: "#3B82F6",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
};

const gridStyles = {
  borderColor: "#93C5FD",
  borderTopWidth: 2,
  borderBottomWidth: 2,
  borderRightWidth: 2,
  borderLeftWidth: 2,
  borderStyle: "dashed",
};

interface OverlayElementProps {
  id: string;
  type?: TreeItemTypes;
  styles?: any;
  subelements?: any[];
}

const getNodeKey: GetNodeKeyFunction = ({ node }) => node.id;

function expandNode(elements: TreeItem[], nodeId: string): TreeItem[] {
  const { treeData: updatedElements } = find({
    treeData: elements,
    getNodeKey,
    searchMethod: (searchData) => searchData.node.id === nodeId,
    expandAllMatchPaths: false,
    expandFocusMatchPaths: true,
    searchFocusOffset: 0,
  });

  return updatedElements;
}

const OverlayElement = ({
  id,
  type = TreeItemTypes.BOX,
  styles,
  subelements,
}: OverlayElementProps) => {
  const [renderCount, { inc }] = useCounter();
  const [elements, updateElements] = useRecoilState(elementsState);
  const updateElementsData = useSetRecoilState(elementsDataState);
  const updateElementLayout = useSetRecoilState(elementLayoutState(id));
  const [activeElementId, updateActiveElementId] =
    useRecoilState(activeElementIdState);
  const activeElementType = useRecoilValue(activeElementTypeState);
  const [hoveredElementId, updateHoveredElementId] = useRecoilState(
    hoveredElementIdState
  );
  const [addElementId, updateAddElementId] = useRecoilState(addElementIdState);
  const dropElementId = useRecoilValue(dropElementIdState);

  const [elementData, updateElementData] = useRecoilState(elementDataState(id));
  const hasNoSubelements = useMemo(() => !subelements?.length, [subelements]);
  const pageSettings = useRecoilValue(elementPageSettingsState);
  const globalGrid = useRecoilValue(globalFloatingGridState);
  const globalZoom = useRecoilValue(globalZoomState);
  const isUserAction = useRecoilValue(isUserActionState);
  const activeSimilarType = useRecoilValue(activeSimiliarElementTypeState);
  const [additionalElementIds, setAdditionalElementIds] = useRecoilState(
    additionalElementIdsState
  );

  const handleClickElement = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();

      if (activeSimilarType) {
        const elementType = getTypeById(id);
        if ([TreeItemTypes.BOX, TreeItemTypes.TEXT].includes(elementType)) {
          setAdditionalElementIds((additionalElementIds) =>
            xor(additionalElementIds, [id])
          );
          return;
        }
      }

      id && id !== "*" && updateElements(expandNode(elements, id));

      updateActiveElementId(id === "*" ? undefined : id);

      if (id === "*") {
        updateAddElementId(undefined);
      }
    },
    [id, activeSimilarType, elements]
  );

  // const handleUpdateLayout = useCallback(
  //   ({ nativeEvent: { layout } }) =>
  //     updateElementLayout((previous: any) => {
  //       const current = {
  //         ...layout,
  //         top: (layout as any).top + getTopOffset(),
  //       };

  //       if (isEqual(current, previous)) {
  //         return;
  //       }

  //       return current;
  //     }),
  //   []
  // );

  const handleUpdateLayout = useDebouncedCallback(
    ({ nativeEvent: { layout } }) =>
      updateElementLayout((previous: any) => {
        const current = {
          ...layout,
          top: (layout as any).top + getTopOffset(),
        };

        if (isEqual(current, previous)) {
          return;
        }

        return current;
      }),
    500
  );

  useEffect(() => {
    const runInc = debounce(() => {
      inc();
    }, 500);

    window.addEventListener("resize", runInc);

    let timer: number;

    // if (id !== "*") {
    //   timer = setInterval(runInc, 1000);
    // }

    return () => {
      window.removeEventListener("resize", runInc);
      timer && clearInterval(timer);
    };
  }, []);

  if (type == TreeItemTypes.FLOAT) {
    return (
      <View
        key={
          elementData.styles &&
          `${renderCount}-${elementData.styles.top}-${elementData.styles.left}`
        }
        style={[
          styles,
          elementData.styles,
          adjustOverlayByZoom(
            elementData.styles && {
              top: floatAdjust(
                globalGrid,
                pageSettings,
                "top",
                elementData.styles.top
              ),
              left: floatAdjust(
                globalGrid,
                pageSettings,
                "left",
                elementData.styles.left
              ),
              width: floatAdjust(
                globalGrid,
                pageSettings,
                "width",
                elementData.styles.width
              ),
              height: floatAdjust(
                globalGrid,
                pageSettings,
                "height",
                elementData.styles.height
              ),
            },
            globalZoom.zoomRatio
            // 1 / globalZoom.zoomRatio
          ),
          hoveredElementId === id && hoveredStyles,
          activeElementType === TreeItemTypes.FLOAT && gridStyles,
          activeElementId === id && activeStyles,
          addElementId === id && addStyles,
          hasNoSubelements && {
            alignItems: "center",
            justifyContent: "center",
          },
        ]}
        onLayout={handleUpdateLayout}
        //@ts-ignore
        onClick={handleClickElement}
        // onMouseEnter={() => !isUserAction && updateHoveredElementId(id)}
        // onMouseLeave={() => !isUserAction && updateHoveredElementId(undefined)}
      >
        {(subelements || []).map((el) => (
          <OverlayElement
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}

        {hasNoSubelements ? (
          <button
            className="w-full flex flex-col items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              updateAddElementId(id);

              if (activeElementId === id) {
                updateActiveElementId(undefined);
              }
            }}
          >
            <Plus className="text-gray-400" size={24} />
            {elementData.styles &&
            elementData.styles.width &&
            elementData.styles.width > 120 ? (
              <span className="mt-2 text-gray-400 text-center">
                Add content
              </span>
            ) : null}
          </button>
        ) : null}
      </View>
    );
  }

  if (type === TreeItemTypes.DUMMY) {
    return (
      <View
        key={renderCount}
        style={[
          styles,
          adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
          { backgroundColor: "#F3F4F6" },
        ]}
      ></View>
    );
  }

  if (type === TreeItemTypes.LIST) {
    return (
      <>
        <View
          style={[
            styles,
            // elementData.styles,
            // adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
            {
              width: "100%",
              flexDirection: elementData?.list?.direction ?? "column",
              flexWrap: "wrap",
            },
            hoveredElementId === id && hoveredStyles,
            activeElementId === id && activeStyles,
            addElementId === id && addStyles,
            dropElementId === id && dropStyles,
          ]}
          //@ts-ignore
          onClick={handleClickElement}
        >
          {subelements?.length &&
            range(0, elementData?.list?.preview ?? 1).map((index: number) => (
              <OverlayElement
                key={index}
                id={subelements[0].id}
                type={index === 0 ? subelements[0].type : TreeItemTypes.DUMMY}
                subelements={index === 0 ? subelements[0].children : []}
              />
            ))}
        </View>
        <div className="mt-4">
          <span className="text-xs italic text-gray-400">
            * Only one item is create in the editor
          </span>
        </div>
      </>
    );
  }

  if (type === TreeItemTypes.TABLE) {
    if (!elementData?.table) return null;
    const rng = seedrandom(id);

    return (
      <View
        style={[
          styles,
          elementData.styles,
          adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
          { width: "100%" },
          // hoveredElementId === id && hoveredStyles,
          // activeElementId === id && activeStyles,
          // addElementId === id && addStyles,
          // dropElementId === id && dropStyles,
        ]}
        //@ts-ignore
        onClick={handleClickElement}
      >
        <View
          style={[
            { flexDirection: "row", width: "100%" },
            adjustOverlayByZoom(
              elementData.table.headerStyles,
              globalZoom.zoomRatio
            ),
          ]}
        >
          {elementData.table.columnIds.map(
            (columnId: string, index: number) => (
              <View
                key={columnId}
                style={[
                  // elementData.table.headerStyles,
                  // adjustOverlayByZoom(
                  //   elementData.table.headerStyles,
                  //   globalZoom.zoomRatio
                  // ),
                  { flex: elementData.table.columns[columnId].weight },
                  {
                    marginRight: index === 0 ? 5 : undefined,
                    marginHorizontal:
                      index !== 0 &&
                      index !== elementData.table.columnIds.length - 1
                        ? 5
                        : undefined,
                    marginLeft:
                      index === elementData.table.columnIds.length - 1
                        ? 5
                        : undefined,
                  },
                ]}
              >
                <Text
                  style={[
                    // elementData.table.columns[columnId].headerStyles,
                    adjustOverlayByZoom(
                      elementData.table.columns[columnId].headerStyles,
                      globalZoom.zoomRatio
                    ),
                  ]}
                >
                  {elementData.table.columns[columnId].headerLabel}
                </Text>
              </View>
            )
          )}
        </View>

        {[0, 1, 2].map((v, index) => (
          <View
            key={v}
            style={[
              { flexDirection: "row", width: "100%" },
              adjustOverlayByZoom(
                elementData.table.rowStyles,
                globalZoom.zoomRatio
              ),
              elementData.table?.rowStyles?.isAlternateBackground &&
              index % 2 !== 1
                ? { backgroundColor: "transparent" }
                : {},
            ]}
          >
            {elementData.table.columnIds.map(
              (columnId: string, index: number) => (
                <View
                  key={columnId}
                  style={[
                    // elementData.table.rowStyles,
                    // adjustOverlayByZoom(
                    //   elementData.table.rowStyles,
                    //   globalZoom.zoomRatio
                    // ),
                    { flex: elementData.table.columns[columnId].weight },
                    {
                      marginRight: index === 0 ? 5 : undefined,
                      marginHorizontal:
                        index !== 0 &&
                        index !== elementData.table.columnIds.length - 1
                          ? 5
                          : undefined,
                      marginLeft:
                        index === elementData.table.columnIds.length - 1
                          ? 5
                          : undefined,
                    },
                  ]}
                >
                  <View
                    style={[
                      {
                        alignItems:
                          elementData.table.columns[columnId].bodyStyles
                            .textAlign === "left"
                            ? "flex-start"
                            : elementData.table.columns[columnId].bodyStyles
                                .textAlign === "right"
                            ? "flex-end"
                            : elementData.table.columns[columnId].bodyStyles
                                .textAlign,
                      },
                    ]}
                  >
                    <View
                      style={[
                        adjustOverlayByZoom(
                          {
                            width: `${(rng() * 100).toFixed(0)}%`,
                            height: 16,
                            borderRadius: 8,
                            backgroundColor: "#F3F4F6",
                          },
                          globalZoom.zoomRatio
                        ),
                      ]}
                    />
                  </View>
                </View>
              )
            )}
          </View>
        ))}

        <div
          className="flex flex-col items-start justify-end"
          style={adjustOverlayByZoom({ height: 54 }, globalZoom.zoomRatio)}
        >
          <span className="text-xs italic text-gray-400">
            * Skeleton structure to show sample table
          </span>
        </div>

        <UserActive
          isHovered={hoveredElementId === id}
          isActive={activeElementId === id}
          isAdd={addElementId === id}
          isAdditional={additionalElementIds.includes(id)}
          isEmpty={false}
          isPushOutside={true}
        />
      </View>
    );
  }

  if (type === TreeItemTypes.IMAGE) {
    const isNewImage =
      !elementData?.imageUrl ||
      !elementData?.styles?.height ||
      !elementData?.styles?.width;

    if (isNewImage) {
      return (
        <View
          key={renderCount}
          style={[
            styles,
            adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
            {
              alignItems: "center",
              justifyContent: "center",
            },
            hoveredElementId === id && hoveredStyles,
            activeElementId === id && activeStyles,
          ]}
          onLayout={handleUpdateLayout}
          //@ts-ignore
          onClick={handleClickElement}
          // onMouseEnter={() => !isUserAction && updateHoveredElementId(id)}
          onMouseLeave={() =>
            !isUserAction && updateHoveredElementId(undefined)
          }
        >
          <motion.div
            className="w-full flex flex-col items-center justify-center p-3"
            initial={{ opacity: 0.45 }}
            animate={{ opacity: 1 }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 1,
            }}
          >
            <AlertTriangle className="text-red-400" size={24} />
            <span className="mt-2 text-red-400 text-center text-xs">
              Requires image, height, & width
            </span>
          </motion.div>
        </View>
      );
    }

    return (
      <View
        key={renderCount}
        style={[
          styles,
          adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
          hoveredElementId === id && hoveredStyles,
          activeElementId === id && activeStyles,
          addElementId === id && addStyles,
          { backgroundColor: "transparent", borderColor: "transparent" },
        ]}
        onLayout={handleUpdateLayout}
        //@ts-ignore
        onClick={handleClickElement}
        // onMouseEnter={() => !isUserAction && updateHoveredElementId(id)}
        onMouseLeave={() => !isUserAction && updateHoveredElementId(undefined)}
      />
    );
  }

  if (type === TreeItemTypes.TEXT) {
    return (
      <View>
        <Text
          key={renderCount}
          style={[
            styles,
            adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
            // hoveredElementId === id && hoveredStyles,
            // activeElementId === id && activeStyles,
            // addElementId === id && addStyles,
            // additionalElementIds.includes(id) && additionalSelectedStyles,
            {
              color: "transparent",
              fontSize: elementData?.styles?.fontSize * globalZoom.zoomRatio,
            },
          ]}
          // onLayout={handleUpdateLayout}
          //@ts-ignore
          onClick={handleClickElement}
        >
          {elementData.textContent}
        </Text>

        <UserActive
          isHovered={hoveredElementId === id}
          isActive={activeElementId === id}
          isAdd={addElementId === id}
          isAdditional={additionalElementIds.includes(id)}
        />
      </View>
    );
  }

  if (type === TreeItemTypes.BOX && id !== "*") {
    const isNewBox =
      !elementData?.styles?.height || !elementData?.styles?.width;

    return (
      <View
        key={renderCount}
        style={[
          styles,
          // elementData.styles,
          adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
          // hoveredElementId === id && hoveredStyles,
          // activeElementId === id && activeStyles,
          // addElementId === id && addStyles,
          // additionalElementIds.includes(id) && additionalSelectedStyles,
          { backgroundColor: "transparent" },
        ]}
        //@ts-ignore
        onClick={handleClickElement}
        onLayout={handleUpdateLayout}
      >
        {(subelements || []).map((el) => (
          <OverlayElement
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}

        {hasNoSubelements && isNewBox ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              updateAddElementId(id);

              if (activeElementId === id) {
                updateActiveElementId(undefined);
              }
            }}
          >
            <Plus className="text-gray-400" size={24} />
            <span className="mt-2 text-gray-400 text-center">
              Add box content
            </span>
          </button>
        ) : null}

        <UserActive
          isHovered={hoveredElementId === id}
          isActive={activeElementId === id}
          isAdd={addElementId === id}
          isAdditional={additionalElementIds.includes(id)}
          isParent={!hasNoSubelements}
        />
      </View>
    );
  }

  if (type === TreeItemTypes.COLUMN) {
    return (
      <View
        key={renderCount}
        style={[
          styles,
          adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
          (hasNoSubelements && {
            height: "100%",
            flex: elementData.styles?.flex ?? 1,
            // borderColor: "#E5E7EB",
            // borderTopWidth: 2,
            // borderBottomWidth: 2,
            // borderRightWidth: 2,
            // borderLeftWidth: 2,
            // borderStyle: "dashed",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }) || {
            borderColor: "transparent",
            // borderTopWidth: 2,
            // borderBottomWidth: 2,
            // borderRightWidth: 2,
            // borderLeftWidth: 2,
          },
          // hoveredElementId === id && hoveredStyles,
          // activeElementId === id && activeStyles,
          // addElementId === id && addStyles,
          // dropElementId === id && dropStyles,
          {
            backgroundColor: "transparent",
          },
        ]}
        //@ts-ignore
        onClick={handleClickElement}
        onLayout={handleUpdateLayout}
      >
        {(subelements || []).map((el) => (
          <OverlayElement
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}

        {hasNoSubelements ? (
          <button
            className="w-full flex flex-col items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              updateAddElementId(id);

              if (activeElementId === id) {
                updateActiveElementId(undefined);
              }
            }}
          >
            <Plus className="text-gray-400" size={24} />
            <span className="mt-2 text-gray-400 text-center">
              Add column content
            </span>
          </button>
        ) : null}

        <UserActive
          isHovered={hoveredElementId === id}
          isActive={activeElementId === id}
          isAdd={addElementId === id}
          isAdditional={additionalElementIds.includes(id)}
          isEmpty={hasNoSubelements}
        />
      </View>
    );
  }

  if (type === TreeItemTypes.ROW) {
    const addColumns = (count: number) => {
      const newNodeIds = range(count).map(() =>
        generateId(TreeItemTypes.COLUMN)
      );
      const newElementDatas = newNodeIds.reduce((pv: any, id) => {
        pv[id] = generateDefaultData(TreeItemTypes.COLUMN, {});
        return pv;
      }, {});

      updateElements((elements) =>
        map({
          treeData: elements,
          getNodeKey,
          ignoreCollapsed: false,
          callback: ({ node }: TreeNode) =>
            node.id !== id
              ? node
              : {
                  ...node,
                  expanded: true,
                  children: range(count).map((item, index) =>
                    createNewElement(TreeItemTypes.COLUMN, newNodeIds[index])
                  ),
                },
        })
      );

      updateElementsData((elementsData: any) => ({
        ...elementsData,
        ...newElementDatas,
      }));

      // updateActiveElementId(newNodeIds[0]);
    };

    return (
      <View
        //@ts-ignore
        id={id}
        key={renderCount}
        style={[
          styles,
          adjustOverlayByZoom(elementData.styles, globalZoom.zoomRatio),
          (hasNoSubelements && {
            width: "100%",
            // borderColor: "#E5E7EB",
            // borderTopWidth: 2,
            // borderBottomWidth: 2,
            // borderRightWidth: 2,
            // borderLeftWidth: 2,
            // borderStyle: "dashed",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }) || {
            borderColor: "transparent",
            backgroundColor: "transparent",
            // borderTopWidth: 2,
            // borderBottomWidth: 2,
            // borderRightWidth: 2,
            // borderLeftWidth: 2,
          },
          // hoveredElementId === id && hoveredStyles,
          // activeElementId === id && activeStyles,
          // addElementId === id && addStyles,
          // dropElementId === id && dropStyles,
          // {
          //   borderColor: "transparent",
          // },
        ]}
        onLayout={handleUpdateLayout}
        //@ts-ignore
        onClick={handleClickElement}
      >
        {(subelements || []).map((el) => (
          <OverlayElement
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}

        {hasNoSubelements ? (
          <div className="w-full py-4 px-8">
            <div className="mb-4">
              <span className="text-gray-500">
                Quickly add columns for the new row
              </span>
            </div>
            <div className="flex items-center justify-between w-full">
              <button
                className="flex flex-col items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addColumns(1);
                }}
              >
                <div className="h-16 w-24 flex mb-2">
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                </div>
                <span className="text-gray-500">1</span>
              </button>
              <button
                className="flex flex-col items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addColumns(2);
                }}
              >
                <div className="h-16 w-24 flex mb-2">
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                </div>
                <span className="text-gray-500">2</span>
              </button>
              <button
                className="flex flex-col items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addColumns(3);
                }}
              >
                <div className="h-16 w-24 flex mb-2">
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                </div>
                <span className="text-gray-500">3</span>
              </button>
              <button
                className="flex flex-col items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addColumns(4);
                }}
              >
                <div className="h-16 w-24 flex mb-2">
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                </div>
                <span className="text-gray-500">4</span>
              </button>
              <button
                className="flex flex-col items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addColumns(5);
                }}
              >
                <div className="h-16 w-24 flex mb-2">
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                  <div
                    className="bg-gray-300 flex-1 h-full rounded-sm"
                    style={{ marginRight: "1px", marginLeft: "1px" }}
                  ></div>
                </div>
                <span className="text-gray-500">5</span>
              </button>
            </div>
          </div>
        ) : undefined}

        <UserActive
          isHovered={hoveredElementId === id}
          isActive={activeElementId === id}
          isAdd={addElementId === id}
          isAdditional={additionalElementIds.includes(id)}
          isEmpty={hasNoSubelements}
          // isPushOutside={true}
          isParent={!hasNoSubelements}
        />
      </View>
    );
  }

  return (
    <View
      key={renderCount}
      style={[styles, elementData.styles, { backgroundColor: "transparent" }]}
      //@ts-ignore
      onClick={handleClickElement}
      // onMouseEnter={() => !isUserAction && updateHoveredElementId(id)}
      // onMouseLeave={() => !isUserAction && updateHoveredElementId(undefined)}
      onLayout={handleUpdateLayout}
    >
      {id === "*" && hasNoSubelements ? (
        <View
          style={{
            height: "50%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EmptyPageQuickActions />
        </View>
      ) : subelements?.length ? (
        subelements?.map((el) => (
          <OverlayElement
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))
      ) : null}
    </View>
  );
};

// export default memo(OverlayElement);
export default OverlayElement;
