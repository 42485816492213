import { useMutation } from "react-query";
import { useAuth } from "reactfire";
import {
  Auth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { User } from "../../models/api.model";

const handleSignup = async (
  auth: Auth,
  firstName: string,
  email: string,
  password: string
): Promise<User> => {
  if (!firstName || !email || !password) {
    throw Error("Not the right information.");
  }

  const user = await createUserWithEmailAndPassword(auth, email, password);
  await sendEmailVerification(user.user);
  const uid = user.user?.uid;

  const token = await user.user.getIdToken();
  const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      externalAuthId: uid,
      firstName,
    }),
  });
  const json = await response.json();
  await fetch('https://emailoctopus.com/api/1.5/lists/bceee11c-5e27-11ec-96e5-06b4694bee2a/contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "api_key": process.env.REACT_APP_EMAILOCTOPUS_API_KEY,
      "email_address": email,
      "fields": {"FirstName": firstName},
      "status":"SUBSCRIBED"
    })
  });
  return json.user;
};

export const useSignup = () => {
  const auth = useAuth();

  return useMutation(({ firstName, email, password }: any) =>
    handleSignup(auth, firstName, email, password)
  );
};
