import { useMutation, useQueryClient } from "react-query";
import { useFirestore, useUser } from "reactfire";
import { doc, Firestore, updateDoc } from "firebase/firestore";
import { useProfile } from "../queries/useProfile";
import { Profile, User } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const handleRemoveShowWelcome = async (
  token: string,
  profile: Profile | undefined
): Promise<User> => {
  if (!profile) {
    throw Error("No profile available");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users/${profile.id}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        isShowWelcome: false,
      }),
    }
  );

  const json = await response.json();
  return json.user;
};

export const useRemoveShowWelcome = () => {
  const { data: token } = useIdToken();
  const { data: profile } = useProfile();
  const queryClient = useQueryClient();

  return useMutation(() => handleRemoveShowWelcome(token as string, profile), {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
    },
  });
};
