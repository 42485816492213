import {
  Info,
  MoreHorizontal,
  MoreVertical,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Pause,
  Square,
} from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { set } from "monolite";

import EditorButton from "../../../components/EditorButton";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";
import { useState } from "react";

enum HelpItems {
  DIRECTION = "DIRECTION",
  ALIGN = "ALIGN",
  JUSTIFY = "JUSTIFY",
}

const ListConfig = () => {
  const [activeHelp, setActiveHelp] = useState<HelpItems>();
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  return (
    <EditorAccordion label="List Configuration">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Data accessor</span>
          <EditorInfo helpLabel="Data accessor" />
        </div>

        <input
          placeholder="Ex. results"
          className="border border-gray-100 rounded-md p-2"
          value={elementData.list.accessor ?? ""}
          onChange={(evt) =>
            updateElementData(
              set(elementData, ["list", "accessor"], evt.target.value)
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">List direction</span>
          <EditorInfo helpLabel="List direction" />
        </div>

        <div className="flex mb-2">
          <EditorButton
            iconComponent={
              <MoreHorizontal className="text-gray-600" size={12} />
            }
            label="Row"
            onClick={() =>
              updateElementData((elementData: any) =>
                set(elementData, ["list", "direction"], "row")
              )
            }
            isSelected={elementData.list.direction === "row"}
          />
          <EditorButton
            iconComponent={<MoreVertical className="text-gray-600" size={12} />}
            label="Column"
            onClick={() =>
              updateElementData((elementData: any) =>
                set(elementData, ["list", "direction"], "column")
              )
            }
            isSelected={elementData.list.direction === "column"}
          />
        </div>

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Preview items</span>
          <EditorInfo helpLabel="Preview items" />
        </div>

        <select
          onChange={(evt) =>
            updateElementData(
              set(elementData, ["list", "preview"], evt.target.value)
            )
          }
          value={elementData.list.preview}
          className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </select>
      </div>
    </EditorAccordion>
  );
};

export default ListConfig;
