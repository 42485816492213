import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";

const startSubscription = async (
  token: string,
  organizationId: string | undefined,
  priceId: string,
  successUrl: string,
  cancelUrl: string
) => {
  if (!organizationId) {
    throw Error("No active organization found.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/stripe-start`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId,
        successUrl,
        cancelUrl,
      }),
    }
  );

  const json = await response.json();

  return json.session;
};

interface StartSubscriptionVars {
  priceId: string;
  successUrl: string;
  cancelUrl: string;
}

export const useStartSubscription = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useMutation(
    ({ priceId, successUrl, cancelUrl }: StartSubscriptionVars) =>
      startSubscription(
        token as string,
        organizationId,
        priceId,
        successUrl,
        cancelUrl
      )
  );
};
