import { useMutation, useQueryClient } from "react-query";
import { Auth, getAuth, signOut } from "firebase/auth";
import { useSetRecoilState } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";

const handleLogout = async (auth: Auth) => {
  await signOut(auth);
};

export const useLogout = () => {
  const auth = getAuth();
  const queryClient = useQueryClient();
  const setActiveOrganizationId = useSetRecoilState(activeOrganizationIdState);
  return useMutation(() => handleLogout(auth), {
    onSuccess: () => {
      queryClient.clear();
      setActiveOrganizationId(undefined);
      sessionStorage.removeItem("activeOrganizationId");
    },
  });
};
