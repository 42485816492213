import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import Banner from "../../components/Banner";
import ActivityChart from "./ActivityChart";
import { GenericTable } from "../../components/GenericTable";
import { useDailyRequests } from "../../hooks/queries/useDailyRequests";
import { range, reverse } from "lodash";
import { useMemo } from "react";
import { useOrganizationSubscription } from "../../hooks/queries/useOrganizationSubscription";

dayjs.extend(utc);

const defaultUsageDay = {
  total: 0,
  success: 0,
  failure: 0,
  usedCredits: 0,
  apiKeys: [],
};

const COLUMNS = [
  {
    id: "date",
    Header: "Date",
    accessor: "date",
    Cell: ({ value }: { value: string }) => dayjs(value).format("LL"),
  },
  { id: "requests", Header: "# of Requests", accessor: "total" },
  {
    id: "apiKeys",
    Header: "Unique API Keys",
    accessor: "apiKeys",
    Cell: ({ value }: { value: string[] }) => value.length,
  },
  {
    id: "successes",
    Header: "Successes",
    accessor: "success",
    Cell: ({ value }: { value: number | null }) => value || 0,
  },
  {
    id: "failures",
    Header: "Failures",
    accessor: "failure",
    Cell: ({ value }: { value: number | null }) => value || 0,
  },
];

const Home = () => {
  const { data } = useDailyRequests();
  const { data: subscription } = useOrganizationSubscription();

  const progressBarWidth = (isStyle: boolean) =>
    subscription?.plan?.monthlyPagesLimit
      ? `${
          (Math.min(
            Math.max(subscription?.plan?.monthlyPagesLimit ?? 0, 0),
            isStyle
              ? Math.max(
                  subscription?.usage?.pages ?? 0,
                  (subscription?.plan?.monthlyPagesLimit ?? 0) * 0.05
                )
              : subscription?.usage?.pages ?? 0
          ) /
            subscription?.plan?.monthlyPagesLimit) *
          100
        }%`
      : "0%";

  const badgeColor = () => {
    if (
      (subscription?.usage?.pages ?? 0) /
        (subscription?.plan?.monthlyPagesLimit ?? 0) >=
      1
    ) {
      return "bg-red-100 text-red-500";
    } else if (
      (subscription?.usage?.pages ?? 0) /
        (subscription?.plan?.monthlyPagesLimit ?? 0) >=
      0.8
    ) {
      return "bg-yellow-100 text-yellow-500";
    } else {
      return "bg-green-100 text-green-500";
    }
  };

  return (
    <>
      <Banner />

      <h3 className="font-normal text-lg">
        {subscription?.plan?.name} plan usage{" "}
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${badgeColor()}`}
        >
          {subscription?.usage?.pages ?? 0} of{" "}
          {subscription?.plan?.monthlyPagesLimit ?? 0} used
        </span>
        <span className="block text-sm font-light mb-4 mt-1">
          {`${dayjs(subscription?.currentPeriodStart).format("ll")} to ${dayjs(
            subscription?.currentPeriodEnd
          ).format("ll")} period`}
        </span>
      </h3>
      <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mb-7">
        <div
          className="bg-gradient-to-tr from-red-400 to-red-500 text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
          style={{
            width: progressBarWidth(true),
          }}
        >
          {progressBarWidth(false)}
        </div>
      </div>

      <h3 className="font-normal text-lg">Recent Activity</h3>
      <span className="block text-sm font-light mb-4 mt-1">
        {`See insights on your usage from ${dayjs(data?.[0]?.date).format(
          "ll"
        )} to ${dayjs(data?.[14]?.date).format("ll")}.`}
      </span>

      <ActivityChart data={data ?? []} />
      <GenericTable
        columns={COLUMNS}
        data={(data && [...data].reverse()) || []}
        pSize={20}
        headerText="Usage per day"
        headerDescription={`See insights on your usage from ${dayjs(
          data?.[0]?.date
        ).format("ll")} to ${dayjs(data?.[14]?.date).format("ll")}.`}
      />
    </>
  );
};

export default Home;
