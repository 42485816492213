import { useState, Fragment, useRef } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { useDeleteOrganization } from "../../hooks/mutations/useDeleteOrganization";
import { useOrganization } from "../../hooks/queries/useOrganization";
import { ExclamationIcon } from "@heroicons/react/outline";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const DeleteOrganizationModal = ({ isOpen, closeModal }: ModalProps) => {
  const { data: organization } = useOrganization();
  const { mutate: mutateDeleteOrganization, isLoading } = useDeleteOrganization();
  const [currentOrganization, setCurrentOrganization] = useState("");

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-90"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full sm:max-w-lg sm:align-middle p-6 my-8 transition-all transform bg-white shadow-xl rounded-md">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  Delete Organization
                </Dialog.Title>
                <div className="mt-2 text-sm space-y-2">
                  <p className="font-light text-gray-500">This is a permanent action. Once you delete your organization all templates, api keys, and associated applications no longer function. Please cancel any active subscription before proceeding.</p>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  mutateDeleteOrganization();
                  closeModal();
                }}
                className="font-light mt-4"
              >
                <div className="flex flex-col">
                  <div>
                    <label className="block text-sm font-medium text-gray-500">
                      {`Type "${organization?.name}" to delete the organization`}
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md text-center"
                      value={currentOrganization}
                      onChange={(evt) =>
                        setCurrentOrganization(evt.target.value)
                      }
                    />
                  </div>
                  <div className="mt-4 flex space-x-2">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="inline-flex justify-center items-center space-x-1 w-full rounded-md text-sm px-4 py-2 bg-gray-200 font-medium text-gray-700 hover:bg-gray-100 focus:outline-none"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={(organization?.name !== currentOrganization) || isLoading}
                      className="inline-flex justify-center items-center space-x-1 w-full rounded-md text-sm px-4 py-2 bg-red-500 font-medium text-white hover:bg-red-400 focus:outline-none"
                    >
                      Delete organization
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteOrganizationModal;