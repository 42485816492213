import { useMutation, useQueryClient } from "react-query";
import { useIdToken } from "../queries/useIdToken";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";

const renameTemplateName = async (
  token: string,
  organizationId: string | undefined,
  activeRename: any,
  updatedName: string
) => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/templates/${activeRename.id}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: updatedName,
      }),
    }
  );

  const json = await response.json();
  return json.template;
};

export const useRenameTemplate = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    ({ updatedName, activeRename }: any) =>
      renameTemplateName(
        token as string,
        organizationId,
        activeRename,
        updatedName
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("templates");
      },
    }
  );
};
