import { ReactNode } from "react";
import { Info } from "react-feather";
import { useToggle } from "react-use";

import HelpModal from "../HelpModal";

const defaultContent = (
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Bibendum ut tristique et
    egestas quis ipsum.
  </p>
);

interface EditorInfoProps {
  helpLabel: string;
  helpContent?: ReactNode;
}

const EditorInfo = ({
  helpLabel,
  helpContent = defaultContent,
}: EditorInfoProps) => {
  const [isActive, toggleActive] = useToggle(false);

  return null;

  return (
    <>
      <button className="focus:outline-none" onClick={toggleActive}>
        <Info size={16} className="text-gray-500" />
      </button>

      <HelpModal
        isVisible={isActive}
        close={toggleActive}
        label={helpLabel}
        children={helpContent}
      />
    </>
  );
};

export default EditorInfo;
