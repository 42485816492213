import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Organization } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const updateOrganization = async (
  token: string,
  organizationId: string | undefined,
  update: any
) => {
  if (!organizationId) {
    throw Error("No active organization");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    }
  );

  const json = await response.json();
  return json.organization as Organization;
};

export const useUpdateOrganization = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    (update: any) =>
      updateOrganization(token as string, organizationId, update),
    {
      onSuccess: (data) => {
        toast.success("Successfully saved global setting!");
        queryClient.invalidateQueries("organization");
        queryClient.invalidateQueries("organizations");
        queryClient.invalidateQueries("profile");
      },
      onError: () => {
        toast.error("Something went wrong!");
      },
    }
  );
};
