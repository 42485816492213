import {
  findIndex,
  omit,
  keys,
  isNumber,
  toNumber,
  toString,
  isString,
  defer,
} from "lodash";
import {
  TreeItem,
  NodeData,
  changeNodeAtPath,
  walk,
  TreeNode,
  map,
  removeNodeAtPath,
} from "react-sortable-tree";
import { v4 } from "uuid";
import { useRecoilCallback } from "recoil";

import { TreeItemTypes } from "../models/editor.model";

type Styles = {
  [name: string]: number | string | undefined;
};

const ZOOM_ADJUSTED_FIELDS = [
  "marginTop",
  "marginBottom",
  "marginLeft",
  "marginRight",
  "paddingTop",
  "paddingBottom",
  "paddingLeft",
  "paddingRight",
  "height",
  "width",
  "top",
  "left",
  "right",
  "bottom",
  "fontSize",
];

export const elementNameByType = (type: TreeItemTypes) => {
  switch (type) {
    case TreeItemTypes.COLUMN:
      return "Column";
    case TreeItemTypes.ROW:
      return "Row";
    case TreeItemTypes.BOX:
      return "Box";
    case TreeItemTypes.TEXT:
      return "Text";
    case TreeItemTypes.IMAGE:
      return "Image";
    case TreeItemTypes.TABLE:
      return "Table";
    case TreeItemTypes.LIST:
      return "List";
    case TreeItemTypes.FLOAT:
      return "Float";
  }
};

export const generateId = (type: string | undefined) =>
  `${v4()}:${type || "box"}`;

export const createNewElement = (type: TreeItemTypes, id?: string) => ({
  id: id || generateId(type),
  type,
  title: elementNameByType(type),
  expanded: true,
  children: [],
});

export const addNodeToTree = (
  tree: TreeItem[],
  path: (string | number)[],
  node: TreeItem,
  previousNodeId: string | null
) => {
  if (!path || !path.length) {
    return tree.reduce((pv: any, element: any, index: number) => {
      pv.push(element);
      if (element.id === previousNodeId) {
        pv.push(node);
      }

      if (!previousNodeId && index === tree.length - 1) {
        pv.push(node);
      }

      return pv;
    }, []);
  }

  return tree.reduce((pv: any, branch: any) => {
    if (branch.id === path[0] && path.length === 1) {
      const updatedChildren = branch?.children?.length
        ? branch.children.reduce((pv: any, element: any, index: number) => {
            pv.push(element);
            if (element.id === previousNodeId) {
              pv.push(node);
            }

            if (!previousNodeId && index === branch.children.length - 1) {
              pv.push(node);
            }

            return pv;
          }, [])
        : [node];

      pv = [
        ...pv,
        {
          ...branch,
          children: updatedChildren,
          expanded: true,
        },
      ];
    } else if (branch.id === path[0]) {
      pv = [
        ...pv,
        {
          ...branch,
          children: addNodeToTree(
            branch.children,
            path.slice(1),
            node,
            previousNodeId
          ),
        },
      ];
    } else {
      pv = [...pv, branch];
    }

    return pv;
  }, []);
};

export const addNodeUnderParentElement = (
  treeData: TreeItem[],
  flatElement: TreeItem,
  newNode: TreeItem
) =>
  changeNodeAtPath({
    treeData,
    path: flatElement?.path as (string | number)[],
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
    newNode: {
      ...flatElement?.node,
      children: [...(flatElement?.node.children as any[]), newNode],
    },
  });

export const generateStyles = (base = {}, type = TreeItemTypes.BOX) => {
  let styles = {};

  switch (type) {
    case TreeItemTypes.ROW:
      styles = {
        position: "relative",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        // width: "100%",
        width: undefined,
        height: undefined,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        // overflow: "hidden",
      };
      break;

    case TreeItemTypes.COLUMN:
      styles = {
        position: "relative",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flex: 1,
        width: "auto",
        height: "100%",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        // overflow: "hidden",
      };
      break;

    case TreeItemTypes.BOX:
      styles = {
        position: "relative",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        overflow: "hidden",
        width: undefined,
        height: undefined,
      };
      break;

    case TreeItemTypes.TEXT:
      styles = {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "18px",
        fontFamily: "Open Sans",
        textAlign: "left",
        color: "#111827",
      };
      break;

    case TreeItemTypes.IMAGE:
      styles = {
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        overflow: "hidden",
      };
      break;

    case TreeItemTypes.TABLE:
      styles = {
        backgroundColor: "#FFFFFF",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
      };
      break;

    case TreeItemTypes.FLOAT:
      styles = {
        position: "absolute",
        overflow: "hidden",
      };
  }

  // let styles = {
  //   paddingTop: 0,
  //   paddingLeft: 0,
  //   paddingRight: 0,
  //   paddingBottom: 0,
  //   ...base
  // } as any

  // if (type === "text") {
  //   styles = {
  //     fontSize: 14,
  //     fontWeight: 400,
  //     lineHeight: '18px',
  //     fontFamily: (base as any).fontFamily || 'Open Sans',
  //   }
  // }

  // if (type === "image") {
  //   styles = {
  //     ...styles,
  //     paddingTop: 0,
  //     paddingLeft: 0,
  //     paddingRight: 0,
  //     paddingBottom: 0,
  //   }
  // }

  // if (type === "row") {
  //   styles.flexDirection = 'row';
  //   styles.paddingTop = 0;
  //   styles.paddingLeft = 0;
  //   styles.paddingRight = 0;
  //   styles.paddingBottom = 0;
  // }
  // if (type === "table") {
  //   styles.width = "100%";
  // }

  return {
    ...styles,
    ...base,
  };
};

export const generateColumnDefaults = () => ({
  accessor: "",
  weight: 1,
  headerLabel: "",
  headerStyles: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Open Sans",
    lineHeight: "20px",
    textAlign: "left",
  },
  bodyStyles: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Open Sans",
    lineHeight: "20px",
    textAlign: "left",
  },
});

export const generateDefaultData = (
  type: TreeItemTypes | undefined,
  baseStyles = {}
) => {
  const data: any = {
    styles: generateStyles(baseStyles, type),
    textContent: "",
    imageUrl: "",
  };

  if (type === "table") {
    const firstColumn = v4();

    data["table"] = {
      accessor: "",
      // columnIds: [firstColumn,],
      columnIds: [],
      columns: {
        // [firstColumn]: generateColumnDefaults()
      },
      headerStyles: {
        backgroundColor: "#FFFFFF",
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        // marginTop: 0,
        // marginBottom: 0,
        // marginRight: 0,
        // marginLeft: 0,
      },
      rowStyles: {
        backgroundColor: "#FFFFFF",
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        // marginTop: 0,
        // marginBottom: 0,
        // marginRight: 0,
        // marginLeft: 0,
        isAlternateBackground: false,
      },
    };
  }

  if (type === "list") {
    data["list"] = {
      accessor: "",
      direction: "column",
      preview: 1,
    };
  }

  return data;
};

export const generateDefaultPageSettings = () => ({
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  fontFamily: "Open Sans",
});

export const generateNewPageDefaults = () => ({
  id: v4(),
  name: "New page",
  tree: [],
  data: {},
  pageSettings: generateDefaultPageSettings(),
});

export const duplicateNode = (
  sourceNode: any,
  parentNode: any,
  updateElements: (value: any) => void,
  updateElementsData: (value: any) => void,
  updateActiveElementId: (value: string) => void
) => {
  const copyNodeIds = [] as string[];
  walk({
    treeData: [sourceNode],
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
    callback: ({ node }: TreeNode) => copyNodeIds.push(node.id),
  });

  const newNodeIds = copyNodeIds.map((v) => `${v4()}:${v.split(":")[1]}`);

  const newNode = map({
    treeData: [sourceNode],
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
    callback: ({ node }: TreeNode) => ({
      ...node,
      id: newNodeIds[findIndex(copyNodeIds, (v) => node.id === v)],
    }),
  })[0];

  updateElements((elements: TreeItem[]) => {
    let update;

    if (!parentNode) {
      update = (elements as any[]).reduce((pv, element) => {
        pv.push(element);
        if (element.id === sourceNode.id) {
          pv.push(newNode);
        }
        return pv;
      }, []);
    } else {
      update = map({
        treeData: elements,
        getNodeKey: ({ node }) => node.id,
        ignoreCollapsed: false,
        callback: ({ node }: TreeNode) =>
          node.id !== parentNode.id
            ? node
            : {
                ...node,
                children: (parentNode.children as any[]).reduce(
                  (pv, element) => {
                    pv.push(element);
                    if (element.id === sourceNode.id) {
                      pv.push(newNode);
                    }
                    return pv;
                  },
                  []
                ),
              },
      });
    }

    return update;
  });

  updateElementsData((elementsData: any) =>
    copyNodeIds.reduce(
      (pv, copyId) => {
        const sisterNodeId =
          newNodeIds[findIndex(copyNodeIds, (v) => v === copyId)];
        pv[sisterNodeId] = elementsData[copyId];
        return pv;
      },
      { ...elementsData }
    )
  );

  updateActiveElementId(newNodeIds[0]);
};

export const deleteNode = (
  sourceNode: any,
  path: string[],
  updateElements: (value: any) => void,
  updateElementsData: (value: any) => void,
  updateActiveElementId: (value: string | undefined) => void
) => {
  updateActiveElementId(undefined);

  defer(() => {
    updateElements((elements: any) =>
      removeNodeAtPath({
        treeData: elements,
        getNodeKey: ({ node }) => node.id,
        ignoreCollapsed: false,
        path,
      })
    );

    const nodeIds = [] as string[];
    walk({
      treeData: [sourceNode],
      getNodeKey: ({ node }) => node.id,
      ignoreCollapsed: false,
      callback: ({ node }: TreeNode) => nodeIds.push(node.id),
    });

    updateElementsData((elementsData: any) => omit(elementsData, nodeIds));
  });
};

export const floatAdjust = (
  globalGrid: any,
  pageSettings: any,
  attr: "top" | "left" | "height" | "width",
  value: number | string
) => {
  switch (attr) {
    case "top":
      return (
        pageSettings.paddingTop +
        (isNumber(value)
          ? value
          : (Number(value.replace("%", "")) / 100) * globalGrid.height)
      );
    case "left":
      return (
        pageSettings.paddingLeft +
        (isNumber(value)
          ? value
          : (Number(value.replace("%", "")) / 100) * globalGrid.width)
      );
    case "height":
      return isNumber(value)
        ? value
        : (Number(value.replace("%", "")) / 100) * globalGrid.height;
    case "width":
      return isNumber(value)
        ? value
        : (Number(value.replace("%", "")) / 100) * globalGrid.width;
  }
};

/**
 * Takes a styles object and current zoom ratio to output an updated
 * set of styles so that matches the screen expectations.
 * @param styles Yoga styles object
 * @param zoomRatio Number set to current zoom ratio
 * @returns Updated yoga styles object
 */
export const adjustOverlayByZoom = (styles: Styles, zoomRatio: number = 1) => {
  if (!styles) return {};

  const adjusted = keys(styles).reduce((pv: Styles, key: string) => {
    if (ZOOM_ADJUSTED_FIELDS.includes(key)) {
      pv[key] = isNumber(styles[key])
        ? Math.round((styles[key] as number) * zoomRatio)
        : styles[key];
    } else {
      pv[key] = styles[key];
    }
    return pv;
  }, {} as Styles);

  if (!!styles?.lineHeight) {
    adjusted.lineHeight = `${
      toNumber(toString(styles.lineHeight).replace("px", "")) * zoomRatio
    }px`;
  }

  if (!!styles?.fontSize) {
    adjusted.fontSize = Math.round(adjusted.fontSize as number);
  }

  return {
    ...adjusted,
    borderColor: "transparent",
  };
};

/**
 *
 * @returns returns the scrolled offset of the current scrollable body
 */
export const getTopOffset = () =>
  document?.getElementById("main-scrollable")?.scrollTop ?? 0;

export const getNumberFromString = (str: string | number | undefined) =>
  (str &&
    toNumber(
      toString(str)
        .split("")
        .filter((c) => !isNaN(toNumber(c)))
        .join("")
    )) ||
  0;

export const fixEditorTextStyles = (styles: Styles) => {
  const fontSize = getNumberFromString(styles?.fontSize);
  // const paddingBottom = getNumberFromString(styles.paddingBottom);
  // const lineHeight = getNumberFromString(styles.lineHeight);

  return {
    ...styles,
    lineHeight: `${fontSize}px`,
    marginBottom: 0,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    // paddingBottom: `${paddingBottom + lineHeight - fontSize}px`,
  };
};

export const getTypeById = (elementId: string) =>
  elementId.split(":")[1] as TreeItemTypes;

export const checkPageIds = (pages: any[]) =>
  pages.map((page, index) => ({
    ...page,
    id: page.id || v4(),
    name: page.name || `Page ${index + 1}`,
  }));
