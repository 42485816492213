import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  memo,
} from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { map, TreeNode } from "react-sortable-tree";
import { set } from "monolite";
import {
  Plus,
  CreditCard,
  Type,
  Menu,
  Image as ImageIcon,
} from "react-feather";
import { motion } from "framer-motion";

import { v4 } from "uuid";
import { range, debounce, isNumber, toNumber } from "lodash";
import { Edit, PlusCircle, AlertTriangle } from "react-feather";
import {
  activeElementIdState,
  activeElementTypeState,
  addElementIdState,
  dropElementIdState,
  elementDataState,
  elementLayoutState,
  elementPageSettingsState,
  elementsDataState,
  elementsState,
  globalFloatingGridState,
  globalZoomState,
  hoveredElementIdState,
} from "./editor.atom";
import { TreeItemTypes } from "../../models/editor.model";
import {
  createNewElement,
  fixEditorTextStyles,
  floatAdjust,
  generateDefaultData,
  generateId,
  getNumberFromString,
} from "../../utils/editor.util";
import { useCounter, useDebounce } from "react-use";
import { EmptyPageQuickActions } from "./Element.components";
import seedrandom from "seedrandom";

interface ElementProps {
  id: string;
  type?: TreeItemTypes;
  styles?: any;
  subelements?: any[];
}

const Element = ({
  id,
  type = TreeItemTypes.BOX,
  styles,
  subelements,
}: ElementProps) => {
  const [renderCount, { inc }] = useCounter();
  const [activeElementId, updateActiveElementId] =
    useRecoilState(activeElementIdState);
  const updateAddElementId = useSetRecoilState(addElementIdState);

  const elementData = useRecoilValue(elementDataState(id));
  const hasNoSubelements = useMemo(() => !subelements?.length, [subelements]);
  const pageSettings = useRecoilValue(elementPageSettingsState);
  const globalGrid = useRecoilValue(globalFloatingGridState);
  const globalZoom = useRecoilValue(globalZoomState);

  useEffect(() => {
    const runInc = debounce(() => {
      inc();
    }, 500);

    window.addEventListener("resize", runInc);
    return () => window.removeEventListener("resize", runInc);
  }, []);

  if (type == TreeItemTypes.FLOAT) {
    return (
      <View
        key={
          elementData.styles &&
          `${renderCount}-${elementData.styles.top}-${elementData.styles.left}`
        }
        style={[
          styles,
          elementData.styles,
          elementData.styles && {
            top: floatAdjust(
              globalGrid,
              pageSettings,
              "top",
              elementData.styles.top
            ),
            left: floatAdjust(
              globalGrid,
              pageSettings,
              "left",
              elementData.styles.left
            ),
            width: floatAdjust(
              globalGrid,
              pageSettings,
              "width",
              elementData.styles.width
            ),
            height: floatAdjust(
              globalGrid,
              pageSettings,
              "height",
              elementData.styles.height
            ),
          },
        ]}
      >
        {(subelements || []).map((el) => (
          <Element
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}
      </View>
    );
  }

  if (type === TreeItemTypes.DUMMY) {
    return (
      <View
        key={renderCount}
        style={[styles, elementData.styles, { backgroundColor: "#F3F4F6" }]}
      ></View>
    );
  }

  if (type === TreeItemTypes.LIST) {
    return (
      <View
        style={[
          styles,
          {
            width: "100%",
            flexDirection: elementData?.list?.direction ?? "column",
            flexWrap: "wrap",
            marginBottom: 30,
          },
        ]}
      >
        {subelements?.length &&
          range(0, elementData?.list?.preview ?? 1).map((index: number) => (
            <Element
              key={index}
              id={subelements[0].id}
              type={index === 0 ? subelements[0].type : TreeItemTypes.DUMMY}
              subelements={index === 0 ? subelements[0].children : []}
            />
          ))}
      </View>
    );
  }

  if (type === TreeItemTypes.TABLE) {
    if (!elementData?.table) return null;
    const rng = seedrandom(id);

    return (
      <View
        style={[
          styles,
          elementData.styles,
          {
            width: "100%",
            // marginTop: 0,
            // marginBottom: 0,
            // backgroundColor: "rgba(255, 0, 0, .2)",
          },
        ]}
      >
        <View
          style={[
            { flexDirection: "row", width: "100%" },
            elementData.table.headerStyles,
          ]}
        >
          {elementData.table.columnIds.map(
            (columnId: string, index: number) => (
              <View
                key={columnId}
                style={[
                  { flex: elementData.table.columns[columnId].weight },
                  {
                    marginRight: index === 0 ? 5 : undefined,
                    marginHorizontal:
                      index !== 0 &&
                      index !== elementData.table.columnIds.length - 1
                        ? 5
                        : undefined,
                    marginLeft:
                      index === elementData.table.columnIds.length - 1
                        ? 5
                        : undefined,
                  },
                ]}
              >
                <Text
                  style={[elementData.table.columns[columnId].headerStyles]}
                >
                  {elementData.table.columns[columnId].headerLabel}
                </Text>
              </View>
            )
          )}
        </View>

        {[0, 1, 2].map((v) => (
          <View key={v} style={[{ flexDirection: "row" }]}>
            {elementData.table.columnIds.map(
              (columnId: string, index: number) => (
                <View
                  key={columnId}
                  style={[
                    elementData.table.rowStyles,
                    { flex: elementData.table.columns[columnId].weight },
                    {
                      marginRight: index === 0 ? 5 : undefined,
                      marginHorizontal:
                        index !== 0 &&
                        index !== elementData.table.columnIds.length - 1
                          ? 5
                          : undefined,
                      marginLeft:
                        index === elementData.table.columnIds.length - 1
                          ? 5
                          : undefined,
                    },
                  ]}
                >
                  <View
                    style={[
                      {
                        alignItems:
                          elementData.table.columns[columnId].bodyStyles
                            .textAlign === "left"
                            ? "flex-start"
                            : elementData.table.columns[columnId].bodyStyles
                                .textAlign === "right"
                            ? "flex-end"
                            : elementData.table.columns[columnId].bodyStyles
                                .textAlign,
                      },
                    ]}
                  >
                    <View
                      style={{
                        width: `${(rng() * 100).toFixed(0)}%`,
                        height: 16,
                        borderRadius: 8,
                        backgroundColor: "#F3F4F6",
                      }}
                    />
                  </View>
                </View>
              )
            )}
          </View>
        ))}
        <View
          style={{
            width: "100%",
            height: 54,
          }}
        />
      </View>
    );
  }

  if (type === TreeItemTypes.IMAGE) {
    if (elementData?.imageUrl?.includes("{{")) {
      return (
        <View
          key={renderCount}
          style={[
            styles,
            elementData.styles,
            {
              backgroundColor: "#F3F4F6",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <ImageIcon size={48} />
        </View>
      );
    }

    return (
      <Image
        key={renderCount}
        style={[styles, elementData.styles]}
        source={{ uri: elementData?.imageUrl ?? "" }}
      />
    );
  }

  if (type === TreeItemTypes.TEXT) {
    const fontSize = getNumberFromString(elementData?.styles?.fontSize);
    const lineHeight = getNumberFromString(elementData?.styles?.lineHeight);
    const wordsSplit = elementData?.textContent?.split(" ") ?? [];

    return (
      <View
        style={{
          ...elementData.styles,
        }}
      >
        {elementData?.textContent
          ?.split("\n")
          .map((line: string, lineIndex: number) => {
            return (
              <View
                key={line}
                style={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                {(line?.split(" ") ?? []).map((word: string, index: number) => (
                  <View
                    key={`${renderCount}-${index}`}
                    style={{
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={[styles, fixEditorTextStyles(elementData.styles)]}
                    >
                      {`${word}${index === wordsSplit.length - 1 ? "" : " "}`}
                    </Text>
                    <View
                      style={{
                        height: `${lineHeight - fontSize}px`,
                        width: 1,
                      }}
                    />
                  </View>
                ))}
              </View>
            );
          })}
      </View>
    );

    return (
      <View
        style={{
          ...elementData.styles,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {wordsSplit.map((word: string, index: number) => (
          <View
            key={`${renderCount}-${index}`}
            style={{ flexDirection: "column" }}
          >
            <Text style={[styles, fixEditorTextStyles(elementData.styles)]}>
              {`${word}${index === wordsSplit.length - 1 ? "" : " "}`}
            </Text>
            <View
              style={{
                height: `${lineHeight - fontSize}px`,
                width: 1,
              }}
            />
          </View>
        ))}
      </View>
    );

    // Depreciated old code because of line height

    return (
      <Text key={renderCount} style={[styles, elementData.styles]}>
        {elementData.textContent}
      </Text>
    );

    return (
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {elementData.textContent
          .split(" ")
          .map((word: string, index: number) => (
            <>
              <Text
                key={`${renderCount}-${index}`}
                style={[styles, fixEditorTextStyles(elementData.styles)]}
              >
                {word}
              </Text>
              <Text
                key={`${renderCount}-${index}-space`}
                style={[styles, fixEditorTextStyles(elementData.styles)]}
              >
                {` `}
              </Text>
            </>
          ))}
      </View>
    );
  }

  if (type === TreeItemTypes.BOX && id !== "*") {
    const isNewBox =
      !elementData?.styles?.height || !elementData?.styles?.width; // ||

    return (
      <View key={renderCount} style={[styles, elementData.styles]}>
        {(subelements || []).map((el) => (
          <Element
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}

        {/* {isNewBox && hasNoSubelements ? (
          <motion.button
            className="w-full flex flex-col items-center justify-center p-3"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              updateAddElementId(id);

              if (activeElementId === id) {
                updateActiveElementId(undefined);
              }
            }}
            initial={{ opacity: 0.45 }}
            animate={{ opacity: 1 }}
            transition={{
              repeat: Infinity,
              repeatType: "mirror",
              duration: 1,
            }}
          >
            <AlertTriangle className="text-red-400" size={24} />
            <span className="mt-2 text-red-400 text-center text-xs block">
              Add elements
            </span>
            <span className="text-xs block font-bold text-red-400 my-1">
              Or
            </span>
            <span className="text-red-400 text-center text-xs block">
              set height, width, & background color
            </span>
          </motion.button>
        ) : null} */}
      </View>
    );
  }

  if (type === TreeItemTypes.COLUMN) {
    return (
      <View key={renderCount} style={[styles, elementData.styles]}>
        {(subelements || []).map((el) => (
          <Element
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}
      </View>
    );
  }

  if (type === TreeItemTypes.ROW) {
    return (
      <View key={renderCount} style={[styles, elementData.styles]}>
        {(subelements || []).map((el) => (
          <Element
            key={el.id}
            id={el.id}
            type={el.type}
            subelements={el.children}
          />
        ))}
      </View>
    );
  }

  return (
    <View key={renderCount} style={[styles, elementData.styles]}>
      {subelements?.map((el) => (
        <Element
          key={el.id}
          id={el.id}
          type={el.type}
          subelements={el.children}
        />
      ))}
    </View>
  );
};

export const ElementsContainer = () => {
  const elements = useRecoilValue(elementsState);
  const pageSettings = useRecoilValue(elementPageSettingsState);

  return (
    <Element
      id="*"
      styles={[
        {
          width: "100%",
          height: "100%",
          borderColor: "rgba(0,0,0,.1)",
          borderWidth: 1,
        },
        pageSettings,
      ]}
      subelements={elements}
    />
  );
};

// export default memo(Element);
export default Element;
