import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import { useFunctions } from "reactfire";
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import { useFirestore } from "reactfire";
import { GenericTable } from "../../components/GenericTable";
import { useHandlePortal } from "../../hooks/mutations/useHandlePortal";
import { usePlansQuery } from "../../hooks/queries/usePlans";
import { useOrganizationSubscription } from "../../hooks/queries/useOrganizationSubscription";
import { useOrganization } from "../../hooks/queries/useOrganization";
import { useStartSubscription } from "../../hooks/mutations/useStartSubscription";
import { useUpdateSubscription } from "../../hooks/mutations/useUpdateSubscription";
import { useCancelSubscription } from "../../hooks/mutations/useCancelSubscription";
import { GenericButton } from "../../components/GenericButton";

const Plan = () => {
  const firestore = useFirestore();
  const functions = useFunctions();
  const [updatedPlan, setPlan] = useState<string>();
  const [isUpdatingPlan, toggleUpdatingPlan] = useToggle(false);

  const { data: organization } = useOrganization();
  const { data: subscription, refetch } = useOrganizationSubscription();
  const { data: plans = [] } = usePlansQuery();

  const { mutate: mutatePortal, isLoading } = useHandlePortal();
  const { mutateAsync: mutateAsyncStartSubscription } = useStartSubscription();
  const { mutateAsync: mutateAsyncUpdateSubscription } =
    useUpdateSubscription();
  const { mutateAsync: mutateAsyncCancelSubscription } =
    useCancelSubscription();

  const handleSubscribe = useCallback(async () => {
    if (!organization) return;

    const priceId = plans.find((plan) => plan.id === (updatedPlan as string))
      ?.price?.id;

    if (!priceId) {
      await mutateAsyncCancelSubscription();
    } else if (!subscription?.priceId) {
      const session = await mutateAsyncStartSubscription({
        priceId,
        successUrl: window.location.href,
        cancelUrl: window.location.href,
      });

      window.location.href = session.url;
    } else {
      await mutateAsyncUpdateSubscription({ priceId });
    }

    refetch();
  }, [subscription, updatedPlan]);

  useEffect(() => {
    if (subscription) {
      setPlan(subscription.plan.id);
    }
  }, [subscription]);

  const COLUMNS = useMemo(
    () => [
      {
        id: "name",
        Header: "Plan name",
        accessor: "name",
      },
      {
        id: "monthlyRequests",
        Header: "Monthly requests",
        accessor: "monthlyPagesLimit",
      },
      {
        id: "cost",
        Header: "Subscription cost",
        accessor: "price",
        Cell: ({ cell: { value } }: any): string =>
          value?.unitAmount ? `$${value.unitAmount / 100}` : "Free",
      },
      {
        id: "actions",
        accessor: (originalRow: any) => originalRow,
        Cell: ({ cell: { value } }: any) => (
          <div className="flex items-center justify-end">
            <input
              name="plan"
              type="radio"
              checked={updatedPlan === value.id}
              onChange={() => setPlan(value.id)}
            />
          </div>
        ),
      },
    ],
    [setPlan, updatedPlan]
  );

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Pricing
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Money can't buy you happiness, but it can buy you a lot of
                really cool stuff.
              </p>
            </div>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-3">
            <GenericTable columns={COLUMNS} data={plans} />
            {subscription?.plan && updatedPlan === null ? (
              <span className="text-red-500 text-sm font-light">
                To downgrade your plan to "Sandbox", please cancel your
                subscription.
              </span>
            ) : null}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            {subscription?.endOfPeriodCancel && (
              <span className="text-sm text-red-500">{`Your subscription ${
                dayjs(subscription?.stripeCancelledAt).isAfter(dayjs())
                  ? "is set to end"
                  : "ended"
              } on ${dayjs(subscription?.stripeCancelledAt).format(
                "MMM DD"
              )}.`}</span>
            )}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-1 text-right">
            <GenericButton
              onClick={handleSubscribe}
              isLoading={isUpdatingPlan}
              disabled={
                !organization ||
                updatedPlan === subscription?.plan.id ||
                (subscription && updatedPlan === null) ||
                subscription?.endOfPeriodCancel
              }
            >
              Change plan
            </GenericButton>
          </div>
        </div>
        {subscription && (
          <>
            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-8">
                <div className="border-t border-gray-200"></div>
              </div>
            </div>

            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-2">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Manage your billing
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Need to change your payment, billing, or other information?
                    You can even cancel your subscription. Click below to
                    connect to our payment partner.
                  </p>

                  <div className="mt-6">
                    <GenericButton
                      isLoading={isLoading}
                      onClick={() => mutatePortal()}
                    >
                      Update Plan
                    </GenericButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Plan;
