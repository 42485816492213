import { useQuery } from "react-query";
import { useUser } from "reactfire";
import { sortBy } from "lodash";

import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { ApiRequest } from "../../models/api.model";
import dayjs from "dayjs";
import { useIdToken } from "./useIdToken";

const getDebug = async (
  token: string,
  organizationId: string | undefined,
  limitNumber: number
): Promise<ApiRequest[]> => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/api-requests?limit=${limitNumber}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.apiRequests;
};

export const useLatestDebug = (limit = 25) => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useQuery(
    ["debug", organizationId, limit],
    () => {
      return getDebug(token as string, organizationId, limit);
    },
    {
      enabled: !!organizationId && !!token,
      select: (data) =>
        sortBy(data, (entry) => -dayjs(entry.requestedAt).unix()),
    }
  );
};
