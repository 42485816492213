import { useForm } from "react-hook-form"
import { useCreateOrganization } from "../../hooks/mutations/useCreateOrganization";
import { Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'
import { Plus } from "react-feather"

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const CreateOrganizationModal = ({ isOpen, closeModal }: ModalProps) => {
  const { mutate: mutateCreateOrganization } = useCreateOrganization();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const nameStatus = watch("name");

  // const [newOrganizationName, setNewOrganizationName] = useState("");

  const onSubmit = (values: { name: string }) => {
    mutateCreateOrganization({
      organizationName: values.name,
    });
    reset();
    closeModal();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-90"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:max-w-sm sm:align-middle p-6 my-8 transition-all transform bg-white shadow-xl rounded-md">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <Plus className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Create an organization
                  </Dialog.Title>
                  <div className="mt-2 text-sm space-y-2">
                    <p className="font-light text-gray-500">Organizations help you seperate projects, billing, and users. At least one is required.</p>
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="font-light mt-4"
                >
                  <div className="flex flex-col">
                    <div>
                      <label className="block font-medium text-gray-700 text-sm">
                        Organization name
                      </label>
                      <input
                        type="text"
                        className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md text-center"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Organization name is required.",
                          },
                        })}
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={!nameStatus.length || isSubmitting}
                      className="mt-4 inline-flex justify-center items-center space-x-1 w-full rounded-md text-sm px-4 py-2 bg-red-500 font-medium text-white hover:bg-red-400 focus:outline-none"
                    >
                      Create organization
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CreateOrganizationModal;
