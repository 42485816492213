import { NavLink } from "react-router-dom";

interface SublinkProps {
  path: string;
  label: string;
}

const Sublink = ({ path, label }: SublinkProps) => {
  return (
    <div className="my-2 pl-5">
      <NavLink
        to={path}
        end={true}
        className={({ isActive }) => (isActive ? "text-red-500" : "")}
      >
        <div className="flex items-center">
          <span className="text-sm">{label}</span>
        </div>
      </NavLink>
    </div>
  );
};

export default Sublink;
