import { useMemo, useState } from "react";
import { Column } from "react-table";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";

import { Timestamp } from "firebase/firestore";

import { ReactComponent as EditSquare } from "../../assets/icons/outlined/Edit-Square.svg";
import { ReactComponent as Duplicate } from "../../assets/icons/outlined/Duplicate.svg";
import { ReactComponent as Delete } from "../../assets/icons/outlined/Delete.svg";

import { GenericTable } from "../../components/GenericTable";
import RenameTemplateModal from "./RenameTemplateModal";
import { useTemplates } from "../../hooks/queries/useTemplates";
import { useRenameTemplate } from "../../hooks/mutations/useRenameTemplate";
import { useDeleteTemplate } from "../../hooks/mutations/useDeleteTemplate";
import { useCopyTemplate } from "../../hooks/mutations/useCopyTemplate";
import Fuse from "fuse.js";
import { useToggle } from "react-use";
import PrebuiltModal from "./PrebuiltModal";
import { CopyButton } from "./CopyButton";
import { SearchInput } from "../../components/SearchInput";
import { GenericButton } from "../../components/GenericButton";

interface ColumnModel {
  name: string;
  id: string;
  createdAt: Timestamp;
  lastUpdated: Timestamp;
}

const Templates = () => {
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const [activeRename, setActiveRename] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const { mutate } = useRenameTemplate();
  const { mutate: mutateDeleteTemplate } = useDeleteTemplate();
  const { mutate: mutateCopyTemplate } = useCopyTemplate();

  const COLUMNS: Array<Column<ColumnModel>> = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: (originalRow: any) => originalRow,
        Cell: ({ cell: { value } }: any) => {
          return (
            <button
              onClick={() => navigate(`/editor/${value.id}`)}
              className="font-medium text-gray-800 hover:underline"
            >
              {value.name}
            </button>
          );
        },
      },
      {
        id: "templateId",
        Header: "Template ID",
        accessor: (originalRow: any) => originalRow,
        Cell: ({ cell: { value } }: any) => (
          <div className="flex items-center">
            <button
              onClick={() => navigate(`/editor/${value.id}`)}
              className="font-medium text-gray-800 hover:underline"
            >
              {value.id}
            </button>
            <CopyButton value={value.id} />
          </div>
        ),
      },
      {
        id: "createdAt",
        Header: "Created on",
        accessor: (originalRow: any) =>
          dayjs(originalRow.createdAt).format("LL"),
      },
      {
        id: "lastUpdated",
        Header: "Last updated",
        accessor: (originalRow: any) =>
          dayjs(originalRow.lastUpdated).format("lll"),
      },
      {
        id: "actions",
        accessor: (originalRow: any) => originalRow,
        Cell: ({ cell: { value } }: any) => (
          <>
            <div className="flex items-center justify-end">
              <EditSquare
                className="hover:text-gray-900 cursor-pointer mr-4"
                height={20}
                width={20}
                onClick={() => setActiveRename(value)}
                data-tip="Rename"
                data-for="rename"
              />
              <Duplicate
                className="hover:text-gray-900 cursor-pointer mr-4"
                height={20}
                width={20}
                onClick={() => mutateCopyTemplate(value)}
                data-tip="Duplicate"
                data-for="duplicate"
              />
              <Delete
                className="hover:text-gray-900 cursor-pointer mr-4"
                height={20}
                width={20}
                onClick={() => mutateDeleteTemplate(value.id)}
                data-tip="Delete"
                data-for="delete"
              />
            </div>
            <ReactTooltip
              id="edit"
              effect="solid"
              place="top"
              type="dark"
              backgroundColor="rgba(21, 21, 21, .7)"
              className="font-light"
            />
            <ReactTooltip
              id="rename"
              effect="solid"
              place="top"
              type="dark"
              backgroundColor="rgba(21, 21, 21, .7)"
              className="font-light"
            />
            <ReactTooltip
              id="duplicate"
              effect="solid"
              place="top"
              type="dark"
              backgroundColor="rgba(21, 21, 21, .7)"
              className="font-light"
            />
            <ReactTooltip
              id="delete"
              effect="solid"
              place="top"
              type="dark"
              backgroundColor="rgba(21, 21, 21, .7)"
              className="font-light"
            />
          </>
        ),
      },
    ],
    [navigate]
  );

  const { data: templates = [] } = useTemplates();

  const fuse = useMemo(
    () =>
      new Fuse(templates as any[], {
        keys: ["name"],
        threshold: 0.2,
      }),
    [templates]
  );

  const filteredData = useMemo(() => {
    return searchValue.length
      ? fuse.search(searchValue).map((entry) => entry.item)
      : templates;
  }, [fuse, templates, searchValue]) as any[];

  return (
    <>
      <div className="flex flex-row items-center pb-1.5 min-w-max mb-6">
        <div className="flex-1">
          <h3 className="font-normal">{`Your template library (${templates.length})`}</h3>
        </div>
        <div className="mr-4">
          <GenericButton onClick={openModal}>
            Create new template
          </GenericButton>
        </div>
        <SearchInput onChangeText={setSearchValue} />
      </div>

      <GenericTable
        columns={COLUMNS}
        data={filteredData}
        // headerText={`Your template library (${templates.length})`}
        pSize={10}
        // setSearchValue={setSearchValue}
      />

      <RenameTemplateModal
        isVisible={!!activeRename}
        setActiveRename={setActiveRename}
        activeRename={activeRename}
        handleUpdate={mutate}
      />
      <PrebuiltModal
        closeModal={closeModal}
        isOpen={isOpen}
      />
    </>
  );
};

export default Templates;
