import { useMutation } from "react-query";
import { useIdToken } from "../queries/useIdToken";
import { useProfile } from "../queries/useProfile";
import { Profile } from "../../models/api.model";

const deleteAccount = async (token: string, profile: Profile | undefined) => {
  if (!profile) {
    throw Error("No found profile.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users/${profile.id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.success;
};

export const useDeleteAccount = () => {
  const { data: token } = useIdToken();
  const { data: profile } = useProfile();

  return useMutation(() => deleteAccount(token as string, profile), {
    onSuccess: () => window.location.reload(),
  });
};
