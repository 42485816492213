import { FileText, X } from "react-feather";
import { useLocalStorage } from "react-use";

const Banner = () => {
  const [showBanner, setShowBanner] = useLocalStorage("show-banner", true);

  if (!showBanner) return null;

  return (
    <div className="w-full mx-auto mb-10">
      <div className="p-2 rounded-lg bg-red-500 opacity-90 sm:p-3">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span>👋</span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:hidden">Need help getting started?</span>
              <span className="hidden md:inline">
                Need help getting started? Check out the documentation.
              </span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="https://docs.exportsdk.com/"
              target="_blank"
              className="flex items-center justify-center space-x-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-50"
            >
              <FileText
                width={20}
                height={20}
                className="text-red-500 mr-2"
                aria-hidden="true"
              />
              View Docs
            </a>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            <button
              type="button"
              onClick={() => setShowBanner(false)}
              className="-mr-1 flex p-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span className="sr-only">Dismiss</span>
              <X className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
