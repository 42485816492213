import { Info } from "react-feather";

import { useRecoilState, useRecoilValue } from "recoil";
import { useState } from "react";
import { set } from "monolite";

import InputNumber from "../../../components/InputNumber";
import TBLRGrid from "../../../components/TBLRGrid";
import EditorColorPicker from "../../../components/EditorColorPicker";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";

const TableStyle = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  return (
    <EditorAccordion label="Table row style">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Background color</span>
          <EditorInfo helpLabel="Background Color" />
        </div>

        <EditorColorPicker
          value={
            elementData?.table?.rowStyles?.backgroundColor
              ? elementData?.table?.rowStyles?.backgroundColor.replace("#", "")
              : ""
          }
          onChange={(value) =>
            updateElementData(
              set(
                elementData,
                ["table", "rowStyles", "backgroundColor"],
                value !== "transparent" ? `#${value}` : value
              )
            )
          }
        />

        <div className="flex items-start mt-4">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              checked={elementData?.table?.rowStyles?.isAlternateBackground}
              onChange={(evt) =>
                updateElementData(
                  set(
                    elementData,
                    ["table", "rowStyles", "isAlternateBackground"],
                    evt.target.checked
                  )
                )
              }
            />
          </div>
          <div className="ml-3 text-sm">
            <label className="font-medium text-gray-700">Alternate rows</label>
          </div>
        </div>

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Padding</span>
          <EditorInfo helpLabel="Padding" />
        </div>

        <TBLRGrid
          topValue={elementData.table.rowStyles.paddingTop}
          leftValue={elementData.table.rowStyles.paddingLeft}
          rightValue={elementData.table.rowStyles.paddingRight}
          bottomValue={elementData.table.rowStyles.paddingBottom}
          handleTop={(value) =>
            updateElementData((elementData: any) =>
              set(elementData, ["table", "rowStyles", "paddingTop"], value)
            )
          }
          handleLeft={(value) =>
            updateElementData((elementData: any) =>
              set(elementData, ["table", "rowStyles", "paddingLeft"], value)
            )
          }
          handleRight={(value) =>
            updateElementData((elementData: any) =>
              set(elementData, ["table", "rowStyles", "paddingRight"], value)
            )
          }
          handleBottom={(value) =>
            updateElementData((elementData: any) =>
              set(elementData, ["table", "rowStyles", "paddingBottom"], value)
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Border bottom</span>
          <EditorInfo helpLabel="Border Bottom" />
        </div>

        <EditorColorPicker
          value={
            elementData?.table?.rowStyles?.borderColor
              ? elementData?.table?.rowStyles?.borderColor.replace("#", "")
              : ""
          }
          onChange={(value) =>
            updateElementData(
              set(
                elementData,
                ["table", "rowStyles", "borderColor"],
                value !== "transparent" ? `#${value}` : value
              )
            )
          }
        />

        <div className="flex items-center mt-4">
          <InputNumber
            value={elementData?.table?.rowStyles?.borderBottomWidth ?? ""}
            onChange={(value) =>
              updateElementData(
                set(
                  elementData,
                  ["table", "rowStyles", "borderBottomWidth"],
                  Number(value)
                )
              )
            }
          />
          <div className="ml-3 text-sm">
            <label className="font-medium text-gray-700">
              Row border width
            </label>
          </div>
        </div>
      </div>
    </EditorAccordion>
  );
};

export default TableStyle;
