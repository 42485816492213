import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { useSignup } from "../../hooks/mutations/useSignup";
import { useAddScriptToHead } from "../../hooks/custom/useAnalytics";

const Signup = () => {
  const { mutate, error } = useSignup();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useAddScriptToHead();

  const handleSignup = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ firstName, email, password });
  };

  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 h-screen">
      <div className="w-full px-4 py-20 mx-auto bg-white xl:py-32 md:w-3/5 lg:w-4/5 xl:w-3/5">
        <h1 className="mb-4 -mt-3 text-2xl font-bold leading-snug tracking-normal text-left text-gray-700 md:text-4xl">
          Get started with Export SDK for free
        </h1>
        <form className="mt-8 space-y-8" onSubmit={handleSignup}>
          <label className="block">
            <span className="block mb-2 text-xs font-medium text-gray-700">
              Your first name
            </span>
            <input
              className="py-2 px-4 focus:ring-red-200 focus:border-red-200 w-full border-gray-300 rounded-md"
              type="text"
              placeholder="Ex. James"
              inputMode="text"
              required
              value={firstName}
              onChange={(evt) => setFirstName(evt.target.value)}
            />
          </label>
          <label className="block">
            <span className="block mb-2 text-xs font-medium text-gray-700">
              Your Email
            </span>
            <input
              className="py-2 px-4 focus:ring-red-200 focus:border-red-200 w-full border-gray-300 rounded-md"
              type="email"
              placeholder="Ex. james@bond.com"
              inputMode="email"
              required
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </label>
          <label className="block">
            <span className="block mb-2 text-xs font-medium text-gray-700">
              Your Password
            </span>
            <input
              className="py-2 px-4 focus:ring-red-200 focus:border-red-200 w-full border-gray-300 rounded-md"
              type="password"
              placeholder="••••••••"
              required
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </label>
          <input
            type="submit"
            className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            value="Register"
          />
        </form>
        <div className="mt-2 text-red-600 text-center">
          {(error as Error)?.message}
        </div>
        <div className="pt-6 mt-6 text-sm font-medium text-gray-700 border-t border-gray-200">
          Already have an account?
          <Link to="/" className="text-blue-500 hover:text-blue-600 ml-2">
            Sign in
          </Link>
        </div>
      </div>
      <div className="px-4 py-20 flex flex-col justify-center space-y-4 bg-gray-100 xl:py-32 md:px-40 lg:px-20 xl:px-40">
        <div className="flex space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="flex-none w-6 h-6 mt-1 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <div>
            <h2 className="text-xl font-medium text-red-500">
              Simple drag and drop editor
            </h2>
          </div>
        </div>
        <div className="flex space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="flex-none w-6 h-6 mt-1 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <div>
            <h2 className="text-xl font-medium text-red-500">
              Integrate with code or without
            </h2>
          </div>
        </div>
        <div className="flex space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="flex-none w-6 h-6 mt-1 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <div>
            <h2 className="text-xl font-medium text-red-500">
              Add unlimited users
            </h2>
          </div>
        </div>
        <div className="flex space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="flex-none w-6 h-6 mt-1 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <div>
            <h2 className="text-xl font-medium text-red-500">
              Get 250 pages / month free
            </h2>
          </div>
        </div>
        <div className="flex space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="flex-none w-6 h-6 mt-1 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <div>
            <h2 className="text-xl font-medium text-red-500">
              Upgrade or downgrade any time
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
