import blankWhite from "./blank-white.json";
import blankBlack from "./blank-black.json";
import understandingFlexbox from "./understanding-flexbox.json";
import understandingSizeSpace from "./understanding-size-space.json";
import understandingColor from "./understanding-color.json";
import createTable from "./create-table.json";
import businessInvoice from "./business-invoice.json";
import projectInvoice from "./project-invoice.json";
import shippingInvoice from "./shipping-invoice.json";
import businessQuote from "./business-quote.json";
import personalInvoice from "./personal-invoice.json";
import projectQuote from "./project-quote.json";
import profitLossStatement from "./profit-loss-statement.json";
import purchaseOrder from "./purchase-order.json";
import receipt from "./receipt.json";
import salesQuote from "./sales-quote.json";
import techInvoice from "./tech-invoice.json";
import eventTicket from "./event-ticket.json";
import proposalCover from "./proposal-cover.json";

export const templates = [
    {
        name: "Blank White",
        img: "/prebuilt/blank-white.png",
        category: "Starter",
        json: blankWhite
    },
    {
        name: "Blank Black",
        img: "/prebuilt/blank-black.png",
        category: "Starter",
        json: blankBlack
    },
    {
        name: "Business Invoice",
        img: "/prebuilt/business-invoice.png",
        category: "Invoices",
        json: businessInvoice
    },
    {
        name: "Shipping Invoice",
        img: "/prebuilt/project-invoice.png",
        category: "Invoices",
        json: shippingInvoice
    },
    {
        name: "Personal Invoice",
        img: "/prebuilt/personal-invoice.png",
        category: "Invoices",
        json: personalInvoice
    },
    {
        name: "Project Invoice",
        img: "/prebuilt/project-invoice.png",
        category: "Invoices",
        json: projectInvoice
    },
    {
        name: "Tech Invoice",
        img: "/prebuilt/tech-invoice.png",
        category: "Invoices",
        json: techInvoice
    },
    {
        name: "Bussines Quote",
        img: "/prebuilt/business-quote.png",
        category: "Quotes",
        json: businessQuote
    },
    {
        name: "Project Quote",
        img: "/prebuilt/project-quote.png",
        category: "Quotes",
        json: projectQuote
    },
    {
        name: "Sales Quote",
        img: "/prebuilt/sales-quote.png",
        category: "Quotes",
        json: salesQuote
    },
    {
        name: "Receipt",
        img: "/prebuilt/receipt.png",
        category: "Transactions",
        json: receipt
    },
    {
        name: "P&L statement",
        img: "/prebuilt/profit-loss-1.png",
        category: "Business & Legal",
        json: profitLossStatement
    },
    {
        name: "Purchase Order",
        img: "/prebuilt/purchase-order.png",
        category: "Purchasing",
        json: purchaseOrder
    },
    {
        name: "E-Ticket",
        img: "/prebuilt/event-ticket.png",
        category: "Events",
        json: eventTicket
    },
    {
        name: "Proposal Cover",
        img: "/prebuilt/proposal-cover.png",
        category: "Proposals",
        json: proposalCover
    },
]

export const tutorials = [
    {
        name: "Understanding flexbox",
        img: "/prebuilt/understanding-flexbox.png",
        category: "Learning Export SDK",
        json: understandingFlexbox
    },
    {
        name: "Understanding flexbox",
        img: "/prebuilt/understanding-flexbox.png",
        category: "Learning Export SDK",
        json: understandingFlexbox
    },
    {
        name: "Understanding size & space",
        img: "/prebuilt/understanding-size-space.png",
        category: "Learning Export SDK",
        json: understandingSizeSpace
    },
    {
        name: "Understanding color",
        img: "/prebuilt/understanding-color.png",
        category: "Learning Export SDK",
        json: understandingColor
    },
    {
        name: "Creating a table",
        img: "/prebuilt/blank-white.png",
        category: "Learning Export SDK",
        json: createTable
    },
]