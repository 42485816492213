import { useMutation, useQueryClient } from "react-query";
import { useFirestore, useUser } from "reactfire";
import dayjs from "dayjs";
import { useProfile } from "../queries/useProfile";
import { addDoc, collection, Firestore } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Template } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";
import toast from "react-hot-toast";

const createTemplate = async (
  token: string,
  organizationId: string | undefined,
  name: string,
  pages: any
) => {
  if (!organizationId) {
    throw Error("No organization active.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/templates`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        pages,
      }),
    }
  );

  const json = await response.json();
  return json.template as Template;
};

export const useCreateTemplate = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, pages }: any) =>
      createTemplate(token as string, organizationId, name, pages),
    {
      onSuccess: (data) => {
        toast.success("Successfully created template!");
        queryClient.invalidateQueries("template");
        queryClient.invalidateQueries("templates");
      },
      onError: () => {
        toast.error("Something bad happened!");
      },
    }
  );
};
