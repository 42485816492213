import {
  Info,
  Settings,
  XCircle,
  CheckCircle,
  Plus,
  Edit,
  Copy,
  Trash,
} from "react-feather";

import { useRecoilState, useRecoilValue } from "recoil";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useMemo } from "react";
import { useToggle } from "react-use";
import { set } from "monolite";
import { v4 } from "uuid";

import InputNumber from "../../../components/InputNumber";
import EditorColorPicker from "../../../components/EditorColorPicker";

import { activeElementIdState, elementDataState } from "../editor.atom";
import { generateColumnDefaults } from "../../../utils/editor.util";
import fontList from "../../../assets/font-list.json";
import { capitalize, omit, xor } from "lodash";

enum ColumnStyleTypes {
  HEADER = "headerStyles",
  BODY = "bodyStyles",
}

const TableConfig = () => {
  const [isOpen, toggleOpen] = useToggle(false);

  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  const [newColumn, setNewColumn] = useState<string>();
  const [activeColumnId, setActiveColumnId] = useState<string>();
  const [activeColumnStyle, setActiveColumnStyle] = useState<ColumnStyleTypes>(
    ColumnStyleTypes.HEADER
  );

  const activeFontFamily = useMemo(
    () =>
      fontList
        .find(
          (font) =>
            font.family ===
            (activeColumnId &&
              elementData?.table?.columns?.[activeColumnId]?.[activeColumnStyle]
                ?.fontFamily)
        )
        ?.fonts?.filter((individual) => individual.fontStyle === "normal")
        ?.map((individual) => individual.fontWeight),
    [
      activeColumnId &&
        elementData?.table?.columns?.[activeColumnId]?.[activeColumnStyle]
          ?.fontFamily,
    ]
  );

  const handleCreateColumn = () => {
    setNewColumn(undefined);

    setTimeout(() => {
      const newColumnId = v4();
      let update = set(
        elementData,
        ["table", "columnIds"],
        [...elementData.table.columnIds, newColumnId]
      );
      update = set(update, ["table", "columns"], {
        ...elementData.table.columns,
        [newColumnId]: {
          ...generateColumnDefaults(),
          accessor: newColumn,
          headerLabel: capitalize(newColumn),
        },
      });

      updateElementData(update);
      setActiveColumnId(newColumnId);
    }, 0);
  };

  const handleCopyColumn = (copyId: string) => {
    setNewColumn(undefined);

    setTimeout(() => {
      const newColumnId = v4();
      let update = set(
        elementData,
        ["table", "columnIds"],
        [...elementData.table.columnIds, newColumnId]
      );
      update = set(update, ["table", "columns"], {
        ...elementData.table.columns,
        [newColumnId]: {
          ...update.table.columns[copyId],
          accessor: "",
          headerLabel: "",
        },
      });

      updateElementData(update);
      setActiveColumnId(newColumnId);
    }, 0);
  };

  const handleRemoveColumn = (columnId: string) => {
    setNewColumn(undefined);

    setTimeout(() => {
      const newColumnId = v4();
      let update = set(
        elementData,
        ["table", "columnIds"],
        xor(elementData.table.columnIds, [columnId])
      );
      update = set(
        update,
        ["table", "columns"],
        omit(elementData.table.columns, columnId)
      );

      updateElementData(update);
    }, 0);
  };

  return (
    <div>
      <div className="flex items-center justify-between bg-gray-50 py-2 px-4 border-b-2 border-gray-100">
        <span className="uppercase text-xs font-bold text-gray-400 tracking-widest">
          Table configuration
        </span>
        <button className="focus:outline-none" onClick={toggleOpen}>
          <Settings className="text-gray-500" size={20} />
        </button>
      </div>

      <AnimatePresence>
        {isOpen ? (
          <motion.div
            initial={"closed"}
            animate={isOpen ? "open" : "closed"}
            exit={"closed"}
            variants={{
              open: {
                opacity: 1,
              },
              closed: {
                opacity: 0,
              },
            }}
            drag
            dragMomentum={false}
            className="fixed right-80 top-20 bg-white w-96 z-10 shadow-lg rounded-md"
          >
            <div className="flex items-center justify-between p-4 bg-gray-50">
              <span>Table configuration</span>
              <button className="focus:outline-none" onClick={toggleOpen}>
                <XCircle className="text-gray-400" size={24} />
              </button>
            </div>

            <div className="overflow-y-auto max-h-640px">
              <div className="mt-4 mb-4 px-4">
                <label className="block text-xs text-gray-400 mb-2">
                  Data accessor
                </label>
                <input
                  placeholder="Ex. tableValues"
                  className="border border-gray-100 rounded-md p-2"
                  value={elementData.table.accessor ?? ""}
                  onChange={(evt) =>
                    updateElementData(
                      set(elementData, ["table", "accessor"], evt.target.value)
                    )
                  }
                />
              </div>

              <div
                className={`py-4 px-4 ${
                  activeColumnId ? "bg-gray-100" : "bg-white"
                } border-gray-300 border-t ${activeColumnId ? "border-b" : ""}`}
              >
                <div className="flex items-center justify-between mb-4">
                  <span className="uppercase font-medium text-gray-500 text-sm tracking-wider">
                    Columns
                  </span>

                  <button
                    className="flex items-center"
                    onClick={() => {
                      setNewColumn("");
                      setActiveColumnId(undefined);
                    }}
                  >
                    <Plus className="text-red-400" size={16} />
                    <span className="text-red-400 ml-1 text-sm">
                      Add column
                    </span>
                  </button>
                </div>

                {elementData.table.columnIds.map(
                  (columnId: string, index: number) => (
                    <div
                      key={columnId}
                      className={`flex items-center mb-1 p-2`}
                    >
                      <span className="mr-auto text-gray-500 font-light">
                        {`${index + 1}. ${
                          elementData.table.columns?.[columnId]?.accessor
                        }`}
                      </span>
                      <button
                        className="ml-3"
                        onClick={() =>
                          setActiveColumnId(
                            columnId === activeColumnId ? undefined : columnId
                          )
                        }
                      >
                        <Edit className="text-gray-400" size={16} />
                      </button>
                      <button
                        className="ml-3"
                        onClick={() => handleCopyColumn(columnId)}
                      >
                        <Copy className="text-gray-400" size={16} />
                      </button>
                      <button
                        className="ml-3"
                        onClick={() => handleRemoveColumn(columnId)}
                      >
                        <Trash className="text-gray-400" size={16} />
                      </button>
                    </div>
                  )
                )}

                {newColumn !== undefined ? (
                  <div className="flex items-center border border-gray-100 rounded-md p-2 bg-white">
                    <span className="mr-1 text-gray-500 font-light">{`${
                      elementData.table.columnIds.length + 1
                    }. `}</span>
                    <input
                      className="font-light flex-1 focus:outline-none"
                      placeholder="Enter field name"
                      value={newColumn}
                      onChange={(evt) => setNewColumn(evt.target.value)}
                      onKeyDown={(evt) =>
                        evt.key === "Enter" ? handleCreateColumn() : null
                      }
                      autoFocus
                    />
                    <button
                      className="ml-3"
                      onClick={() => setNewColumn(undefined)}
                    >
                      <XCircle className="text-red-500" size={16} />
                    </button>
                    <button className="ml-3" onClick={handleCreateColumn}>
                      <CheckCircle className="text-green-500" size={16} />
                    </button>
                  </div>
                ) : null}
              </div>

              {activeColumnId ? (
                <div className="py-4 px-4">
                  <div className="flex items-center justify-between">
                    <span className="uppercase font-medium text-gray-500 text-sm tracking-wider">
                      Editing column
                    </span>
                    <button
                      className="flex items-center"
                      onClick={() => setActiveColumnId(undefined)}
                    >
                      <CheckCircle className="text-green-500" size={16} />
                      <span className="ml-2 text-green-500">Done</span>
                    </button>
                  </div>

                  <div className="mt-6 grid grid-cols-8 gap-2">
                    <div className="col-span-3">
                      <label className="block text-xs text-gray-400 mb-2">
                        Accessor
                      </label>
                      <input
                        className="border p-2 border-gray-100 w-full rounded-md text-sm"
                        placeholder="Ex. name"
                        value={
                          elementData.table.columns[activeColumnId].accessor
                        }
                        onChange={(evt) =>
                          updateElementData(
                            set(
                              elementData,
                              ["table", "columns", activeColumnId, "accessor"],
                              evt.target.value
                            )
                          )
                        }
                      />
                    </div>
                    <div className="col-span-3">
                      <label className="block text-xs text-gray-400 mb-2">
                        Header label
                      </label>
                      <input
                        className="border p-2 border-gray-100 w-full rounded-md text-sm"
                        placeholder="Ex. name"
                        value={
                          elementData.table.columns[activeColumnId].headerLabel
                        }
                        onChange={(evt) =>
                          updateElementData(
                            set(
                              elementData,
                              [
                                "table",
                                "columns",
                                activeColumnId,
                                "headerLabel",
                              ],
                              evt.target.value
                            )
                          )
                        }
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block text-xs text-gray-400 mb-2">
                        Weight
                      </label>
                      <InputNumber
                        value={elementData.table.columns[activeColumnId].weight}
                        onChange={(value) =>
                          updateElementData(
                            set(
                              elementData,
                              ["table", "columns", activeColumnId, "weight"],
                              value
                            )
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="mt-10">
                    <div className="flex mb-4">
                      <span className="uppercase font-medium text-gray-500 text-sm tracking-wider mr-8">
                        Style
                      </span>

                      <div className="rounded-md w-full bg-gray-200 p-1 flex items-center">
                        <button
                          className={`flex-1 rounded-md py-1 ${
                            activeColumnStyle === ColumnStyleTypes.HEADER
                              ? "bg-red-400"
                              : ""
                          }`}
                          onClick={() =>
                            setActiveColumnStyle(ColumnStyleTypes.HEADER)
                          }
                        >
                          <span className="text-white text-sm font-medium">
                            Header
                          </span>
                        </button>
                        <button
                          className={`flex-1 rounded-md py-1 ${
                            activeColumnStyle === ColumnStyleTypes.BODY
                              ? "bg-red-400"
                              : ""
                          }`}
                          onClick={() =>
                            setActiveColumnStyle(ColumnStyleTypes.BODY)
                          }
                        >
                          <span className="text-white text-sm font-medium">
                            Body
                          </span>
                        </button>
                      </div>
                    </div>

                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-400 font-light">
                        Font family
                      </span>
                      <select
                        value={
                          elementData.table.columns[activeColumnId][
                            activeColumnStyle
                          ].fontFamily
                        }
                        onChange={(evt) =>
                          updateElementData(
                            set(
                              elementData,
                              [
                                "table",
                                "columns",
                                activeColumnId,
                                activeColumnStyle,
                                "fontFamily",
                              ],
                              evt.target.value
                            )
                          )
                        }
                        className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
                      >
                        <option value="Abril Fatface">Abril Fatface</option>
                        <option value="Alegreya">Alegreya</option>
                        <option value="Archivo">Archivo</option>
                        <option value="Arvo">Arvo</option>
                        <option value="B612">B612</option>
                        <option value="BioRhyme">BioRhyme</option>
                        <option value="Cairo">Cairo</option>
                        <option value="Cardo">Cardo</option>
                        <option value="Concert One">Concert One</option>
                        <option value="Cormorant">Cormorant</option>
                        <option value="Crimson Text">Crimson Text</option>
                        <option value="Exo 2">Exo 2</option>
                        <option value="Fira Sans">Fira Sans</option>
                        <option value="Fjalla One">Fjalla One</option>
                        <option value="Frank Ruhl Libre">
                          Frank Ruhl Libre
                        </option>
                        <option value="IBM Plex Serif">IBM Plex Serif</option>
                        <option value="Karla">Karla</option>
                        <option value="Lato">Lato</option>
                        <option value="Lora">Lora</option>
                        <option value="Merriweather">Merriweather</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Noto Sans">Noto Sans</option>
                        <option value="Old Standard TT">Old Standard TT</option>
                        <option value="Open Sans">Open Sans</option>
                        <option value="Oswald">Oswald</option>
                        <option value="Oxygen">Oxygen</option>
                        <option value="Playfair Display">
                          Playfair Display
                        </option>
                        <option value="Poppins">Poppins</option>
                        <option value="PT Sans">PT Sans</option>
                        <option value="PT Serif">PT Serif</option>
                        <option value="Rakkas">Rakkas</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Rubik">Rubik</option>
                        <option value="Source Sans Pro">Source Sans Pro</option>
                        <option value="Spectral">Spectral</option>
                        <option value="Titillium Web">Titillium Web</option>
                        <option value="Ubuntu">Ubuntu</option>
                        <option value="Varela">Varela</option>
                        <option value="Vollkorn">Vollkorn</option>
                        <option value="Work Sans">Work Sans</option>
                        <option value="Yatra One">Yatra One</option>
                      </select>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-400 font-light">
                        Weight
                      </span>
                      <select
                        value={
                          elementData.table.columns[activeColumnId][
                            activeColumnStyle
                          ].fontWeight
                        }
                        onChange={(evt) =>
                          updateElementData(
                            set(
                              elementData,
                              [
                                "table",
                                "columns",
                                activeColumnId,
                                activeColumnStyle,
                                "fontWeight",
                              ],
                              evt.target.value
                            )
                          )
                        }
                        className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
                      >
                        {activeFontFamily?.includes(100) ? (
                          <option value={100}>Thin</option>
                        ) : null}
                        {activeFontFamily?.includes(200) ? (
                          <option value={200}>Ultra light</option>
                        ) : null}
                        {activeFontFamily?.includes(300) ? (
                          <option value={300}>Light</option>
                        ) : null}
                        {activeFontFamily?.includes(400) ? (
                          <option value={400}>Regular</option>
                        ) : null}
                        {activeFontFamily?.includes(500) ? (
                          <option value={500}>Medium</option>
                        ) : null}
                        {activeFontFamily?.includes(600) ? (
                          <option value={600}>Semibold</option>
                        ) : null}
                        {activeFontFamily?.includes(700) ? (
                          <option value={700}>Bold</option>
                        ) : null}
                      </select>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-400 font-light">
                        Size
                      </span>
                      <select
                        value={
                          elementData.table.columns[activeColumnId][
                            activeColumnStyle
                          ].fontSize
                        }
                        onChange={(evt) =>
                          updateElementData(
                            set(
                              elementData,
                              [
                                "table",
                                "columns",
                                activeColumnId,
                                activeColumnStyle,
                                "fontSize",
                              ],
                              Number(evt.target.value)
                            )
                          )
                        }
                        className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
                      >
                        <option value={8}>8pt</option>
                        <option value={10}>10pt</option>
                        <option value={12}>12pt</option>
                        <option value={14}>14pt</option>
                        <option value={16}>16pt</option>
                        <option value={18}>18pt</option>
                      </select>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-400 font-light">
                        Line Height
                      </span>
                      <select
                        value={
                          elementData.table.columns[activeColumnId][
                            activeColumnStyle
                          ].lineHeight
                            ? elementData.table.columns[activeColumnId][
                                activeColumnStyle
                              ].lineHeight.replace("px", "")
                            : ""
                        }
                        onChange={(evt) =>
                          updateElementData(
                            set(
                              elementData,
                              [
                                "table",
                                "columns",
                                activeColumnId,
                                activeColumnStyle,
                                "lineHeight",
                              ],
                              `${evt.target.value}px`
                            )
                          )
                        }
                        className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
                      >
                        <option value={8}>8pt</option>
                        <option value={10}>10pt</option>
                        <option value={12}>12pt</option>
                        <option value={14}>14pt</option>
                        <option value={16}>16pt</option>
                        <option value={18}>18pt</option>
                        <option value={20}>20pt</option>
                        <option value={22}>22pt</option>
                        <option value={24}>24pt</option>
                      </select>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-400 font-light">
                        Alignment
                      </span>
                      <select
                        value={
                          elementData.table.columns[activeColumnId][
                            activeColumnStyle
                          ].textAlign
                        }
                        onChange={(evt) =>
                          updateElementData(
                            set(
                              elementData,
                              [
                                "table",
                                "columns",
                                activeColumnId,
                                activeColumnStyle,
                                "textAlign",
                              ],
                              evt.target.value
                            )
                          )
                        }
                        className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
                      >
                        <option value="left">Left</option>
                        <option value="center">Center</option>
                        <option value="right">Right</option>
                      </select>
                    </div>

                    <div className="border-b-2 border-gray-50 my-6"></div>

                    <div className="flex items-center justify-between mb-4">
                      <span className="text-sm text-gray-500">Text color</span>
                      <button className="focus:outline-none">
                        <Info size={16} className="text-gray-500" />
                      </button>
                    </div>

                    <EditorColorPicker
                      value={
                        elementData.table.columns[activeColumnId][
                          activeColumnStyle
                        ].color
                          ? elementData.table.columns[activeColumnId][
                              activeColumnStyle
                            ].color.replace("#", "")
                          : ""
                      }
                      onChange={(value) =>
                        updateElementData(
                          set(
                            elementData,
                            [
                              "table",
                              "columns",
                              activeColumnId,
                              activeColumnStyle,
                              "color",
                            ],
                            value !== "transparent" ? `#${value}` : value
                          )
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default TableConfig;
