import { useMutation, useQueryClient } from "react-query";
import { SetterOrUpdater, useSetRecoilState } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";

const handleSwitchOrganization = async (
  orgId: string,
  setActiveOrganizationId: SetterOrUpdater<string | undefined>
) => {
  setActiveOrganizationId(orgId);
  sessionStorage.setItem("activeOrganizationId", orgId);
};

export const useSwitchOrganization = () => {
  const setActiveOrganizationId = useSetRecoilState(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    (orgId: string) => handleSwitchOrganization(orgId, setActiveOrganizationId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("profile");
      },
    }
  );
};
