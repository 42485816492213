import {
  PlusCircle,
  CreditCard,
  Type,
  Menu,
  Image as ImageIcon,
  Cloud,
} from "react-feather";

import { useCreateElement } from "./editor.atom";
import { TreeItemTypes } from "../../models/editor.model";

export const EmptyPageQuickActions = () => {
  const handleCreateElement = useCreateElement();

  return (
    <div className="flex flex-col items-center text-red-400 w-full text-center">
      <PlusCircle size={24} className="text-red-400 mb-4" />
      <span className="text-sm">
        Add your first element. Here are some recommendations:
      </span>
      <div className="flex items-center p-2 mt-4">
        <button
          className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
          onClick={() => handleCreateElement(TreeItemTypes.ROW)}
        >
          <CreditCard size={20} />
          <span className="text-sm mt-1">Row</span>
        </button>
        <button
          className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
          onClick={() => handleCreateElement(TreeItemTypes.TEXT)}
        >
          <Type size={20} />
          <span className="text-sm mt-1">Text</span>
        </button>
        <button
          className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
          onClick={() => handleCreateElement(TreeItemTypes.IMAGE)}
        >
          <ImageIcon size={20} />
          <span className="text-sm mt-1">Image</span>
        </button>
        <button
          className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
          onClick={() => handleCreateElement(TreeItemTypes.TABLE)}
        >
          <Menu size={20} />
          <span className="text-sm mt-1">Table</span>
        </button>
        <button
          className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
          onClick={() => handleCreateElement(TreeItemTypes.FLOAT)}
        >
          <Cloud size={20} />
          <span className="text-sm mt-1">Floating Box</span>
        </button>
      </div>
    </div>
  );
};
