import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";

const deleteApiRequestPayloadQuery = async (
  token: string,
  organizationId: string | undefined,
  apiRequestId: string
): Promise<void> => {
  if (!organizationId) {
    throw Error("No active organization");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/api-requests/${apiRequestId}/data`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to delete payload for API request with ID ${apiRequestId}`
    );
  }
};

export const useDeleteApiRequestPayload = () => {
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const { data: token } = useIdToken();
  const queryClient = useQueryClient();

  return useMutation(
    (apiRequestId: string) =>
      deleteApiRequestPayloadQuery(
        token as string,
        organizationId,
        apiRequestId
      ),
    {
      onSuccess: () => {
        toast.success("Successfully deleted API request payload");
        queryClient.invalidateQueries(["debug", organizationId]);
      },
      onError: () => {
        toast.success("Something bad happened!");
      },
    }
  );
};
