import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";

const cancelSubscription = async (
  token: string,
  organizationId: string | undefined
) => {
  if (!organizationId) {
    throw Error("No active organization found.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/stripe-cancel`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );

  const json = await response.json();

  return json.session;
};

export const useCancelSubscription = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useMutation(() => cancelSubscription(token as string, organizationId));
};
