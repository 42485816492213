import { useState, useEffect, useCallback } from "react";
import { useComboKeys } from "react-combo-keys";
import ReactTooltip from "react-tooltip";
import { useDebouncedCallback } from "use-debounce";
import {
  Snapshot,
  useGotoRecoilSnapshot,
  useRecoilCallback,
  useRecoilSnapshot,
  useRecoilTransactionObserver_UNSTABLE,
  useRecoilValue,
} from "recoil";
import { CornerUpLeft, CornerUpRight } from "react-feather";
import { isEqual, last, takeRight } from "lodash";
import {
  activePageState,
  editorActiveTemplateId,
  editorPagesState,
  elementPageSettingsState,
  elementsDataState,
  elementsState,
  elementsLayoutState,
  activeElementIdState,
  hoveredElementIdState,
  activeSimiliarElementTypeState,
  addElementIdState,
  additionalElementIdsState,
  dropElementIdState,
} from "./editor.atom";
import { useCounter, useDebounce, useList } from "react-use";
import { useUpdateTemplatePages } from "../../hooks/mutations/useUpdateTemplatePages";

const getNotableState = (snapshot: Snapshot) => {
  const elements = snapshot.getLoadable(elementsState).contents;
  const elementsData = snapshot.getLoadable(elementsDataState).contents;
  const pageSettings = snapshot.getLoadable(elementPageSettingsState).contents;
  const pages = snapshot.getLoadable(editorPagesState).contents;
  const activePageIndex = snapshot.getLoadable(activePageState).contents;
  // const elementsLayout = snapshot.getLoadable(elementsLayoutState).contents;

  return {
    elements,
    elementsData,
    pageSettings,
    pages,
    activePageIndex,
    // elementsLayout,
  };
};

const UndoRedo = () => {
  const [snapshots, { push, set: setSnapshots }] = useList<any>([]);
  const [activeSnapshotIndex, { inc, dec, set }] = useCounter();
  const templateId = useRecoilValue(editorActiveTemplateId);
  const { mutate: mutateTemplatePages, isLoading: isLoadingTemplatePages } =
    useUpdateTemplatePages();

  const handleSaves = useDebouncedCallback((currentNotableState) => {
    if (!snapshots.length) {
      return push(currentNotableState);
    }

    const lastNotableState = snapshots[activeSnapshotIndex];
    if (!isEqual(lastNotableState, currentNotableState)) {
      if (activeSnapshotIndex !== snapshots.length - 1) {
        const cutSnapshots = snapshots.slice(0, activeSnapshotIndex + 1);

        setSnapshots([...cutSnapshots, currentNotableState]);
        set(cutSnapshots.length);
      } else {
        const newSnapshots = takeRight([...snapshots, currentNotableState], 24);
        setSnapshots(newSnapshots);
        // push(currentNotableState);
        set(newSnapshots.length);
      }
    }
  }, 250);

  useRecoilTransactionObserver_UNSTABLE(async ({ snapshot }) => {
    if (!snapshot.getLoadable(editorActiveTemplateId).contents) {
      return;
    }

    const currentNotableState = getNotableState(snapshot);
    handleSaves(currentNotableState);
  });

  const handleSetState = useRecoilCallback(
    ({ set }) =>
      (state: any) => {
        const { elements, elementsData, pageSettings, pages, activePageIndex } =
          state;

        set(editorPagesState, pages);
        set(elementPageSettingsState, pageSettings);
        set(activePageState, activePageIndex);
        set(elementsState, elements);
        set(elementsDataState, elementsData);

        set(activeElementIdState, undefined);
        set(hoveredElementIdState, undefined);
        set(addElementIdState, undefined);
        set(dropElementIdState, undefined);
        set(activeSimiliarElementTypeState, undefined);
        set(additionalElementIdsState, []);
      },
    []
  );

  const handleSave = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        if (templateId === "new") {
          return;
        }

        const [activePageIndex, pages, elements, elementsData, pageSettings] =
          await Promise.all([
            snapshot.getPromise(activePageState),
            snapshot.getPromise(editorPagesState),
            snapshot.getPromise(elementsState),
            snapshot.getPromise(elementsDataState),
            snapshot.getPromise(elementPageSettingsState),
          ]);

        const updatePages = pages.map((page: any, index: number) =>
          index === activePageIndex
            ? {
                tree: elements,
                data: elementsData,
                pageSettings,
                id: page.id,
                name: page.name,
              }
            : page
        );

        mutateTemplatePages({
          templateId,
          pages: updatePages,
        });

        console.log("saved template");
      },
    [templateId]
  );

  useDebounce(handleSave, 20 * 1000, [activeSnapshotIndex]);

  const handleUndo = useCallback(() => {
    if (!snapshots.length) return;

    const lastIndex = Math.max(activeSnapshotIndex - 1, 0);
    handleSetState(snapshots[lastIndex]);
    set(lastIndex);
  }, [activeSnapshotIndex, snapshots]);

  const handleRedo = useCallback(() => {
    const nextIndex = Math.min(activeSnapshotIndex + 1, snapshots.length - 1);
    handleSetState(snapshots[nextIndex]);
    set(nextIndex);
  }, [activeSnapshotIndex, snapshots]);

  useComboKeys({
    "ctrl+r": handleRedo,
    "ctrl+z": handleUndo,
  });

  return (
    <div className="w-48 flex items-center">
      <button
        className="focus:outline-none mx-2"
        onClick={handleUndo}
        disabled={activeSnapshotIndex === 0}
      >
        <CornerUpLeft
          className={
            activeSnapshotIndex === 0 ? "text-gray-300" : "text-gray-500"
          }
          size={20}
          data-tip="Undo (cltr + z)"
          data-for="undo"
        />
      </button>
      <button
        className="focus:outline-none mx-2"
        disabled={activeSnapshotIndex + 1 === snapshots.length}
        onClick={handleRedo}
      >
        <CornerUpRight
          className={
            activeSnapshotIndex + 1 === snapshots.length
              ? "text-gray-300"
              : "text-gray-500"
          }
          size={20}
          data-tip="Redo (cltr + r)"
          data-for="redo"
        />
      </button>
      <ReactTooltip
        id="undo"
        effect="solid"
        place="bottom"
        type="dark"
        className="font-light"
      />
      <ReactTooltip
        id="redo"
        effect="solid"
        place="bottom"
        type="dark"
        className="font-light"
      />
    </div>
  );
};

export default UndoRedo;
