import React, { useMemo } from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { pick, isNumber, omit } from "lodash";
import Handlebars from "handlebars";
import {
  getTableRowStyles,
  getTableTextStyles,
  sanitizeTextStyles,
} from "../../utils/preview.util";

interface PDFElementProps {
  id: string;
  pages: any[];
  pageIndex: number;
  outsideData: any;
  type: string;
  subelements: any[];
  styles?: any;
  isParentHeighSet?: boolean;
}

const PDFElement = ({
  id,
  pages,
  pageIndex,
  outsideData,
  type,
  subelements,
  styles,
  isParentHeighSet,
}: PDFElementProps) => {
  const page = useMemo(() => pages[pageIndex], [pageIndex]);
  let elementStyles = page?.data?.[id]?.styles ?? {};
  if (
    elementStyles?.height &&
    elementStyles.height === "100%" &&
    !isParentHeighSet
  ) {
    elementStyles = {
      ...elementStyles,
      height: undefined,
    };
  }

  if (type === "image") {
    let clean: string;

    try {
      clean = Handlebars.compile(page?.data?.[id]?.imageUrl || "")(outsideData);
    } catch (error) {
      clean = "";
    }
    const source = clean?.startsWith("data:") ? { data: clean } : clean || "";

    if (!clean) {
      return <View style={[elementStyles, { backgroundColor: "#F3F4F6" }]} />;
    }

    return <Image source={clean} style={elementStyles} />;
  }

  if (type === "text") {
    let text: string;

    try {
      text = page?.data?.[id]?.textContent
        ? Handlebars.compile(page?.data?.[id]?.textContent, {
            noEscape: true,
          })(outsideData)
        : "";
    } catch (error) {
      text = page?.data?.[id]?.textContent;
    }

    return (
      <Text
        style={[
          // styles,
          sanitizeTextStyles(elementStyles),
        ]}
      >
        {text}
      </Text>
    );
  }

  if (type === "float") {
    return (
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          paddingTop: page.pageSettings.paddingTop,
          paddingBottom: page.pageSettings.paddingBottom,
          paddingLeft: page.pageSettings.paddingLeft,
          paddingRight: page.pageSettings.paddingRight,
        }}
      >
        <View style={[styles, elementStyles]}>
          {(subelements || []).map((element: any) => (
            <PDFElement
              key={element.id}
              id={element.id}
              pages={pages}
              pageIndex={pageIndex}
              outsideData={outsideData}
              type={element?.type ?? "view"}
              subelements={element?.children ?? []}
            />
          ))}
        </View>
      </View>
    );
  }

  if (type === "list") {
    const listData = page?.data?.[id]?.list;

    return (
      <View
        style={{
          width: "100%",
          flexWrap: "wrap",
          flexDirection: listData?.direction ?? "column",
        }}
      >
        {subelements?.length &&
          (outsideData?.[listData?.accessor] ?? []).map(
            (item: any, index: number) => (
              <PDFElement
                key={index}
                id={subelements[0]?.id}
                pages={pages}
                pageIndex={pageIndex}
                outsideData={item}
                type={subelements[0]?.type ?? "view"}
                subelements={subelements[0]?.children ?? []}
              />
            )
          )}
      </View>
    );
  }

  if (type === "table") {
    const tableData = page?.data?.[id]?.table;

    return (
      <View style={{ width: "100%" }}>
        <View
          style={[
            { flexDirection: "row", width: "100%" },
            getTableRowStyles(tableData.headerStyles),
          ]}
        >
          {tableData.columnIds.map((columnId: string, index: number) => (
            <View
              key={columnId}
              style={[
                { flex: tableData?.columns?.[columnId]?.weight ?? 1 },
                {
                  marginRight: index === 0 ? 5 : undefined,
                  marginHorizontal:
                    index !== 0 && index !== tableData.columnIds.length - 1
                      ? 5
                      : undefined,
                  marginLeft:
                    index === tableData.columnIds.length - 1 ? 5 : undefined,
                },
              ]}
            >
              <Text
                style={getTableTextStyles(
                  tableData?.columns?.[columnId].headerStyles
                )}
              >
                {tableData?.columns?.[columnId]?.headerLabel}
              </Text>
            </View>
          ))}
        </View>
        {(outsideData?.[tableData?.accessor] ?? []).map(
          (row: any, index: number) => (
            <View
              key={index}
              style={[
                { flexDirection: "row", width: "100%" },
                getTableRowStyles(tableData.rowStyles),
                tableData?.rowStyles?.isAlternateBackground && index % 2 !== 1
                  ? { backgroundColor: "white" }
                  : {},
              ]}
            >
              {tableData.columnIds.map((columnId: string, index: number) => (
                <View
                  key={columnId}
                  style={[
                    { flex: tableData?.columns?.[columnId]?.weight ?? 1 },
                    {
                      marginRight: index === 0 ? 5 : undefined,
                      marginHorizontal:
                        index !== 0 && index !== tableData.columnIds.length - 1
                          ? 5
                          : undefined,
                      marginLeft:
                        index === tableData.columnIds.length - 1
                          ? 5
                          : undefined,
                    },
                  ]}
                >
                  <Text
                    style={getTableTextStyles(
                      tableData?.columns?.[columnId].bodyStyles
                    )}
                  >{`${row?.[tableData?.columns?.[columnId]?.accessor]}`}</Text>
                </View>
              ))}
            </View>
          )
        )}
      </View>
    );
  }

  if (id === "*") {
    return (
      <View style={[styles, elementStyles, { height: "100%", width: "100%" }]}>
        {(subelements || []).map((element: any) => (
          <PDFElement
            key={element.id}
            id={element.id}
            pages={pages}
            pageIndex={pageIndex}
            outsideData={outsideData}
            type={element?.type ?? "view"}
            subelements={element?.children ?? []}
          />
        ))}
      </View>
    );
  }

  return (
    <View style={[styles, elementStyles]}>
      {(subelements || []).map((element: any) => (
        <PDFElement
          key={element.id}
          id={element.id}
          pages={pages}
          pageIndex={pageIndex}
          outsideData={outsideData}
          type={element?.type ?? "view"}
          subelements={element?.children ?? []}
          isParentHeighSet={!!elementStyles?.height || isParentHeighSet}
        />
      ))}
    </View>
  );
};

export default PDFElement;
