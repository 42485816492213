import { useMutation, useQueryClient } from "react-query";
import { useIdToken } from "../queries/useIdToken";

const handleDeclineInvite = async (
  token: string,
  organizationId: string,
  inviteId: string
): Promise<boolean> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/invites/${inviteId}/decline`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.success;
};

export const useDeclineInvite = () => {
  const { data: token } = useIdToken();
  const queryClient = useQueryClient();

  return useMutation(
    ({ inviteId, orgId }: { inviteId: string; orgId: string }) =>
      handleDeclineInvite(token as string, orgId, inviteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organization-invites");
      },
    }
  );
};
