import { forwardRef, ForwardedRef } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { useToggle, useKey, useKeyPressEvent } from "react-use";

interface InputNumberProps {
  value: number | string | undefined;
  placeholder?: string;
  onChange?: (value: number | undefined) => void;
  onTab?: () => void;
}

const InputNumber = forwardRef(
  (
    { value, onChange, onTab, placeholder }: InputNumberProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [isFocused, toggleFocused] = useToggle(false);
    const [isAccelerated, toggleAccerated] = useToggle(false);
    // useKey("38", () => onChange && onChange((Number(value) || 0) + 1));
    // useKey("40", () => onChange && onChange((Number(value) || 0) - 1));
    // useKey("", (e) => console.log(e));
    useKeyPressEvent("ArrowUp", (e) => {
      e.preventDefault();
      if (isFocused) {
        onChange?.((Number(value) || 0) + (isAccelerated ? 10 : 1));
      }
    });
    useKeyPressEvent("ArrowDown", (e) => {
      e.preventDefault();
      if (isFocused) {
        onChange?.((Number(value) || 0) - (isAccelerated ? 10 : 1));
      }
    });
    useKeyPressEvent("Tab", (e) => {
      e.preventDefault();
      if (isFocused) {
        onTab?.();
      }
    });

    useKeyPressEvent(
      "Shift",
      (e) => {
        e.preventDefault();
        if (isFocused) {
          toggleAccerated(true);
        }
      },
      (e) => {
        e.preventDefault();
        if (isFocused) {
          toggleAccerated(false);
        }
      }
    );

    return (
      <div className="flex items-center px-3 border border-gray-100 rounded-md w-min">
        <input
          ref={ref}
          className="w-12 mr-2 py-1 my-1 focus:outline-none text-sm"
          value={value}
          onChange={(evt) =>
            onChange &&
            onChange(evt.target.value ? Number(evt.target.value) : undefined)
          }
          placeholder={placeholder}
          onFocus={() => toggleFocused(true)}
          onBlur={() => toggleFocused(false)}
        />
        <div className="flex flex-col justify-center items-center">
          <button
            className="focus:outline-none"
            onClick={() => onChange && onChange((Number(value) || 0) + 1)}
          >
            <ChevronUp className="text-gray-400" size={12} />
          </button>
          <button
            className="focus:outline-none"
            onClick={() => onChange && onChange((Number(value) || 0) - 1)}
          >
            <ChevronDown className="text-gray-400" size={12} />
          </button>
        </div>
        {isFocused ? <></> : null}
      </div>
    );
  }
);

export default InputNumber;
