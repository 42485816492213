import { useState } from "react";
import { Plus } from "react-feather";
import { useToggle } from "react-use";
import APIKeyModal from "../../components/APIKeyModal";
import { GenericButton } from "../../components/GenericButton";
import { useAddApiKey } from "../../hooks/mutations/useAddApiKey";
import { useApiKeys } from "../../hooks/queries/useApiKeys";
import { KeyItem } from "./KeyItem";

const Keys = () => {
  const [newKey, updateNewKey] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const { data: apiKeys } = useApiKeys();
  const { mutate: addKeyMutate, isLoading } = useAddApiKey();

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Manage API Keys
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                A key that unlocks many doors is a great key, but a door that is
                unlocked by many keys is...
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <div className="border overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-8">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <div className="w-full bg-gray-50 rounded-md py-2 px-4">
                      <div className="grid grid-cols-4 text-sm text-gray-500">
                        <div className="col-span-3">Label</div>
                        <div className="col-span-1"></div>
                      </div>
                    </div>

                    {apiKeys?.map((apiKey: any) => (
                      <KeyItem key={apiKey.id} {...apiKey} />
                    ))}
                  </div>

                  <div className="mt-4 col-span-6 sm:col-span-4 lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Need a new API key?
                    </label>
                    <input
                      type="text"
                      name="api_key"
                      id="api_key"
                      autoComplete="api-key"
                      placeholder="Label"
                      className="mt-2 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={newKey}
                      onChange={(evt) => updateNewKey(evt.target.value)}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 self-end">
                    <GenericButton
                      onClick={() => {
                        addKeyMutate(newKey, {
                          onSuccess: (data: any) => {
                            setApiKey(data.value);
                            openModal();
                          },
                        });
                        updateNewKey("");
                      }}
                      disabled={!newKey}
                      isLoading={isLoading}
                    >
                      <>
                        <Plus size={16} />
                        <span className="ml-2">Add api key</span>
                      </>
                    </GenericButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIKeyModal
        closeModal={closeModal}
        isOpen={isOpen}
        value={apiKey}
      />
    </>
  );
};

export default Keys;
