import { ReactNode } from "react";
import CountUp from "react-countup";

interface QuickStatProps {
  icon: ReactNode;
  label: string;
  count: number;
  textColor: string;
  bgColor: string;
}

const QuickStat = ({
  icon,
  label,
  count,
  textColor,
  bgColor,
}: QuickStatProps) => {
  return (
    <div className="rounded-lg shadow-xs bg-white">
      <div className="p-4 flex items-center">
        <div className={`p-3 rounded-full ${textColor} ${bgColor} mr-4`}>
          {icon}
        </div>
        <div>
          <p className="mb-0 text-sm">{label}</p>
          <p className="text-lg font-semibold text-gray-700">
            <CountUp end={count ?? 0} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuickStat;
