export type User = {
  id: string;
  externalAuthId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  isShowWelcome: boolean;
  country?: string;
  company?: string;
};

export type Organization = {
  id: string;
  createAt: string;
  deletedAt: string | null;
  updatedAt: string;
  name: string;
  ownerId: string;
  stripeId: string | null;
  stripeLink: string | null;
  plan?: Plan;
  users: {
    organizationId: string;
    userId: string;
    user: User;
  }[];
};

export type Profile = User & {
  organizations: {
    organizationId: string;
    userId: string;
    organization: Organization;
  }[];
};

export type ApiKey = {
  id: string;
  label: string;
  organizationId: string;
};

export type Template = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  pages: any[];
};

export enum InviteStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export type Invite = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  email: string;
  userId: string;
  status: InviteStatus;
  organizationId: string;
  organization?: Organization;
};

export type Plan = {
  id: string;
  stripeId?: string;
  isActive: boolean;
  description: string;
  name: string;
  stripeMetaLimit: number;
  price?: Price;
  monthlyPagesLimit: number;
  isDefault: boolean;
};

export type Price = {
  id: string;
  stripeId: string;
};

export type Subscription = {
  id: string;
  organizationId: string;
  stripeId: string;
  stripeLink: string;
  status: "active" | "canceled";
  planId: string;
  plan: Plan;
  priceId: string;
  price: Price;
  metadata: any;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  userCancelledAt?: string;
  stripeCancelledAt?: string;
  endOfPeriodCancel: boolean;
  usage: {
    id: string;
    createdAt: string;
    updatedAt: string;
    subscriptionId: string;
    startPeriod: string;
    endPeriod: string;
    pages: number;
    status: string;
  }[];
};

export type ApiRequest = {
  id: string;
  requestedAt: string;
  apiKey: string;
  data: any;
  status: "SUCCESS" | "FAILED";
  pages: number;
  organizationId: string;
  templateId: string;
};
