import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Organization } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getOrganization = async (
  token: string,
  organizationId: string | undefined
) => {
  if (!organizationId) {
    throw Error("No active organization");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.organization as Organization;
};

export const useOrganization = () => {
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const { data: token } = useIdToken();

  return useQuery(
    ["organization", organizationId],
    () => getOrganization(token as string, organizationId),
    {
      enabled: !!organizationId && !!token,
    }
  );
};
