import { useMutation, useQueryClient } from "react-query";
import { useProfile } from "../queries/useProfile";
import { useRecoilState } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";
import toast from "react-hot-toast";

const handleDeleteOrganization = async (
  token: string,
  organizationId: string | undefined
) => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  if (!json.success) {
    throw Error(json?.message || "Unable to delete organization");
  }

  return Promise.resolve(true);
};

export const useDeleteOrganization = () => {
  const { data: token } = useIdToken();
  const { data: profile } = useProfile();
  const [organizationId, setOrganizationId] = useRecoilState(
    activeOrganizationIdState
  );
  const queryClient = useQueryClient();

  return useMutation(
    () => handleDeleteOrganization(token as string, organizationId),
    {
      onSuccess: () => {
        toast.success("Successfully deleted organization!");
        queryClient.invalidateQueries("profile");
        queryClient.invalidateQueries("organization");
        if (profile) {
          setOrganizationId(profile.organizations?.[0]?.organizationId);
          sessionStorage.setItem(
            "activeOrganizationId",
            profile.organizations?.[0]?.organizationId as string
          );
        }
      },
      onError: () => {
        toast.success("Something bad happened!");
      },
    }
  );
};
