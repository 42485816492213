import { useState, useMemo } from "react";
import { Plus, ChevronDown, Edit, Trash2 } from "react-feather";
import { useRecoilValue, useRecoilCallback, useRecoilState } from "recoil";
import {
  pagesLengthState,
  activePageState,
  editorPagesState,
  elementsState,
  elementsDataState,
  elementPageSettingsState,
  activeElementIdState,
  pageIdsSelector,
  pageNamesByIdIndexSelector,
} from "./editor.atom";
import { range } from "lodash";
import { generateNewPageDefaults } from "../../utils/editor.util";
import { motion, Reorder } from "framer-motion";
import { Portal } from "react-portal";
import RenamePageModal from "./RenamePageModal";

const Pages = () => {
  const [animationState, setAnimationState] = useState("open");
  const [activePageId, setActivePageId] = useState<string>();
  const activePageIndex = useRecoilValue(activePageState);
  const [pageIds, setPagesId] = useRecoilState(pageIdsSelector);
  const pageNamesIndex = useRecoilValue(pageNamesByIdIndexSelector);

  const pageHandler = useRecoilCallback(
    ({ set, snapshot }) =>
      async (index: number | undefined) => {
        const pages = await snapshot.getPromise(editorPagesState);
        const elements = await snapshot.getPromise(elementsState);
        const elementsData = await snapshot.getPromise(elementsDataState);
        const pageSettings = await snapshot.getPromise(
          elementPageSettingsState
        );

        const updatePages = pages.map((page, index) =>
          index === activePageIndex
            ? {
                tree: elements,
                data: elementsData,
                pageSettings,
                id: page.id,
                name: page.name,
              }
            : page
        );
        await set(activeElementIdState, undefined);

        if (index === undefined) {
          const appendedPages = [...updatePages, generateNewPageDefaults()];
          await set(editorPagesState, appendedPages);

          const updatedIndex = appendedPages.length - 1;
          await set(activePageState, updatedIndex);
          await set(elementsState, appendedPages[updatedIndex].tree);
          await set(elementsDataState, appendedPages[updatedIndex].data);
          await set(
            elementPageSettingsState,
            appendedPages[updatedIndex].pageSettings
          );
        } else {
          await set(editorPagesState, updatePages);

          await set(activePageState, index);
          await set(elementsState, updatePages[index].tree);
          await set(elementsDataState, updatePages[index].data);
          await set(elementPageSettingsState, updatePages[index].pageSettings);
        }
      },
    [activePageIndex]
  );

  const deleteHandler = useRecoilCallback(
    ({ set, snapshot }) =>
      async (id: string) => {
        const pages = await snapshot.getPromise(editorPagesState);

        const currentId = pages.find((page, i) => i === activePageIndex)?.id;
        const updatePages = pages.filter((page, i) => page.id !== id);
        set(activeElementIdState, undefined);
        set(editorPagesState, updatePages);

        set(
          activePageState,
          updatePages.findIndex((page) => page.id === currentId)
        );
      },
    [activePageIndex]
  );

  return (
    <>
      <motion.div className="p-4 mb-2" initial="open" animate={animationState}>
        <div className="flex items-center py-2">
          <span className="text-sm mr-auto">Pages</span>
          <button
            className="focus:outline-none mr-2"
            onClick={() => pageHandler(undefined)}
          >
            <Plus className="text-gray-500" size={16} />
          </button>
          <motion.button
            className="focus:outline-none"
            onClick={() =>
              setAnimationState(animationState === "closed" ? "open" : "closed")
            }
            variants={{
              closed: {
                rotate: 180,
              },
              open: {
                rotate: 0,
              },
            }}
            transition={{
              type: "tween",
            }}
          >
            <ChevronDown className="text-gray-500" size={16} />
          </motion.button>
        </div>
        <motion.div
          className="overflow-hidden"
          variants={{
            closed: {
              height: 0,
            },
            open: {
              height: "auto",
            },
          }}
          transition={{
            type: "tween",
          }}
        >
          {pageIds.length && !!pageIds[0] ? (
            <div className="py-2 border-t-2 border-gray-50">
              <Reorder.Group axis="y" values={pageIds} onReorder={setPagesId}>
                {pageIds.map((id, index) => (
                  <Reorder.Item key={id} value={id}>
                    <div
                      className={`w-full flex justify-between items-center text-sm py-1 mb-2 px-2 hover:bg-red-100 rounded-md ${
                        activePageIndex === index
                          ? "text-red-400"
                          : "text-gray-500"
                      }`}
                    >
                      <button
                        className="focus:outline-none"
                        onClick={() => pageHandler(index)}
                      >
                        <span className="font-light">{pageNamesIndex[id]}</span>
                      </button>

                      <div className="space-x-2">
                        <button
                          className="focus:outline-none text-gray-500"
                          onClick={() => setActivePageId(id)}
                        >
                          <Edit size={16} />
                        </button>
                        <button
                          className="focus:outline-none text-gray-500 disabled:opacity-50"
                          onClick={() => deleteHandler(id)}
                          disabled={activePageIndex === index}
                        >
                          <Trash2 size={16} />
                        </button>
                      </div>
                    </div>
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            </div>
          ) : null}
        </motion.div>
      </motion.div>

      <Portal>
        <RenamePageModal
          activePageId={activePageId}
          onClose={() => setActivePageId(undefined)}
        />
      </Portal>
    </>
  );
};

export default Pages;
