import { ReactNode } from "react";
import { Text } from "theme-ui";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { colors } from "../../theme";

interface LinkProps {
  label: string;
  icon: ReactNode;
  activeIcon?: ReactNode;
  path: string;
}

const Link = ({ label, icon, activeIcon, path }: LinkProps) => {
  const resolved = useResolvedPath(path);
  const match = useMatch({ path: resolved.pathname, end: path === "/" });

  return (
    <NavLink
      to={path}
      style={({ isActive }) => ({
        textDecoration: "none",
        color: isActive ? "white" : colors.content,
      })}
    >
      <div
        className={`flex ${
          match ? "bg-gradient-to-l from-red-400 to-red-500" : "bg-white"
        } items-center rounded-md ${match ? "px-4" : ""} py-2 mt-3`}
      >
        {match ? activeIcon || icon : icon}
        <Text ml={2}>{label}</Text>
      </div>
    </NavLink>
  );
};

export default Link;
