import { useMeasure } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";
import { range } from "lodash";

import {
  activeElementTypeState,
  elementPageSettingsState,
  globalFloatingGridState,
  globalZoomState,
} from "./editor.atom";
import { useEffect, useMemo } from "react";
import { TreeItemTypes } from "../../models/editor.model";
import { adjustOverlayByZoom } from "../../utils/editor.util";

const FloatingGrid = () => {
  const [ref, { width, height }] = useMeasure();
  const activeElementType = useRecoilValue(activeElementTypeState);
  const pageSettings = useRecoilValue(elementPageSettingsState);
  const [globalGrid, updateGlobalGrid] = useRecoilState(
    globalFloatingGridState
  );
  const globalZoom = useRecoilValue(globalZoomState);

  const gridWidth = useMemo(
    () => width / globalGrid.columns,
    [
      width,
      pageSettings.paddingLeft,
      pageSettings.paddingRight,
      globalGrid.columns,
    ]
  );
  const gridHeight = useMemo(
    () => height / globalGrid.rows,
    [
      height,
      pageSettings.paddingTop,
      pageSettings.paddingBottom,
      globalGrid.rows,
    ]
  );

  useEffect(() => {
    updateGlobalGrid({ ...globalGrid, gridHeight, gridWidth, width, height });
  }, [gridHeight, gridWidth]);

  return (
    <div
      className="absolute"
      style={adjustOverlayByZoom(
        {
          top: pageSettings.paddingTop,
          left: pageSettings.paddingLeft,
          right: pageSettings.paddingRight,
          bottom: pageSettings.paddingBottom,
          pointerEvents: "none",
        },
        globalZoom.zoomRatio
      )}
      ref={ref as any}
    >
      {activeElementType === TreeItemTypes.FLOAT ? (
        <>
          {range(globalGrid.columns + 1).map((c, index) => (
            <div
              key={`column-${index}`}
              className="absolute border-r border-l border-blue-100 border-dashed border-opacity-50"
              style={{
                top: 0,
                bottom: 0,
                left: `${index * (100 / globalGrid.columns)}%`,
              }}
            />
          ))}
          {range(globalGrid.rows + 1).map((c, index) => (
            <div
              key={`row-${index}`}
              className="absolute border-t border-b border-blue-100 border-dashed border-opacity-50"
              style={{
                left: 0,
                right: 0,
                top: `${index * (100 / globalGrid.rows)}%`,
              }}
            />
          ))}
        </>
      ) : null}
    </div>
  );
};

export default FloatingGrid;
