import { useState, useMemo, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { XCircle } from "react-feather";
import { useRecoilState } from "recoil";
import { pageNameByIdSelector } from "./editor.atom";

const VARIANTS = {
  overlay: {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  },
  content: {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: 300 },
  },
};

interface RenamePageModalProps {
  activePageId?: string;
  onClose: () => void;
}

const RenamePageModal = ({ activePageId, onClose }: RenamePageModalProps) => {
  const isVisible = useMemo(() => !!activePageId, [activePageId]);
  const [pageName, setPageName] = useRecoilState(
    pageNameByIdSelector(activePageId)
  );

  const [tempName, setTempName] = useState("");

  useEffect(() => {
    if (pageName) {
      setTempName(pageName);
    }
  }, [pageName]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="container"
          className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-20"
          initial="closed"
          animate={isVisible ? "open" : "closed"}
          exit="closed"
        >
          <motion.div
            key="overlay"
            className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 z-30 cursor-pointer"
            variants={VARIANTS.overlay}
            onClick={() => onClose()}
          />
          <motion.div
            key="content"
            className="z-40 w-full"
            variants={VARIANTS.content}
          >
            <div className="bg-white shadow-lg rounded-lg w-full max-w-3xl mx-auto">
              <div className="h-14 px-4 bg-gray-100 flex items-center justify-between rounded-t-lg">
                <span className="text-gray-500">Rename page</span>
                <button
                  className="text-gray-500 focus:outline-none"
                  onClick={onClose}
                >
                  <XCircle size={20} />
                </button>
              </div>
              <div className="px-4 py-6 font-light text-sm">
                <div className="grid grid-cols-4 gap-x-4 mt-4 w-full">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Renamed page
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={tempName}
                      onChange={(evt) => setTempName(evt.target.value)}
                    />
                  </div>
                  <div className="col-span-1 self-end">
                    <button
                      type="button"
                      className={`inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                        tempName.length
                          ? "bg-red-500 hover:bg-red-400"
                          : "bg-gray-300 hover:bg-gray-400"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200`}
                      disabled={!tempName.length}
                      onClick={() => {
                        setPageName(tempName);
                        onClose();
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RenamePageModal;
