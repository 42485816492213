import { Flex, Heading, Box, Card, Text, IconButton } from 'theme-ui';
import { motion, AnimatePresence } from 'framer-motion';
import ReactJson from 'react-json-view';
import dayjs from 'dayjs';

import { ReactComponent as CloseSquare } from '../../assets/icons/outlined/Close-Square.svg';
import { ReactComponent as Duplicate } from '../../assets/icons/outlined/Duplicate.svg';

import mock from '../../mocks/debug-console.mocks';
import { colors } from '../../theme';

const VARIANTS = {
  overlay: {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  },
  content: {
    open: {
      opacity: 1,
      y: 0,
    },
    closed: {
      opacity: 0,
      y: 300,
    },
  },
};

interface DebugModalProps {
  isVisible: boolean;
  toggle(): void;
  data: any;
}

const DebugModal = ({ isVisible, toggle, data }: DebugModalProps) => {
  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          key='container'
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          initial='closed'
          animate={isVisible ? 'open' : 'closed'}
          exit='closed'
        >
          <motion.div
            key='overlay'
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,.4)',
              zIndex: 1000,
            }}
            variants={VARIANTS.overlay}
            onClick={() => toggle()}
          />
          <motion.div
            key='content'
            style={{ width: 800, zIndex: 1001 }}
            variants={VARIANTS.content}
          >
            <Card>
              <Flex sx={{ justifyContent: 'space-between' }}>
                <Box>
                  <Heading as='h4'>Request</Heading>
                  <Text>{dayjs(mock[0].date).format('LLL')}</Text>
                </Box>
                <IconButton onClick={toggle}>
                  <CloseSquare color={colors.primaryRed} />
                </IconButton>
              </Flex>
              <Box
                mt={3}
                sx={{
                  height: 400,
                  bg: 'rgb(43, 48, 59)',
                  position: 'relative',
                }}
                p={2}
              >
                <ReactJson src={data} theme='ocean' />

                <IconButton
                  sx={{ position: 'absolute', bottom: 16, right: 16 }}
                >
                  <Duplicate color={colors.primaryBlue} />
                </IconButton>
              </Box>
            </Card>
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default DebugModal;
