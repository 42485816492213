import { Info } from "react-feather";

import { useRecoilState, useRecoilValue } from "recoil";
import { useMemo, useRef, useState } from "react";
import { set } from "monolite";

import EditorColorPicker from "../../../components/EditorColorPicker";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";
import fontList from "../../../assets/font-list.json";
import { useEffect } from "react";
import { defer } from "lodash";
import { useUpdateElementStyles } from "../../../hooks/custom/useUpdateElementStyles";

const Typography = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );
  const updateStyles = useUpdateElementStyles(activeElementId);
  const ref = useRef<any>();

  const activeFontFamily = useMemo(
    () =>
      fontList
        .find((font) => font.family === elementData?.styles?.fontFamily)
        ?.fonts?.filter((individual) => individual.fontStyle === "normal")
        ?.map((individual) => individual.fontWeight),
    [elementData?.styles?.fontFamily]
  );

  useEffect(() => {
    defer(() => ref?.current?.focus());
  }, [activeElementId]);

  return (
    <EditorAccordion label="Text">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Content</span>
          <EditorInfo helpLabel="Content" />
        </div>

        <textarea
          ref={ref}
          rows={8}
          className="w-full border-2 border-gray-50 rounded-sm p-2 text-sm focus:outline-none focus:ring-1 focus:ring-blue-100"
          value={elementData?.textContent ?? ""}
          onChange={(evt) =>
            updateElementData(
              set(elementData, ["textContent"], evt.target.value)
            )
          }
          onFocus={(e) => {
            const temp_value = e.target.value;
            e.target.value = "";
            e.target.value = temp_value;
          }}
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Styling</span>
          <EditorInfo helpLabel="Styling" />
        </div>

        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400 font-light">Font family</span>
          <select
            value={elementData.styles.fontFamily ?? ""}
            onChange={(evt) =>
              updateElementData(
                set(elementData, ["styles", "fontFamily"], evt.target.value)
              )
            }
            className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
          >
            <option value="Abril Fatface">Abril Fatface</option>
            <option value="Alegreya">Alegreya</option>
            <option value="Archivo">Archivo</option>
            <option value="Arvo">Arvo</option>
            <option value="B612">B612</option>
            <option value="BioRhyme">BioRhyme</option>
            <option value="Cairo">Cairo</option>
            <option value="Cardo">Cardo</option>
            <option value="Concert One">Concert One</option>
            <option value="Cormorant">Cormorant</option>
            <option value="Crimson Text">Crimson Text</option>
            <option value="Exo 2">Exo 2</option>
            <option value="Fira Sans">Fira Sans</option>
            <option value="Fjalla One">Fjalla One</option>
            <option value="Frank Ruhl Libre">Frank Ruhl Libre</option>
            <option value="IBM Plex Serif">IBM Plex Serif</option>
            <option value="Karla">Karla</option>
            <option value="Lato">Lato</option>
            <option value="Lora">Lora</option>
            <option value="Merriweather">Merriweather</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Noto Sans">Noto Sans</option>
            <option value="Old Standard TT">Old Standard TT</option>
            <option value="Open Sans">Open Sans</option>
            <option value="Oswald">Oswald</option>
            <option value="Oxygen">Oxygen</option>
            <option value="Playfair Display">Playfair Display</option>
            <option value="Poppins">Poppins</option>
            <option value="PT Sans">PT Sans</option>
            <option value="PT Serif">PT Serif</option>
            <option value="Rakkas">Rakkas</option>
            <option value="Roboto">Roboto</option>
            <option value="Rubik">Rubik</option>
            <option value="Source Sans Pro">Source Sans Pro</option>
            <option value="Spectral">Spectral</option>
            <option value="Titillium Web">Titillium Web</option>
            <option value="Ubuntu">Ubuntu</option>
            <option value="Varela">Varela</option>
            <option value="Vollkorn">Vollkorn</option>
            <option value="Work Sans">Work Sans</option>
            <option value="Yatra One">Yatra One</option>
          </select>
        </div>
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400 font-light">Weight</span>
          <select
            onChange={(evt) =>
              // updateElementData(
              //   set(elementData, ["styles", "fontWeight"], evt.target.value)
              // )
              updateStyles(["styles", "fontWeight"], evt.target.value)
            }
            value={elementData.styles.fontWeight}
            className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
          >
            {activeFontFamily?.includes(100) ? (
              <option value={100}>Thin</option>
            ) : null}
            {activeFontFamily?.includes(200) ? (
              <option value={200}>Ultra light</option>
            ) : null}
            {activeFontFamily?.includes(300) ? (
              <option value={300}>Light</option>
            ) : null}
            {activeFontFamily?.includes(400) ? (
              <option value={400}>Regular</option>
            ) : null}
            {activeFontFamily?.includes(500) ? (
              <option value={500}>Medium</option>
            ) : null}
            {activeFontFamily?.includes(600) ? (
              <option value={600}>Semibold</option>
            ) : null}
            {activeFontFamily?.includes(700) ? (
              <option value={700}>Bold</option>
            ) : null}
          </select>
        </div>
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400 font-light">Size</span>
          <select
            onChange={(evt) =>
              // updateElementData(
              //   set(
              //     elementData,
              //     ["styles", "fontSize"],
              //     Number(evt.target.value)
              //   )
              // )
              updateStyles(["styles", "fontSize"], Number(evt.target.value))
            }
            value={elementData?.styles?.fontSize}
            className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
          >
            <option value={8}>8pt</option>
            <option value={10}>10pt</option>
            <option value={12}>12pt</option>
            <option value={14}>14pt</option>
            <option value={16}>16pt</option>
            <option value={18}>18pt</option>
            <option value={24}>24pt</option>
            <option value={30}>30pt</option>
            <option value={36}>36pt</option>
            <option value={48}>48pt</option>
            <option value={60}>60pt</option>
            <option value={72}>72pt</option>
            <option value={96}>96pt</option>
          </select>
        </div>
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400 font-light">Line Height</span>
          <select
            onChange={(evt) =>
              // updateElementData(
              //   set(
              //     elementData,
              //     ["styles", "lineHeight"],
              //     `${evt.target.value}px`
              //   )
              // )
              updateStyles(["styles", "lineHeight"], `${evt.target.value}px`)
            }
            value={
              elementData?.styles?.lineHeight
                ? Number(elementData?.styles?.lineHeight.replace("px", ""))
                : ""
            }
            className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
          >
            <option value={8}>8pt</option>
            <option value={10}>10pt</option>
            <option value={12}>12pt</option>
            <option value={14}>14pt</option>
            <option value={16}>16pt</option>
            <option value={18}>18pt</option>
            <option value={20}>20pt</option>
            <option value={22}>22pt</option>
            <option value={24}>24pt</option>
            <option value={30}>30pt</option>
            <option value={36}>36pt</option>
            <option value={48}>48pt</option>
            <option value={60}>60pt</option>
            <option value={72}>72pt</option>
            <option value={96}>96pt</option>
            <option value={120}>120pt</option>
          </select>
        </div>
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400 font-light">Alignment</span>
          <select
            onChange={(evt) =>
              // updateElementData(
              //   set(elementData, ["styles", "textAlign"], evt.target.value)
              // )
              updateStyles(["styles", "textAlign"], evt.target.value)
            }
            value={elementData.styles.textAlign ?? ""}
            className="block bg-white text-sm text-right border-b border-gray-100 border-t-0 border-l-0 border-r-0 py-1"
          >
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </div>

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Text color</span>
          <EditorInfo helpLabel="Text Color" />
        </div>

        <EditorColorPicker
          value={
            elementData?.styles?.color
              ? elementData?.styles?.color.replace("#", "")
              : ""
          }
          onChange={(value) =>
            // updateElementData(
            //   set(elementData, ["styles", "color"], value === 'transparent' ? value :`#${value}`)
            // )
            updateStyles(
              ["styles", "color"],
              value !== "transparent" ? `#${value}` : value
            )
          }
        />
      </div>
    </EditorAccordion>
  );
};

export default Typography;
