import { sortBy } from "lodash";
import { useQuery } from "react-query";
import { Plan } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getPlans = async (token: string): Promise<Plan[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/plans`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();
  return json.plans;
};

export const usePlansQuery = () => {
  const { data: token } = useIdToken();

  return useQuery(["plans"], () => getPlans(token as string), {
    select: (data) => sortBy(data, "monthlyPagesLimit"),
    enabled: !!token,
  });
};
