import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Fuse from "fuse.js";
import { sumBy } from "lodash";
import { useMemo, useState } from "react";
import { AlertTriangle, CheckCircle, Hash } from "react-feather";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useSetRecoilState } from "recoil";
import { ReactComponent as ArrowRightSquare } from "../../assets/icons/outlined/Arrow-Right-Square.svg";
import { ReactComponent as Delete } from "../../assets/icons/outlined/Delete.svg";
import { ReactComponent as Show } from "../../assets/icons/outlined/Show.svg";
import ButtonSpinner from "../../components/ButtonSpinner";
import GenericDeleteModal from "../../components/GenericDeleteModal";
import { GenericTable } from "../../components/GenericTable";
import { useDeleteApiRequestPayload } from "../../hooks/mutations/useDeleteApiRequestPayload";
import { useLatestDebug } from "../../hooks/queries/useLatestDebug";
import { previewJsonState } from "../Editor/editor.atom";
import DebugModal from "./DebugModal";
import QuickStat from "./QuickStat";

dayjs.extend(utc);

const DebugConsole = () => {
  const {
    mutate: deleteApiRequestPayload,
    isLoading: isDeletingApiRequestPayload,
  } = useDeleteApiRequestPayload();
  const [activeItem, setActiveItem] = useState<any>();
  const [apiRequestPayloadId, setApiRequestPayloadId] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  const setPreviewJson = useSetRecoilState(previewJsonState);
  const dToday = useMemo(() => dayjs().format("YYYY-MM-DD"), []);

  const {
    data: latestDebug = [],
    isFetching: isFetchingLatestDebug,
    refetch: refetchLatestDebug,
  } = useLatestDebug();

  const [searchValue, setSearchValue] = useState("");
  const fuse = useMemo(
    () =>
      new Fuse(latestDebug as any[], {
        keys: ["apiKey", "templateId.id"],
        threshold: 0.2,
      }),
    [latestDebug]
  );

  const filteredData = useMemo(() => {
    return searchValue.length
      ? fuse.search(searchValue).map((entry) => entry.item)
      : latestDebug;
  }, [fuse, latestDebug, searchValue]) as any[];

  const COLUMNS = useMemo(
    () => [
      {
        id: "date",
        Header: "Request time",
        accessor: (originalRow: any) =>
          dayjs(originalRow.requestedAt).format("MMM DD, LT"),
      },
      { id: "templateId", Header: "Template ID", accessor: "templateId" },
      { id: "apiKey", Header: "API Key", accessor: "apiKey" },
      { id: "pages", Header: "Pages", accessor: "pages" },
      {
        id: "actions",
        accessor: (originalRow: any) => originalRow,
        Cell: ({ cell: { value } }: any) => (
          <div className="flex items-center justify-end">
            {value.data && (
              <>
                <Show
                  className="hover:text-gray-900 cursor-pointer mr-4"
                  height={20}
                  width={20}
                  onClick={() => setActiveItem(value)}
                  data-tip="View Payload"
                  data-for="view"
                />
                <ReactTooltip
                  id="view"
                  effect="solid"
                  place="top"
                  type="dark"
                  backgroundColor="rgba(21, 21, 21, .4)"
                  className="font-light"
                />
                <Delete
                  className="hover:text-gray-900 cursor-pointer mr-4"
                  height={20}
                  width={20}
                  onClick={() => setApiRequestPayloadId(value.id)}
                  data-tip="Delete Payload"
                  data-for="delete-payload"
                />
                <ReactTooltip
                  id="delete-payload"
                  effect="solid"
                  place="top"
                  type="dark"
                  backgroundColor="rgba(21, 21, 21, .4)"
                  className="font-light"
                />
              </>
            )}
            <ArrowRightSquare
              className="hover:text-gray-900 cursor-pointer mr-4"
              height={20}
              width={20}
              onClick={() => {
                value.data && setPreviewJson(value.data);
                navigate(`/editor/${value.templateId}`);
              }}
              data-tip="Edit template"
              data-for="edit"
            />

            <ReactTooltip
              id="edit"
              effect="solid"
              place="top"
              type="dark"
              backgroundColor="rgba(21, 21, 21, .4)"
              className="font-light"
            />
          </div>
        ),
      },
    ],
    [setActiveItem, navigate, setPreviewJson]
  );

  return (
    <>
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
        <div className="col-span-1">
          <QuickStat
            icon={<CheckCircle size={20} />}
            label="Successful Requests"
            count={
              latestDebug.filter(
                (entry) =>
                  dayjs(entry.requestedAt).format("YYYY-MM-DD") === dToday &&
                  entry.status === "SUCCESS"
              ).length
            }
            textColor="text-green-500"
            bgColor="bg-green-100"
          />
        </div>
        <div className="col-span-1">
          <QuickStat
            icon={<AlertTriangle size={20} />}
            label="Failed Requests"
            count={
              latestDebug.filter(
                (entry) =>
                  dayjs(entry.requestedAt).format("YYYY-MM-DD") === dToday &&
                  entry.status === "FAILED"
              ).length
            }
            textColor="text-red-500"
            bgColor="bg-red-100"
          />
        </div>
        <div className="col-span-1">
          <QuickStat
            icon={<Hash size={20} />}
            label="Pages Generated Today"
            count={sumBy(
              latestDebug.filter(
                (entry) =>
                  dayjs(entry.requestedAt).format("YYYY-MM-DD") === dToday
              ),
              "pages"
            )}
            textColor="text-blue-500"
            bgColor="bg-blue-100"
          />
        </div>
        <div className="col-span-1 flex justify-end items-start">
          <button
            className="flex items-center text-red-500"
            onClick={() => refetchLatestDebug()}
          >
            <ButtonSpinner
              isVisible={isFetchingLatestDebug}
              textColor="text-red-500"
            />
            <span>Refresh</span>
          </button>
        </div>
      </div>

      <div className="mt-10" />

      <GenericTable
        columns={COLUMNS}
        data={filteredData}
        pSize={25}
        setSearchValue={setSearchValue}
        headerText="Captured Requests"
        headerDescription="Inspect incoming requests so you can debug any issues in real time."
      />

      <DebugModal
        isVisible={Boolean(activeItem)}
        toggle={() => setActiveItem(null)}
        data={activeItem?.data}
      />

      <GenericDeleteModal
        open={Boolean(apiRequestPayloadId)}
        setOpen={() => setApiRequestPayloadId(null)}
        idToDelete={apiRequestPayloadId ?? ""}
        title="Delete API Request Data"
        description="This is a permanent action. Once you delete the payload associated with this API request it cannot be
        recovered."
        action={deleteApiRequestPayload}
        isLoading={isDeletingApiRequestPayload}
      />
    </>
  );
};

export default DebugConsole;
