import { motion, AnimatePresence } from "framer-motion";
import SyncLoader from "react-spinners/SyncLoader";

import { useInvites } from "../../hooks/queries/useInvites";
import { useProfile } from "../../hooks/queries/useProfile";

import { useToggle } from "react-use";
import { Invites } from "./Invites";
import { Create } from "./Create";

const VARIANTS = {
  overlay: {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  },
  content: {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: 300 },
  },
};

const NoOrganizationModal = () => {
  const { data: profile } = useProfile();
  const { data: invites } = useInvites();
  const [isLoading, toggleLoading] = useToggle(false);

  const isVisible = !profile?.organizations?.length || invites?.length;

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="container"
          className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-20"
          initial="closed"
          animate={isVisible ? "open" : "closed"}
          exit="closed"
        >
          <motion.div
            key="overlay"
            className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 z-30 cursor-pointer"
            variants={VARIANTS.overlay}
          />
          <motion.div
            key="content"
            className="z-40"
            variants={VARIANTS.content}
          >
            <div className="bg-white shadow-lg rounded-lg w-full max-w-xl">
              {isLoading ? (
                <div className="h-64 w-full p-16 flex flex-col items-center justify-center">
                  <h1 className="text-lg text-center">
                    Hang tight while your organization is being created
                  </h1>
                  <div className="mx-auto mt-4">
                    <svg
                      className={`animate-spin h-8 w-8 text-red-500`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                </div>
              ) : invites?.length ? (
                <Invites invites={invites} onClose={toggleLoading} />
              ) : (
                <Create onClose={toggleLoading} />
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NoOrganizationModal;
