import ReactTooltip from "react-tooltip";
import {
  Menu,
  List,
  CreditCard,
  Columns,
  Type,
  Box,
  Image,
  Cloud,
} from "react-feather";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { useCreateElement, hoveredElementIdState } from "./editor.atom";
import { TreeItemTypes } from "../../models/editor.model";
import UndoRedo from "./UndoRedo";

const Header = () => {
  const handleCreateElement = useCreateElement();
  const setHoveredId = useSetRecoilState(hoveredElementIdState);

  return (
    <div
      className="w-full h-16 bg-white border-r-2 border-l-2 border-gray-50 flex items-center justify-between px-4"
      onMouseEnter={() => setHoveredId(undefined)}
    >
      <UndoRedo />

      <div className="flex items-center">
        {/* <button className="focus:outline-none mx-4">
          <Plus className="text-gray-500" size={20} />
        </button> */}
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.ROW)}
          data-tip="Row"
          data-for="row"
        >
          <CreditCard className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="row"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.COLUMN)}
          data-tip="Column"
          data-for="column"
        >
          <Columns className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="column"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.BOX)}
          data-tip="Box"
          data-for="box"
        >
          <Box className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="box"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.TEXT)}
          data-tip="Text"
          data-for="text"
        >
          <Type className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="text"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.IMAGE)}
          data-tip="Image"
          data-for="image"
        >
          <Image className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="image"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.TABLE)}
          data-tip="Table"
          data-for="table"
        >
          <Menu className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="table"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.LIST)}
          data-tip="List"
          data-for="list"
        >
          <List className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="list"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        <button
          className="focus:outline-none mx-4"
          onClick={() => handleCreateElement(TreeItemTypes.FLOAT)}
          data-tip="Floating Box"
          data-for="float"
        >
          <Cloud className="text-gray-500" size={20} />
        </button>
        <ReactTooltip
          id="float"
          effect="solid"
          place="bottom"
          type="dark"
          className="font-light"
        />
        {/* <button className="focus:outline-none mx-4">
          <Grid className="text-gray-500" size={20} />
        </button> */}
      </div>

      <div className="w-48"></div>
    </div>
  );
};

export default Header;
