import { useEffect, useMemo, useState } from "react";
import {
  PDFViewer,
  BlobProvider,
  PDFDownloadLink,
  Font,
  Page,
  Document,
} from "@react-pdf/renderer";
import { Document as ViewDocument, Page as ViewPage, pdfjs } from "react-pdf";
import { XCircle } from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { motion, AnimatePresence } from "framer-motion";
import ReactJson from "react-json-view";
import { uniq, map, defer } from "lodash";
import { useDebounce, useToggle } from "react-use";
import {
  isPreviewActiveState,
  editorPagesState,
  previewJsonState,
} from "./editor.atom";

import PDFElement from "./PDFElement";

import fontList from "../../assets/font-list.json";
import Editor, { useMonaco } from "@monaco-editor/react";
import { getTemplateJsonStructure } from "../../utils/preview.util";

const PDFPreview = ({ previewJson }: { previewJson: any }) => {
  const pages = useRecoilValue(editorPagesState);
  const [isReady, toggleIsReady] = useToggle(false);
  const [count, setCount] = useState(1);

  useEffect(() => {
    const pageFontFamilies = pages.map((page) => page.pageSettings.fontFamily);
    const otherFontFamilies = map(
      pages[0].data,
      (page) => page.styles.fontFamily
    );

    uniq([...pageFontFamilies, ...otherFontFamilies]).forEach((family) => {
      const fonts = fontList.find((item) => item.family === family)?.fonts as {
        src: string;
        fontWeight: number;
        fontStyle: string;
      }[];

      Font.register({
        family,
        fonts,
      });
      Font.registerHyphenationCallback((word) => [word]);
    });

    toggleIsReady(true);
  }, []);

  const content = useMemo(() => {
    if (!isReady) return undefined;

    return (
      <Document>
        {pages.map((page, pageIndex) => (
          <Page
            // size="A4"
            size={[595.28 * 1.25, 841.89 * 1.25]}
            wrap
            key={pageIndex}
            style={[
              page.pageSettings,
              // {
              //   position: "absolute",
              //   top: 0,
              //   left: 0,
              //   width: "100vw",
              //   height: "100vh",
              //   backgroundColor: "lightgray",
              // },
            ]}
          >
            <PDFElement
              id="*"
              styles={{}}
              pages={pages}
              pageIndex={pageIndex}
              outsideData={previewJson}
              subelements={page.tree}
              type="view"
            />
          </Page>
        ))}
      </Document>
    );
  }, [pages, previewJson, isReady]);

  if (!isReady || !content) return null;

  return (
    <PDFViewer width="100%" height="100%">
      {content}
    </PDFViewer>
  );
};

const Preview = () => {
  const [isEdited, toggleEdited] = useToggle(false);
  const [pasteJson, setPasteJson] = useState<any>();
  const [debouncedJson, setDebouncedJson] = useState(undefined);

  const pages = useRecoilValue(editorPagesState);
  const [isPreviewActive, togglePreviewActive] =
    useRecoilState(isPreviewActiveState);
  const [previewJson, setPreviewJson] = useRecoilState(previewJsonState);

  useEffect(() => {
    if (!isPreviewActive) return;

    if (pasteJson) {
      toggleEdited(true);
      try {
        const parsed = JSON.parse(pasteJson);
        setPreviewJson(parsed);
      } catch (error) {}
    }

    setPasteJson("");
  }, [pasteJson]);

  useEffect(() => {
    if (!isPreviewActive || isEdited) return;

    setPreviewJson(getTemplateJsonStructure(pages));
  }, [isPreviewActive]);

  const [, cancel] = useDebounce(
    () => {
      if (!isPreviewActive) return;

      setDebouncedJson(previewJson);
    },
    2000,
    [previewJson]
  );

  if (!isPreviewActive) return null;

  return (
    <AnimatePresence>
      {isPreviewActive && (
        <motion.div
          className="fixed flex top-0 left-0 right-0 bottom-0 z-10 items-center justify-center"
          initial="inactive"
          animate="active"
          exit="inactive"
        >
          <motion.a
            className="fixed top-0 left-0 right-0 bottom-0 z-20 bg-gray-900 opacity-20"
            onClick={() => togglePreviewActive(false)}
            variants={{ active: { opacity: 0.5 }, inactive: { opacity: 0 } }}
          />
          <motion.div
            className="relative shadow-xl z-30 bg-white flex flex-col h-90vh w-90vw rounded-lg"
            variants={{
              active: { y: 0, opacity: 1 },
              inactive: { y: "50%", opacity: 0 },
            }}
          >
            <div className="w-full h-12 bg-gray-50 rounded-t-md flex justify-between items-center px-4">
              <span className="text-gray-500">Preview template</span>
              <button onClick={() => togglePreviewActive(false)}>
                <XCircle size={24} className="text-gray-500" />
              </button>
            </div>
            <div className="flex flex-1 p-4">
              <div className="flex-1 flex flex-col mr-2">
                <Editor
                  height="100%"
                  defaultLanguage="json"
                  defaultValue={
                    previewJson ? JSON.stringify(previewJson, null, 2) : "{}"
                  }
                  theme="light"
                  onChange={(value) => {
                    if (!value) return;
                    toggleEdited(true);

                    try {
                      const parse = JSON.parse(value);
                      setPreviewJson(parse);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </div>

              <div className="flex-1 ml-2">
                <PDFPreview previewJson={debouncedJson} />
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Preview;
