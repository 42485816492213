import { useMemo, useState, Fragment, useEffect } from "react";
import { useProfile } from "../../hooks/queries/useProfile";
import { useRemoveShowWelcome } from "../../hooks/mutations/useRemoveShowWelcome";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Dialog, Transition } from '@headlessui/react'

const WelcomeModal = () => {
  const { data: profile } = useProfile();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const isVisible = useMemo(
    () => profile && organizationId,
    [profile, organizationId]
  );
  const { mutate: removeShowWelcome } = useRemoveShowWelcome();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isVisible && profile?.isShowWelcome) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, []);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-90"
          onClose={() => { setIsOpen(false); removeShowWelcome() }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:max-w-4xl sm:align-middle p-6 my-8 transition-all transform bg-white shadow-xl rounded-md">
                <div>
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    How to get started (in under 90 seconds)
                  </Dialog.Title>
                  <div className="relative mt-4" style={{ paddingTop: "56.25%" }}>
                    <iframe src="https://www.loom.com/embed/a760a8ec0afd49c88f6def84642fdd0f" className="absolute inset-0 w-full h-full" frameBorder="0"></iframe>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default WelcomeModal;