import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { ApiKey } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const addApiKeyQuery = async (
  token: string,
  organizationId: string | undefined,
  label: string
): Promise<ApiKey> => {
  if (!organizationId) {
    throw Error("No active organization");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/api-keys`,
    {
      method: "POST",
      body: JSON.stringify({
        label,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.apiKey;
};

export const useAddApiKey = () => {
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const { data: token } = useIdToken();
  const queryClient = useQueryClient();

  return useMutation(
    (label: string) => addApiKeyQuery(token as string, organizationId, label),
    {
      onSuccess: (data) => {
        toast.success("Successfully added new API key");
        queryClient.invalidateQueries("api-keys");
      },
      onError: () => {
        toast.success("Something bad happened!");
      },
    }
  );
};
