import { useMutation, useQueryClient } from "react-query";
import { useProfile } from "../queries/useProfile";
import { Organization, Profile } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";

const handleCreateOrganization = async (
  token: string,
  profile: Profile | undefined,
  organizationName: string
): Promise<Organization> => {
  if (!profile) {
    throw Error("Not properly logged in.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: organizationName,
        ownerId: profile.id,
      }),
    }
  );

  const json = await response.json();
  return json.organization;
};

export const useCreateOrganization = () => {
  const { data: token } = useIdToken();
  const { data: profile } = useProfile();
  const [organizationId, setOrganizationId] = useRecoilState(
    activeOrganizationIdState
  );
  const queryClient = useQueryClient();

  return useMutation(
    ({ organizationName }: { organizationName: string }) =>
      handleCreateOrganization(token as string, profile, organizationName),
    {
      onSuccess: (data) => {
        toast.success("Successfully created organization!");
        queryClient.invalidateQueries("profile");
        queryClient.invalidateQueries("organization");
        setOrganizationId(data.id);
        sessionStorage.setItem("activeOrganizationId", data.id);
      },
      onError: () => {
        toast.error("Something bad happened!");
      },
    }
  );
};
