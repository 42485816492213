import { useForm } from "react-hook-form";
import { useCreateOrganization } from "../../hooks/mutations/useCreateOrganization";

interface CreateProps {
  onClose: () => void;
}

export const Create = ({ onClose }: CreateProps) => {
  const { mutate: mutateCreateOrganization } = useCreateOrganization();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const nameStatus = watch("name");

  const onSubmit = (values: { name: string }) => {
    mutateCreateOrganization({
      organizationName: values.name,
    });
    onClose();
  };

  return (
    <>
      <div className="h-14 px-4 bg-gray-100 flex items-center justify-between rounded-t-lg">
        <span className="text-gray-700">Create an organization</span>
      </div>
      <div className="px-4 py-4 font-light text-sm">
        <p className="text-lg">
          Organizations help you seperate projects, billing, and users. At least
          one is required.
        </p>
        <form
          className="grid grid-cols-4 gap-x-4 mt-4 w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Organization name
            </label>
            <input
              type="text"
              className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
              {...register("name", {
                required: {
                  value: true,
                  message: "Organization name is required.",
                },
              })}
            />
          </div>
          <div className="col-span-1 self-end">
            <button
              type="submit"
              className={`inline-flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                nameStatus.length
                  ? "bg-red-500 hover:bg-red-400"
                  : "bg-gray-300 hover:bg-gray-400"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200`}
              disabled={!nameStatus.length || isSubmitting}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
