import { useMutation } from "react-query";
import { useAuth } from "reactfire";
import { Auth, sendEmailVerification, User } from "@firebase/auth";
import toast from "react-hot-toast";

const sendVerificationEmail = async (auth: Auth) => {
  await sendEmailVerification(auth.currentUser as User, {
    url: process.env.REACT_APP_URL as string,
  });
};

export const useSendEmailVerification = () => {
  const auth = useAuth();

  return useMutation(() => sendVerificationEmail(auth), {
    onSuccess: () => {
      toast.success("Sent email verification!");
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
};
