import { Info, Lock } from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { set } from "monolite";

import EditorButton from "../../../components/EditorButton";
import TBLRGrid from "../../../components/TBLRGrid";
import EditorColorPicker from "../../../components/EditorColorPicker";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";
import { useToggle } from "react-use";
import { useState } from "react";
import { useUpdateElementStyles } from "../../../hooks/custom/useUpdateElementStyles";

enum HelpItems {
  BG_COLOR = "BG_COLOR",
  BR_COLOR = "BR_COLOR",
  BR_WIDTH = "BR_WIDTH",
}

const Color = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  const updateStyles = useUpdateElementStyles(activeElementId);
  const [isBorderHorizontalLocked, toggleBorderHorizontalLocked] =
    useToggle(false);
  const [isBorderVerticalLocked, toggleBorderVerticalLocked] = useToggle(false);

  if (!activeElementId) return null;

  return (
    <EditorAccordion label="Color">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Background color</span>
          <EditorInfo helpLabel="Background Color" />
        </div>

        <EditorColorPicker
          value={
            elementData?.styles?.backgroundColor
              ? elementData?.styles?.backgroundColor.replace("#", "")
              : ""
          }
          onChange={(value) =>
            // updateElementData(
            //   set(elementData, ["styles", "backgroundColor"], value === 'transparent' ? value :`#${value}`)
            // )
            updateStyles(
              ["styles", "backgroundColor"],
              value !== "transparent" ? `#${value}` : value
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Border color</span>
          <EditorInfo helpLabel="Border Color" />
        </div>

        <EditorColorPicker
          value={
            elementData?.styles?.borderColor
              ? elementData?.styles?.borderColor.replace("#", "")
              : ""
          }
          onChange={(value) =>
            // updateElementData(
            //   set(elementData, ["styles", "borderColor"], value === 'transparent' ? value :`#${value}`)
            // )
            updateStyles(
              ["styles", "borderColor"],
              value !== "transparent" ? `#${value}` : value
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Border width</span>
          <EditorInfo helpLabel="Border Width" />
        </div>

        <div className="flex mb-6">
          <EditorButton
            iconComponent={<Lock className="text-gray-600" size={12} />}
            label="Lock horizontal"
            isSelected={isBorderHorizontalLocked}
            onClick={toggleBorderHorizontalLocked}
          />
          <EditorButton
            iconComponent={<Lock className="text-gray-600" size={12} />}
            label="Lock vertical"
            isSelected={isBorderVerticalLocked}
            onClick={toggleBorderVerticalLocked}
          />
        </div>

        <TBLRGrid
          topValue={elementData.styles.borderTopWidth}
          leftValue={elementData.styles.borderLeftWidth}
          rightValue={elementData.styles.borderRightWidth}
          bottomValue={elementData.styles.borderBottomWidth}
          handleTop={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(
            //     elementData,
            //     ["styles", "borderTopWidth"],
            //     value
            //   );
            //   if (isBorderVerticalLocked) {
            //     update = set(update, ["styles", "borderBottomWidth"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "borderTopWidth"],
                isBorderVerticalLocked
                  ? ["styles", "borderBottomWidth"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleLeft={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(
            //     elementData,
            //     ["styles", "borderLeftWidth"],
            //     value
            //   );
            //   if (isBorderHorizontalLocked) {
            //     update = set(update, ["styles", "borderRightWidth"], value);
            //   }
            //   return update;
            // })

            updateStyles(
              [
                ["styles", "borderLeftWidth"],
                isBorderHorizontalLocked
                  ? ["styles", "borderRightWidth"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleRight={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(
            //     elementData,
            //     ["styles", "borderRightWidth"],
            //     value
            //   );
            //   if (isBorderHorizontalLocked) {
            //     update = set(update, ["styles", "borderLeftWidth"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "borderRightWidth"],
                isBorderHorizontalLocked
                  ? ["styles", "borderLeftWidth"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleBottom={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(
            //     elementData,
            //     ["styles", "borderBottomWidth"],
            //     value
            //   );
            //   if (isBorderVerticalLocked) {
            //     update = set(update, ["styles", "borderTopWidth"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "borderBottomWidth"],
                isBorderVerticalLocked
                  ? ["styles", "borderTopWidth"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
        />
      </div>
    </EditorAccordion>
  );
};

export default Color;
