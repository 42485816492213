import { defer } from "lodash";
import { useRef } from "react";
import InputNumber from "../InputNumber";

interface TBLRGridProps {
  topValue?: number;
  leftValue?: number;
  rightValue?: number;
  bottomValue?: number;

  handleTop?: (value: number | undefined) => void;
  handleLeft?: (value: number | undefined) => void;
  handleRight?: (value: number | undefined) => void;
  handleBottom?: (value: number | undefined) => void;
}

const TBLRGrid = ({
  topValue,
  leftValue,
  rightValue,
  bottomValue,

  handleTop,
  handleLeft,
  handleRight,
  handleBottom,
}: TBLRGridProps) => {
  const topRef = useRef<any>(null);
  const leftRef = useRef<any>(null);
  const rightRef = useRef<any>(null);
  const bottomRef = useRef<any>(null);

  return (
    <div>
      <div className="flex items-center justify-center">
        <InputNumber
          value={topValue ?? ""}
          onChange={handleTop}
          ref={topRef}
          onTab={() => defer(() => leftRef?.current?.focus())}
        />
      </div>
      <div className="flex items-center justify-center my-2">
        <InputNumber
          value={leftValue ?? ""}
          onChange={handleLeft}
          ref={leftRef}
          onTab={() => defer(() => rightRef?.current?.focus())}
        />
        <div className="w-24 h-12 p-2 mx-2 border border-gray-100 rounded-md">
          <div className="h-full w-full bg-gray-100 rounded-md"></div>
        </div>
        <InputNumber
          value={rightValue ?? ""}
          onChange={handleRight}
          ref={rightRef}
          onTab={() => defer(() => bottomRef?.current?.focus())}
        />
      </div>
      <div className="flex items-center justify-center">
        <InputNumber
          value={bottomValue ?? ""}
          onChange={handleBottom}
          ref={bottomRef}
          onTab={() => defer(() => topRef?.current?.focus())}
        />
      </div>
    </div>
  );
};

export default TBLRGrid;
