import { useMutation, useQueryClient } from "react-query";
import { useFirestore, useUser } from "reactfire";
import { deleteDoc, doc, Firestore } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";

const handleDeleteTemplate = async (
  token: string,
  organizationId: string | undefined,
  templateId: string
): Promise<boolean> => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/templates/${templateId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.success;
};

export const useDeleteTemplate = () => {
  const user = useUser();
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    (templateId: string) =>
      handleDeleteTemplate(token as string, organizationId, templateId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("template");
        queryClient.invalidateQueries("templates");
      },
    }
  );
};
