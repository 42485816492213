import { Menu, Crosshair } from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { set } from "monolite";

import EditorButton from "../../../components/EditorButton";
import TBLRGrid from "../../../components/TBLRGrid";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";
import { useUpdateElementStyles } from "../../../hooks/custom/useUpdateElementStyles";

const Position = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );
  const updateStyles = useUpdateElementStyles(activeElementId);

  if (!activeElementId) return null;

  return (
    <EditorAccordion label="Position">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Positioning</span>
          <EditorInfo helpLabel="Positioning" />
        </div>

        <div className="flex mb-2">
          <EditorButton
            iconComponent={<Menu className="text-gray-600" size={12} />}
            label="Relative"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "position"], "relative")
              // )
              updateStyles(["styles", "position"], "relative")
            }
            isSelected={elementData.styles.position === "relative"}
          />
          <EditorButton
            iconComponent={<Crosshair className="text-gray-600" size={12} />}
            label="Absolute"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "position"], "absolute")
              // )
              updateStyles(["styles", "position"], "absolute")
            }
            isSelected={elementData.styles.position === "absolute"}
          />
        </div>

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Offset</span>
          <EditorInfo helpLabel="Offset" />
        </div>

        <TBLRGrid
          topValue={elementData.styles.top}
          leftValue={elementData.styles.left}
          rightValue={elementData.styles.right}
          bottomValue={elementData.styles.bottom}
          handleTop={(value) =>
            // updateElementData((elementData: any) =>
            //   set(elementData, ["styles", "top"], value)
            // )
            updateStyles(["styles", "top"], value)
          }
          handleLeft={(value) =>
            // updateElementData((elementData: any) =>
            //   set(elementData, ["styles", "left"], value)
            // )
            updateStyles(["styles", "left"], value)
          }
          handleRight={(value) =>
            // updateElementData((elementData: any) =>
            //   set(elementData, ["styles", "right"], value)
            // )
            updateStyles(["styles", "right"], value)
          }
          handleBottom={(value) =>
            // updateElementData((elementData: any) =>
            //   set(elementData, ["styles", "bottom"], value)
            // )
            updateStyles(["styles", "bottom"], value)
          }
        />
      </div>
    </EditorAccordion>
  );
};

export default Position;
