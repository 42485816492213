import { Trash } from "react-feather";
import { useToggle } from "react-use";
import GenericDeleteModal from "../../components/GenericDeleteModal";
import { useDeleteApiKey } from "../../hooks/mutations/useDeleteAPIKey";

interface KeyItemProps {
  id: string;
  label: string;
}

export const KeyItem = ({ id, label }: KeyItemProps) => {
  const { mutate, isLoading } = useDeleteApiKey();
  const [isVisible, setIsVisible] = useToggle(false);
  return (
    <div
      key={id}
      className="w-full rounded-md py-2 px-4 border-b border-gray-100"
    >
      <div className="grid grid-cols-3 text-sm text-gray-500">
        <div className="col-span-2">{label}</div>
        <div className="col-span-1 flex justify-end">
          <button
            className="focus:outline-none text-gray-500 hover:text-red-500"
            onClick={() => setIsVisible(true)}
          >
            <Trash size={16} />
          </button>
        </div>
      </div>
      <GenericDeleteModal
        open={isVisible}
        setOpen={setIsVisible}
        idToDelete={id}
        title="Delete API Key"
        description="This is a permanent action. Once you delete your API Key, all
        associated applications will no longer function."
        action={mutate}
        isLoading={isLoading}
      />
    </div>
  );
};
