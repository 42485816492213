import { useMutation, useQueryClient } from "react-query";
import { useFirestore, useUser } from "reactfire";
import { omit } from "lodash";
import dayjs from "dayjs";
import { addDoc, collection, Firestore } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Template } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const handleCopyTemplate = async (
  token: string,
  organizationId: string | undefined,
  template: Template
): Promise<Template> => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/templates`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: `${template.name} copy`,
        pages: template.pages,
      }),
    }
  );

  const json = await response.json();
  return json.template;
};

export const useCopyTemplate = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    (template: any) =>
      handleCopyTemplate(token as string, organizationId, template),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("template");
        queryClient.invalidateQueries("templates");
      },
    }
  );
};
