import { useMutation } from 'react-query';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';

const handleLogin = async (email: string, password: string) => {
  if (!email || !password) {
    return;
  }

  const auth = getAuth();
  await setPersistence(auth, browserLocalPersistence);
  await signInWithEmailAndPassword(auth, email, password);
};

export const useLogin = () => {
  return useMutation(({ email, password }: any) =>
    handleLogin(email, password)
  );
};
