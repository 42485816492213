import { FormEvent, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useUser } from "reactfire";
import { GenericButton } from "../../components/GenericButton";
import { useDeleteAccount } from "../../hooks/mutations/useDeleteAccount";
import { useUpdatePassword } from "../../hooks/mutations/useUpdatePassword";
import { useUpdateProfile } from "../../hooks/mutations/useUpdateProfile";
import { useUpdateUserDisplayName } from "../../hooks/mutations/useUpdateUserDisplayName";
import { useUpdateUserEmail } from "../../hooks/mutations/useUpdateUserEmail";
import { useProfile } from "../../hooks/queries/useProfile";

const PersonalForm = () => {
  const { data: user } = useUser();
  const { data: profile } = useProfile();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const { mutateAsync: mutateProfile, isLoading: isLoadingProfile } =
    useUpdateProfile();
  const {
    mutateAsync: mututeUserDisplayName,
    isLoading: isLoadingDisplayName,
  } = useUpdateUserDisplayName();
  const { mutateAsync: mututeUserEmail, isLoading: isLoadingEmail } =
    useUpdateUserEmail();

  const isSubmitting = useMemo(
    () => isLoadingProfile || isLoadingDisplayName || isLoadingEmail,
    [isLoadingProfile, isLoadingDisplayName, isLoadingEmail]
  );

  const onSubmit = async (data: any) => {
    const displayName = `${data.firstName} ${data.lastName}`;
    try {
      if (data.email !== user?.email) {
        await mututeUserEmail(data.email);
      }
      if (displayName !== user?.displayName) {
        await mututeUserDisplayName(displayName);
      }
      await mutateProfile(data);
      toast.success("Successfully saved personal information!");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="border overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label className="block text-sm font-medium text-gray-700">
                First name
              </label>
              <input
                type="text"
                autoComplete="given-name"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "First name is required.",
                  },
                })}
                defaultValue={profile?.firstName}
              />
              {errors.firstName && (
                <span className="block mt-2 text-xs text-red-500">
                  {errors.firstName.message}
                </span>
              )}
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label className="block text-sm font-medium text-gray-700">
                Last name
              </label>
              <input
                type="text"
                autoComplete="family-name"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "Last name is required.",
                  },
                })}
                defaultValue={profile?.lastName}
              />
              {errors.lastName && (
                <span className="block mt-2 text-xs text-red-500">
                  {errors.lastName.message}
                </span>
              )}
            </div>

            <div className="col-span-6 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700">
                <span className="mr-2">Email address</span>
                {!user?.emailVerified ? (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-500">
                    Not verified
                  </span>
                ) : (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-500">
                    Verified
                  </span>
                )}
              </label>
              <input
                type="text"
                autoComplete="email"
                placeholder="Ex. steve.smith@example.com"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email is required.",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Entered value does not match email format",
                  },
                })}
                defaultValue={profile?.email}
              />
              {errors.email && (
                <span className="block mt-2 text-xs text-red-500">
                  {errors.email.message}
                </span>
              )}
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label className="block text-sm font-medium text-gray-700">
                Country / Region
              </label>
              <select
                autoComplete="country"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-red-200 focus:border-red-200 sm:text-sm"
                {...register("country", {
                  required: false,
                })}
                defaultValue={profile?.country}
              >
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
                <option>United Kingdom</option>
                <option>France</option>
                <option>Spain</option>
                <option>Germany</option>
                <option>Netherlands</option>
                <option>Other</option>
              </select>
            </div>

            <div className="col-span-6">
              <label className="block text-sm font-medium text-gray-700">
                Company name
              </label>
              <input
                type="text"
                autoComplete="company-name"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                {...register("company", {
                  required: false,
                })}
                defaultValue={profile?.company}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center px-4 py-3 bg-gray-50 text-right sm:px-6">
          <GenericButton
            type="submit"
            disabled={!isDirty}
            isLoading={isSubmitting}
          >
            Save
          </GenericButton>
        </div>
      </div>
    </form>
  );
};

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { mutate: updatePassword, isLoading } = useUpdatePassword();

  const disableSave =
    !currentPassword || !confirmPassword || confirmPassword !== newPassword;

  const onSubmit = useCallback(
    (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      updatePassword(
        { currentPassword, newPassword },
        {
          onSuccess: () => {
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
          },
        }
      );
    },
    [currentPassword, newPassword, updatePassword]
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="border overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700">
                Current password
              </label>
              <input
                type="password"
                name="password"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                value={currentPassword}
                onChange={(evt) => setCurrentPassword(evt.target.value)}
              />
            </div>

            <div className="col-span-6 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700">
                New password
              </label>
              <input
                type="password"
                name="password"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                value={newPassword}
                onChange={(evt) => setNewPassword(evt.target.value)}
              />
            </div>

            <div className="col-span-6 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700">
                Confirm new password
              </label>
              <input
                type="password"
                name="confirmPassword"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                value={confirmPassword}
                onChange={(evt) => setConfirmPassword(evt.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 flex justify-end items-center sm:px-6">
          <GenericButton
            type="submit"
            disabled={disableSave}
            isLoading={isLoading}
          >
            Save
          </GenericButton>
        </div>
      </div>
    </form>
  );
};

const DeleteAccountForm = () => {
  const [deleteConfirm, setDeleteConfirm] = useState("");
  const { mutate, isLoading } = useDeleteAccount();

  const onSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (deleteConfirm === "delete") {
      mutate();
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="border overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label className="block text-sm font-medium text-gray-700">
                Type 'delete' and click Delete account
              </label>
              <input
                type="text"
                name="phrase"
                id="phrase"
                autoComplete="family-name"
                className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                value={deleteConfirm}
                onChange={(evt) => setDeleteConfirm(evt.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <GenericButton
            type="submit"
            disabled={deleteConfirm !== "delete"}
            isLoading={isLoading}
          >
            Delete account
          </GenericButton>
        </div>
      </div>
    </form>
  );
};

const Account = () => {
  const { data: user } = useUser();
  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Use a real name so we know what to call you.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <PersonalForm />
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      {/* Change Password */}

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Change Password
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Change your password to something easy to guess.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <ChangePasswordForm />
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      {/* Delete Account */}

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Delete Account
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                This is a permanent action and be cannot be undone. If you are
                the account owner, this will also delete the account.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <DeleteAccountForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
