import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { GenericButton } from "../../components/GenericButton";
import { useHandlePortal } from "../../hooks/mutations/useHandlePortal";
import { useOrganization } from "../../hooks/queries/useOrganization";
import { useOrganizationSubscription } from "../../hooks/queries/useOrganizationSubscription";

const Manage = () => {
  const { mutate, isLoading } = useHandlePortal();
  const { data: organization } = useOrganization();
  const { data: subscription } = useOrganizationSubscription();

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Manage subscription{" "}
                {isLoading && (
                  <SyncLoader
                    color="rgba(248, 113, 113, var(--tw-text-opacity))"
                    size={4}
                  />
                )}
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Changes to your payment method will be applied immediately.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <GenericButton isLoading={isLoading} onClick={() => mutate()}>
              Update plan
            </GenericButton>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className=" py-16">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Cancel subscription
              </h3>
              {subscription?.endOfPeriodCancel
                ? `The subscription associated with this account is already set to cancel on ${dayjs(
                    subscription?.stripeCancelledAt
                  ).format("MMM DD")}.`
                : "This will automatically downgrade your account to our free sandbox plan at the end of the billing period."}
              <p className="mt-1 text-sm text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <GenericButton
              onClick={() => null}
              disabled={subscription?.endOfPeriodCancel}
            >
              Cancel Subscription
            </GenericButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manage;
