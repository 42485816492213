import { useQuery } from "react-query";
import { useUser } from "reactfire";
import { getIdToken, User } from "@firebase/auth";

const fetchIdToken = async (user: User) => {
  return await getIdToken(user, true);
};

export const useIdToken = () => {
  const { data: user } = useUser();

  return useQuery(["idToken"], () => fetchIdToken(user as User), {
    enabled: !!user,
    refetchInterval: 3600000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    staleTime: 3600000, // token expires every hour, set stale time to 1 hour
  });
};
