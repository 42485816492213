import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import {
  elementPageSettingsState,
  elementsLayoutState,
  elementsState,
  flatElementIdsState,
  globalZoomState,
  hoveredElementIdState,
  isUserActionState,
} from "../editor.atom";
import { throttle, keys } from "lodash";

import ActiveHelper from "../ActiveHelper";
import AddElement from "../AddElement";
import FloatingGrid from "../FloatingGrid";

import OverlayElement from "./OverlayElement";
import { adjustOverlayByZoom, getTopOffset } from "../../../utils/editor.util";
import { useRecoilCallback } from "recoil";

const Overlay = () => {
  const elements = useRecoilValue(elementsState);
  const pageSettings = useRecoilValue(elementPageSettingsState);
  const globalZoom = useRecoilValue(globalZoomState);

  const simulateHover = useRecoilCallback(
    ({ set, snapshot }) =>
      async (offsetX: number, offsetY: number) => {
        const [isUserAction, elementLayouts] = await Promise.all([
          snapshot.getPromise(isUserActionState),
          snapshot.getPromise(elementsLayoutState),
        ]);

        if (isUserAction) return;
        const topOffset = getTopOffset();

        const possibleTargets = keys(elementLayouts)
          .filter((elementId) => {
            if (elementId === "*") return false;

            const elementLayout = elementLayouts[elementId];
            if (!elementLayout) return false;

            const top = elementLayout.top - topOffset;
            const height = elementLayout.top + elementLayout.height - topOffset;

            return (
              offsetX > elementLayout.left &&
              offsetX < elementLayout.left + elementLayout.width &&
              offsetY > top &&
              offsetY < height &&
              Math.abs(offsetX - elementLayout.left) +
                Math.abs(offsetX - elementLayout.left + elementLayout.width) +
                Math.abs(offsetY - top) +
                Math.abs(offsetY - height)
            );
          })
          .filter(Boolean)
          .sort();

        set(
          hoveredElementIdState,
          possibleTargets.length
            ? possibleTargets[possibleTargets.length - 1]
            : undefined
        );
      },
    []
  );

  const throttleMouseOver = useCallback(
    throttle(
      (evt) => simulateHover(evt.nativeEvent.x, evt.nativeEvent.y),
      // (evt) => console.log(evt.nativeEvent.x, evt.nativeEvent.y),
      100
    ),
    []
  );

  if (!globalZoom.zoomRatio) {
    return null;
  }

  return (
    <div
      className="absolute bg-transparent"
      style={{
        top: 40,
        left: 40,
        width: globalZoom.pdfWidth * globalZoom.zoomRatio,
        height: globalZoom.pdfHeight * globalZoom.zoomRatio,
      }}
      onMouseMove={throttleMouseOver}
    >
      <FloatingGrid />
      <OverlayElement
        id="*"
        styles={[
          {
            width: "100%",
            height: "100%",
          },
          adjustOverlayByZoom(pageSettings, globalZoom.zoomRatio),
        ]}
        subelements={elements}
      />

      <ActiveHelper />
      <AddElement />
    </div>
  );
};

export default Overlay;
