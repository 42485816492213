import { ReactNode } from "react";

interface EditorButtonProps {
  iconComponent: ReactNode;
  label: string;
  isSelected?: boolean;
  isLast?: boolean;
  onClick?(): void;
}

const EditorButton = ({
  iconComponent,
  label,
  isSelected = false,
  isLast = false,
  onClick = () => null,
}: EditorButtonProps) => {
  return (
    <button
      className={`flex items-center p-2 ${
        isSelected ? "bg-gray-100" : ""
      } rounded-sm ${isLast ? "" : "mr-2"} my-1`}
      onClick={onClick}
    >
      {iconComponent}
      <span className="text-xs text-gray-600 font-light ml-2">{label}</span>
    </button>
  );
};

export default EditorButton;
