import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from "react-query";
import { useAddUserToOrg } from "./useAddUsertoOrg";
import { SetterOrUpdater, useSetRecoilState } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { User } from "../../models/api.model";
import { useDeclineInvite } from "./useDeclineInvites";
import { useAcceptInvite } from "./useAcceptInvites";

const handleRespondInvites = async (
  accepted: any[],
  rejected: any[],
  acceptInvite: UseMutateAsyncFunction<
    boolean,
    unknown,
    {
      inviteId: string;
      orgId: string;
    },
    unknown
  >,
  declineInvite: UseMutateAsyncFunction<
    boolean,
    unknown,
    {
      inviteId: string;
      orgId: string;
    },
    unknown
  >,
  addUserToOrg: UseMutateAsyncFunction<User, unknown, any, unknown>,
  setActiveOrganizationId: SetterOrUpdater<string | undefined>
) => {
  for (const invite of rejected) {
    await declineInvite({ inviteId: invite.id, orgId: invite.organizationId });
  }

  if (accepted.length) {
    for (const invite of accepted) {
      await addUserToOrg(invite.organizationId);
      await acceptInvite({ inviteId: invite.id, orgId: invite.organizationId });
    }
    setActiveOrganizationId(accepted[0].organizationId);
  }

  return;
};

export const useRespondInvites = () => {
  const setActiveOrganizationId = useSetRecoilState(activeOrganizationIdState);
  const { mutateAsync: declineInvite } = useDeclineInvite();
  const { mutateAsync: acceptInvite } = useAcceptInvite();
  const { mutateAsync: addUserToOrg } = useAddUserToOrg();
  const queryClient = useQueryClient();

  return useMutation(
    ({ accepted, rejected }: any) =>
      handleRespondInvites(
        accepted,
        rejected,
        acceptInvite,
        declineInvite,
        addUserToOrg,
        setActiveOrganizationId
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("invites");
        queryClient.invalidateQueries("profile");
      },
    }
  );
};
