import { useQuery, UseQueryOptions } from "react-query";
import { useUser } from "reactfire";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "./useIdToken";

const getDailyRequests = async (
  token: string,
  organizationId: string | undefined
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/api-requests/by-day`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();

  return json.results;
};

export const useDailyRequests = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useQuery(
    ["api-requests-by-day", organizationId],
    () => {
      return getDailyRequests(token as string, organizationId);
    },
    { enabled: !!organizationId }
  );
};
