import { Eye } from "react-feather";
import { useRecoilCallback } from "recoil";
import {
  activePageState,
  editorPagesState,
  elementPageSettingsState,
  elementsDataState,
  elementsState,
  isPreviewActiveState,
} from "./editor.atom";

const PreviewButton = () => {
  const handlePreview = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const [activePageIndex, pages, elements, elementsData, pageSettings] =
          await Promise.all([
            snapshot.getPromise(activePageState),
            snapshot.getPromise(editorPagesState),
            snapshot.getPromise(elementsState),
            snapshot.getPromise(elementsDataState),
            snapshot.getPromise(elementPageSettingsState),
          ]);

        const updatePages = pages.map((page: any, index: number) =>
          index === activePageIndex
            ? {
                tree: elements,
                data: elementsData,
                pageSettings,
                id: page.id,
                name: page.name,
              }
            : page
        );

        set(editorPagesState, updatePages);
        set(isPreviewActiveState, true);
      },
    []
  );

  return (
    <button
      className="focus:outline-none rounded-full py-2 px-3 border-opacity-10 border-black border-1 bg-red-400 text-white text-sm leading-5 flex items-center"
      onClick={handlePreview}
    >
      <Eye size={16} />
      <span className="ml-1">Preview</span>
    </button>
  );
};

export default PreviewButton;
