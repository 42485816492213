import { CopyToClipboard } from "react-copy-to-clipboard";
import { Clipboard } from "react-feather";
import { motion } from "framer-motion";
import { useToggle } from "react-use";
import { useEffect } from "react";
import { delay } from "lodash";
import ReactTooltip from "react-tooltip";

interface CopyButtonProps {
  value: string;
}

export const CopyButton = ({ value }: CopyButtonProps) => {
  const [isCopied, toggleCopied] = useToggle(false);

  useEffect(() => {
    if (isCopied) {
      delay(() => toggleCopied(false), 400);
    }
  }, [isCopied]);

  return (
    <>
      <CopyToClipboard text={value} onCopy={toggleCopied}>
        <motion.span
          className="ml-2 text-gray-500 cursor-pointer"
          whileHover={{ scale: 1.2 }}
          animate={{ opacity: isCopied ? 0.5 : 1 }}
          data-tip="copy"
          data-for="copy"
        >
          <Clipboard size={16} />
        </motion.span>
      </CopyToClipboard>
      <ReactTooltip
        id="copy"
        effect="solid"
        place="top"
        type="dark"
        backgroundColor="rgba(21, 21, 21, .7)"
        className="font-light"
      />
    </>
  );
};
