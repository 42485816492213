import { useEffect, useState } from "react";
import { Save } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { Portal } from "react-portal";

import { useCreateTemplate } from "../../hooks/mutations/useCreateTemplate";
import { useUpdateTemplatePages } from "../../hooks/mutations/useUpdateTemplatePages";
import { useTemplate } from "../../hooks/queries/useTemplate";
import {
  activePageState,
  editorActiveTemplateId,
  editorPagesState,
  elementPageSettingsState,
  elementsDataState,
  elementsState,
} from "./editor.atom";

import NewTemplateModal from "./NewTemplateModal";

const SaveButton = () => {
  const navigate = useNavigate();
  const [showNewTemplateModal, toggleShowNewtemplateModal] = useToggle(false);
  const [newTemplateName, updateNewTemplateName] = useState("");
  const templateId = useRecoilValue(editorActiveTemplateId);

  const { data: template, isFetched, refetch: refetchTemplate } = useTemplate();
  const { mutate: mutateTemplatePages, isLoading: isLoadingTemplatePages } =
    useUpdateTemplatePages();
  const { mutateAsync: mutateAsyncCreateTemplate } = useCreateTemplate();

  const handleSave = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const [activePageIndex, pages, elements, elementsData, pageSettings] =
          await Promise.all([
            snapshot.getPromise(activePageState),
            snapshot.getPromise(editorPagesState),
            snapshot.getPromise(elementsState),
            snapshot.getPromise(elementsDataState),
            snapshot.getPromise(elementPageSettingsState),
          ]);

        const updatePages = pages.map((page: any, index: number) =>
          index === activePageIndex
            ? { tree: elements, data: elementsData, pageSettings }
            : page
        );

        if (templateId === "new") {
          const savedTemplate = await mutateAsyncCreateTemplate({
            name: newTemplateName,
            pages: updatePages,
          });

          set(editorActiveTemplateId, savedTemplate.id);
          refetchTemplate();
          navigate(`/editor/${savedTemplate.id}`);
        } else {
          mutateTemplatePages({
            templateId,
            pages: updatePages,
          });
        }
      },
    [templateId, newTemplateName]
  );

  useEffect(() => {
    if (templateId === "new") {
      toggleShowNewtemplateModal(true);
    }
  }, [templateId]);

  return (
    <>
      <button
        className="focus:outline-none rounded-full py-2 px-3 border-opacity-10 border-black border-1 bg-blue-500 text-white text-sm leading-5 flex items-center"
        onClick={() =>
          templateId === "new" ? toggleShowNewtemplateModal(true) : handleSave()
        }
      >
        <Save size={16} />
        <span className="ml-1">Save</span>
      </button>

      <Portal>
        <NewTemplateModal
          isVisible={showNewTemplateModal}
          close={toggleShowNewtemplateModal}
          newTemplateName={newTemplateName}
          updateNewTemplateName={updateNewTemplateName}
          handleSave={handleSave}
        />
      </Portal>
    </>
  );
};

export default SaveButton;
