import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";

const updateSubscription = async (
  token: string,
  organizationId: string | undefined,
  priceId: string
) => {
  if (!organizationId) {
    throw Error("No active organization found.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/stripe-change`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId,
      }),
    }
  );

  const json = await response.json();

  return json.subscription;
};

interface UpdateSubscriptionVars {
  priceId: string;
}

export const useUpdateSubscription = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useMutation(({ priceId }: UpdateSubscriptionVars) =>
    updateSubscription(token as string, organizationId, priceId)
  );
};
