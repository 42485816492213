import { range } from "lodash";
import { useQuery } from "react-query";
import { useUser } from "reactfire";
import { Invite } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getInvites = async (token: string, user: any): Promise<Invite[]> => {
  if (!user.data?.email) {
    throw Error("No email found on the account.");
  }

  let response, json;

  for (const interval of range(5)) {
    try {
      response = await fetch(`${process.env.REACT_APP_API_URL}/me/invites`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      json = await response.json();
    } catch (error) {
      await new Promise((resolve, reject) =>
        setInterval(() => resolve(undefined), interval * 1000)
      );
      continue;
    }

    break;
  }

  return json.invites;
};

export const useInvites = () => {
  const user = useUser();
  const { data: token } = useIdToken();

  return useQuery(
    ["invites", user.data?.email],
    () => {
      return getInvites(token as string, user);
    },
    { enabled: !!user.data?.email && !!token }
  );
};
