import { useMutation } from 'react-query';
import { useUser } from 'reactfire';
import { updateProfile, User } from 'firebase/auth';

const updateUser = (user: User, displayName: string) => {
  return updateProfile(user, {
    displayName,
  });
};

export const useUpdateUserDisplayName = () => {
  const user = useUser();

  return useMutation((displayName: string) =>
    updateUser(user.data as User, displayName)
  );
};
