import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Subscription } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getOrganizationSubscription = async (
  token: string,
  organizationId: string | undefined
) => {
  if (!organizationId) {
    return null;
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/subscriptions/active`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  if (json.subscription) {
    return json.subscription as Subscription;
  }
  return null;
};

export const useOrganizationSubscription = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useQuery(
    ["organization-subscription", organizationId],
    () => {
      return getOrganizationSubscription(token as string, organizationId);
    },
    {
      enabled: !!organizationId && !!token,
      select: (data) => findCurrentPeriod(data as Subscription),
      refetchOnWindowFocus: true,
    }
  );
};

const findCurrentPeriod = (data: Subscription) => {
  const newData = {
    ...data,
    usage: data?.usage?.find(
      (usageData) =>
        usageData.startPeriod === data.currentPeriodStart &&
        usageData.endPeriod === data.currentPeriodEnd
    ),
  };
  return newData;
};
