import { useMutation, useQueryClient } from "react-query";
import { useProfile } from "../queries/useProfile";
import { Profile, User } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const deleteUserFromOrg = async (
  token: string,
  profile: Profile | undefined,
  organizationId: string,
  userId: string
): Promise<User> => {
  if (!profile) {
    throw Error("No found profile.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users/${userId}/organizations`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ organizationId }),
    }
  );

  const json = await response.json();
  return json.user;
};

export const useDeleteUserFromOrg = () => {
  const { data: token } = useIdToken();
  const { data: profile } = useProfile();
  const queryClient = useQueryClient();

  return useMutation(
    ({ organizationId, userId }: { organizationId: string; userId: string }) =>
      deleteUserFromOrg(token as string, profile, organizationId, userId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("profile");
        queryClient.invalidateQueries("organization");
      },
    }
  );
};
