export const colors = {
  titleActive: '#4B526C',
  content: '#4B526C',
  tableContent: '#606060',
  label: '#6E7E91',
  line: '#D9DBE9',
  inputBg: '#DEDEDE',
  bg: "#F8F8F8",
  offWhite: '#FFFFFF',

  primaryRed: '#FF735E',
  primaryBlue: '#3F8CFF',
  primaryBlueDk: '#202E83',
  primaryYellow: '#FFCE1F'
};

const theme = {
  colors,
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'Poppins, sans-serif'
  },
  fontWeights: {
    body: 500,
    heading: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [
    12, 14, 17, 20, 24, 32, 48, 64
  ],
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      color: 'content'
    },
    h1: {
      fontSize: 7,
    },
    h2: {
      fontSize: 6,
    },
    h3: {
      fontSize: 5
    },
    h4: {
      fontSize: 4
    },
    hr: {
      opacity: .15
    }
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      bg: 'primaryRed',
      color: 'white',
      outline: 'none'
    }
  },
  text: {
    default: {
      fontFamily: 'body',
      fontSize: 1
    },
    bold: {
      fontWeight: 700
    },
    medium: {
      fontWeight: 500
    },
    sizeL: {
      fontSize: 4,
    },
    sizeM: {
      fontSize: 3,
    },
    sizeS: {
      fontSize: 2,
    },
    sizeXs: {
      fontSize: 1
    },
    sizeXxs: {
      fontSize: 0
    }
  },
  cards: {
    primary: {
      borderRadius: 8,
      padding: 3,
      bg: 'white',
      width: '100%',
    }
  },
  containers: {
    tableHeader: {
      alignItems: 'center',
      borderRadius: 4,
      px: 3,
      py: 2,
      bg: 'bg'
    },
    tableRow: {
      alignItems: 'center',
      px: 3,
      py: 2,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "bg",
      '&:last-child': {
        borderBottomWidth: 0
      }
    }
  },
  forms: {
    input: {
      borderColor: 'inputBg'
    },
    label: {
      fontSize: 0,
      color: 'content',
      opacity: .7
    }
  },
  images: {
    avatar: {
      height: 40,
      width: 40,
      borderRadius: 20
    }
  }
}

export default theme;