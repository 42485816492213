import { uniq, upperCase } from "lodash";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { usedColorsSelector } from "../../layouts/Editor/editor.atom";
import transparent from "./transparent.png";

const DEFAULT_COLORS = [
  "3B82F6",
  "34D399",
  "F87171",
  "6366F1",
  "FBBF24",
  "DC2626",
  "047857",
  "6D28D9",
  "DB2777",
  "FDE68A",
  "1E40AF",
  "F3F4F6",
  "D1D5DB",
  "4B5563",
  "111827",
  "000000",
  "FFFFFF",
];

const QuickColor = ({
  color,
  handler,
  hasBorder,
}: {
  color: string;
  handler(value: string): void;
  hasBorder?: boolean;
}) => {
  return (
    <button
      className={`h-5 w-5 rounded-sm bg-gray-500 mr-2 mb-2 ${
        hasBorder ? "border border-gray-100" : ""
      }`}
      style={{ backgroundColor: `#${color}` }}
      onClick={() => handler(color)}
    ></button>
  );
};

interface EditorColorPickerProps {
  value?: string;
  onChange?(value: string): void;
}

const EditorColorPicker = ({ value, onChange }: EditorColorPickerProps) => {
  const usedColors = useRecoilValue(usedColorsSelector);

  const availableColors = useMemo(
    () =>
      uniq([...DEFAULT_COLORS, ...usedColors.map((c) => c.replace("#", ""))]),
    [usedColors]
  );

  return (
    <div>
      <div className="flex items-center">
        <div
          className="h-5 w-5 rounded-sm bg-gray-500 mr-4"
          style={{
            backgroundColor: value
              ? value !== "transparent"
                ? `#${value}`
                : value
              : undefined,
          }}
        />
        <div className="flex items-center">
          <span className="text-gray-300 mr-1">#</span>
          <input
            className="focus:outline-none placeholder-opacity-20"
            placeholder="FFFFFF"
            value={value}
            onChange={(evt) =>
              onChange && onChange(evt.target.value.toUpperCase())
            }
          />
        </div>
      </div>

      <div className="mb-2 mt-3">
        <span className="text-xs text-gray-400 uppercase">Quick colors</span>
      </div>

      <div className="flex items-center flex-wrap">
        <button
          className="h-5 w-5 rounded-sm mr-2 mb-2 border border-gray-100"
          onClick={() => onChange && onChange("transparent")}
        >
          <img src={transparent} />
        </button>

        {availableColors.map((color) => (
          <QuickColor
            key={color}
            color={color}
            handler={(value) => onChange && onChange(value)}
            hasBorder={color === "FFFFFF"}
          />
        ))}
      </div>
    </div>
  );
};

export default EditorColorPicker;
