import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUpdateOrganization } from "../../hooks/mutations/useUpdateOrganization";
import { pick } from "lodash";

import DeleteOrganizationModal from "./DeleteOrganizationModal";
import { useOrganization } from "../../hooks/queries/useOrganization";
import { GenericButton } from "../../components/GenericButton";
import { useIsOrganizationOwner } from "../../hooks/queries/useIsOrganizationOwner";
import GenericModal from "../../components/GenericModal";
import { useNavigate } from "react-router-dom";
import { useOrganizationSubscription } from "../../hooks/queries/useOrganizationSubscription";

const Organization = () => {
  const { data: organization } = useOrganization();
  const { register, handleSubmit, reset } = useForm();
  const { mutate, isLoading } = useUpdateOrganization();
  const isOrgOwner = useIsOrganizationOwner();
  const { data: subscription } = useOrganizationSubscription();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    reset(pick(organization, ["name", "contactEmail", "organizationName"]));
  }, [organization]);

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Global setting
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                The world revolves around your organization.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form
              onSubmit={handleSubmit((values) => {
                mutate(values);
                // console.log(values);
              })}
            >
              <div className="border overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Organization name
                      </label>
                      <input
                        type="text"
                        autoComplete="organization-name"
                        className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                        {...register("name", { required: true })}
                      />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Email address for alerts
                      </label>
                      <input
                        type="text"
                        autoComplete="email"
                        placeholder="Ex. steve.smith@example.com"
                        className="mt-1 focus:ring-red-200 focus:border-red-200 block w-full sm:text-sm border-gray-300 rounded-md"
                        {...register("contactEmail", { required: false })}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <GenericButton type="submit" isLoading={isLoading}>
                    Save
                  </GenericButton>
                </div>
              </div>
            </form>
          </div>
        </div>
        {isOrgOwner && (
          <>
            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-t border-gray-200"></div>
              </div>
            </div>
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Delete your organization
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Proceeding will delete the organization and all associated
                information. If you have an active subscription, please cancel
                that first before proceeding.
              </p>
              <div className="mt-6">
                <GenericButton type="button" onClick={openModal}>
                  Delete organization
                </GenericButton>
              </div>
            </div>
          </>
        )}
      </div>

      {subscription?.plan.name.toLowerCase() !== "free" ? (
        <GenericModal
          open={isOpen}
          setOpen={openModal}
          title="Redirect to Subscriptions"
          description="You are currently on a paid plan. Please downgrade to the free plan before deleting your organization."
          action={() => navigate("/subscription")}
          actionButtonText="Redirect"
        />
      ) : (
        <DeleteOrganizationModal closeModal={closeModal} isOpen={isOpen} />
      )}
    </>
  );
};

export default Organization;
