import { Info, Minimize, Maximize2, Lock } from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { set } from "monolite";
import { useToggle } from "react-use";

import EditorButton from "../../../components/EditorButton";
import InputNumber from "../../../components/InputNumber";
import InputPercent from "../../../components/InputPercent";
import TBLRGrid from "../../../components/TBLRGrid";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";
import { useState } from "react";
import { useMemo } from "react";
import { useUpdateElementStyles } from "../../../hooks/custom/useUpdateElementStyles";

const AUTO_SPACE = undefined;

const SizeSpace = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );
  const updateStyles = useUpdateElementStyles(activeElementId);

  const isColumn = useMemo(
    () => activeElementId?.includes(":column"),
    [activeElementId]
  );

  const [isMarginHorizontalLocked, toggleMarginHorizontalLocked] =
    useToggle(false);
  const [isMarginVerticalLocked, toggleMarginVerticalLocked] = useToggle(false);
  const [isPaddingHorizontalLocked, togglePaddingHorizontalLocked] =
    useToggle(false);
  const [isPaddingVerticalLocked, togglePaddingVerticalLocked] =
    useToggle(false);

  if (!activeElementId) return null;

  return (
    <EditorAccordion label="Size & Space">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Margin</span>
          <EditorInfo helpLabel="Margin" />
        </div>

        <div className="flex mb-6">
          <EditorButton
            iconComponent={<Lock className="text-gray-600" size={12} />}
            label="Lock horizontal"
            isSelected={isMarginHorizontalLocked}
            onClick={toggleMarginHorizontalLocked}
          />
          <EditorButton
            iconComponent={<Lock className="text-gray-600" size={12} />}
            label="Lock vertical"
            isSelected={isMarginVerticalLocked}
            onClick={toggleMarginVerticalLocked}
          />
        </div>

        <TBLRGrid
          topValue={elementData.styles.marginTop}
          leftValue={elementData.styles.marginLeft}
          rightValue={elementData.styles.marginRight}
          bottomValue={elementData.styles.marginBottom}
          handleTop={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "marginTop"], value);
            //   if (isMarginVerticalLocked) {
            //     update = set(update, ["styles", "marginBottom"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "marginTop"],
                isMarginVerticalLocked ? ["styles", "marginBottom"] : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleLeft={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "marginLeft"], value);
            //   if (isMarginHorizontalLocked) {
            //     update = set(update, ["styles", "marginRight"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "marginLeft"],
                isMarginHorizontalLocked
                  ? ["styles", "marginRight"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleRight={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "marginRight"], value);
            //   if (isMarginHorizontalLocked) {
            //     update = set(update, ["styles", "marginLeft"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "marginRight"],
                isMarginHorizontalLocked ? ["styles", "marginLeft"] : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleBottom={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "marginBottom"], value);
            //   if (isMarginVerticalLocked) {
            //     update = set(update, ["styles", "marginTop"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "marginBottom"],
                isMarginVerticalLocked ? ["styles", "marginTop"] : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Padding</span>
          <EditorInfo helpLabel="Padding" />
        </div>

        <div className="flex mb-6">
          <EditorButton
            iconComponent={<Lock className="text-gray-600" size={12} />}
            label="Lock horizontal"
            isSelected={isPaddingHorizontalLocked}
            onClick={togglePaddingHorizontalLocked}
          />
          <EditorButton
            iconComponent={<Lock className="text-gray-600" size={12} />}
            label="Lock vertical"
            isSelected={isPaddingVerticalLocked}
            onClick={togglePaddingVerticalLocked}
          />
        </div>

        <TBLRGrid
          topValue={elementData.styles.paddingTop}
          leftValue={elementData.styles.paddingLeft}
          rightValue={elementData.styles.paddingRight}
          bottomValue={elementData.styles.paddingBottom}
          handleTop={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "paddingTop"], value);
            //   if (isPaddingVerticalLocked) {
            //     update = set(update, ["styles", "paddingBottom"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "paddingTop"],
                isPaddingVerticalLocked
                  ? ["styles", "paddingBottom"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleLeft={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "paddingLeft"], value);
            //   if (isPaddingHorizontalLocked) {
            //     update = set(update, ["styles", "paddingRight"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "paddingLeft"],
                isPaddingHorizontalLocked
                  ? ["styles", "paddingRight"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleRight={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "paddingRight"], value);
            //   if (isPaddingHorizontalLocked) {
            //     update = set(update, ["styles", "paddingLeft"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "paddingRight"],
                isPaddingHorizontalLocked
                  ? ["styles", "paddingLeft"]
                  : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
          handleBottom={(value) =>
            // updateElementData((elementData: any) => {
            //   let update = set(elementData, ["styles", "paddingBottom"], value);
            //   if (isPaddingVerticalLocked) {
            //     update = set(update, ["styles", "paddingTop"], value);
            //   }
            //   return update;
            // })
            updateStyles(
              [
                ["styles", "paddingBottom"],
                isPaddingVerticalLocked ? ["styles", "paddingTop"] : undefined,
              ].filter(Boolean) as string[][],
              value
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Width</span>
          <EditorInfo helpLabel="Width" />
        </div>

        {isColumn ? (
          <div className="flex items-center">
            <span className="mr-auto text-xs text-gray-600 font-light">
              Column weight
            </span>
            <InputNumber
              value={elementData?.styles?.flex}
              onChange={(value) =>
                // updateElementData(set(elementData, ["styles", "flex"], value))
                updateStyles(["styles", "flex"], value)
              }
            />
          </div>
        ) : (
          <div className="flex items-center">
            <EditorButton
              iconComponent={<Minimize className="text-gray-600" size={12} />}
              label="Auto"
              isSelected={elementData?.styles?.width === AUTO_SPACE}
              onClick={() =>
                // updateElementData(
                //   set(elementData, ["styles", "width"], AUTO_SPACE)
                // )
                updateStyles(["styles", "width"], AUTO_SPACE)
              }
            />
            <EditorButton
              iconComponent={<Maximize2 className="text-gray-600" size={12} />}
              label="100%"
              isSelected={elementData?.styles?.width === "100%"}
              onClick={() =>
                // updateElementData(set(elementData, ["styles", "width"], "100%"))
                updateStyles(["styles", "width"], "100%")
              }
            />

            <span className="ml-auto mr-4">or</span>
            <InputNumber
              value={
                elementData?.styles?.width &&
                elementData?.styles?.width !== AUTO_SPACE &&
                elementData?.styles?.width !== "100%"
                  ? elementData?.styles?.width
                  : ""
              }
              onChange={(value) =>
                // updateElementData(set(elementData, ["styles", "width"], value))
                updateStyles(["styles", "width"], value)
              }
            />
          </div>
        )}

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Height</span>
          <EditorInfo helpLabel="Height" />
        </div>

        <div className="flex items-center">
          <EditorButton
            iconComponent={<Minimize className="text-gray-600" size={12} />}
            label="Auto"
            isSelected={elementData?.styles?.height === AUTO_SPACE}
            onClick={() =>
              // updateElementData(
              //   set(elementData, ["styles", "height"], AUTO_SPACE)
              // )
              updateStyles(["styles", "height"], AUTO_SPACE)
            }
          />
          <EditorButton
            iconComponent={<Maximize2 className="text-gray-600" size={12} />}
            label="100%"
            isSelected={elementData?.styles?.height === "100%"}
            onClick={() =>
              // updateElementData(set(elementData, ["styles", "height"], "100%"))
              updateStyles(["styles", "height"], "100%")
            }
          />

          <span className="ml-auto mr-4">or</span>
          <InputNumber
            value={
              elementData?.styles?.height &&
              elementData?.styles?.height !== AUTO_SPACE &&
              elementData?.styles?.height !== "100%"
                ? elementData?.styles?.height
                : ""
            }
            onChange={(value) =>
              // updateElementData(set(elementData, ["styles", "height"], value))
              updateStyles(["styles", "height"], value)
            }
          />
          {/* <InputPercent
            value={
              elementData?.styles?.height &&
              elementData?.styles?.height !== AUTO_SPACE &&
              elementData?.styles?.height?.includes("%")
                ? elementData?.styles?.height
                : ""
            }
            onChange={(value) =>
              updateElementData(set(elementData, ["styles", "height"], value))
            }
          /> */}
        </div>
      </div>
    </EditorAccordion>
  );
};

export default SizeSpace;
