import { Dispatch, SetStateAction } from "react";
import { useTable, usePagination } from "react-table";
import { SearchInput } from "../SearchInput";
import { ReactComponent as NotFound } from "../../assets/Not-Found.svg";
import Pagination from "../Pagination";
import { some } from "lodash";
// import { Pagination, Popconfirm } from 'antd';
// import { QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
  columns: any;
  headerText?: string;
  headerDescription?: string;
  setSearchValue?: Dispatch<SetStateAction<string>>;
  data?: any[];
  pSize?: number;
}

export const GenericTable = ({
  columns,
  headerText,
  headerDescription,
  setSearchValue,
  data = [],
  pSize = 5,
}: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pSize } as any,
    },
    usePagination
  ) as any;

  return (
    <div className="flex flex-col">
      {some([headerText, headerDescription, setSearchValue]) ? (
        <div className="flex flex-row justify-between items-center min-w-max pb-2">
          <div>
            {!!headerText && <h3 className="font-normal">{headerText}</h3>}
            {!!headerDescription && (
              <span className="text-sm font-light">{headerDescription}</span>
            )}
          </div>
          {!!setSearchValue && <SearchInput onChangeText={setSearchValue} />}
        </div>
      ) : null}

      <div className="-my-2 sm:-mx-6 lg:-mx-8 pt-2">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-gray-800 text-white">
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup: any) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column: any) => (
                          // Apply the header cell props
                          <th
                            {...column.getHeaderProps()}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                          >
                            {
                              // Render the header
                              column.render("Header")
                            }
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {page.length ? (
                  // Loop over the table rows
                  page.map((row: any) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map((cell: any) => {
                            // Apply the cell props
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 text-gray-600 text-sm font-normal whitespace-nowrap"
                              >
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={headerGroups[0].headers.length}
                      className="text-center p-6 text-gray-600 font-bold text-xl"
                    >
                      <NotFound className="mx-auto mb-5" />
                      <div>No Data Found</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {data.length > pSize ? (
        <div className="flex justify-start mt-3">
          <Pagination
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
          />
        </div>
      ) : null}
    </div>
  );
};
