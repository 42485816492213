import { useEffect } from "react";
import { useUser } from "reactfire";
import { useAddScriptToHead } from "../../hooks/custom/useAnalytics";
import { useLogout } from "../../hooks/mutations/useLogout";
import { useSendEmailVerification } from "../../hooks/mutations/useSendEmailVerification";
import { GenericButton } from "../GenericButton";

export const VerifyEmail = () => {
  const { data: user } = useUser();

  useEffect(() => {
    const intervalId = setInterval(() => {
      user?.reload();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { mutate } = useLogout();
  const { mutate: mutateSendEmailVerification, isLoading } =
    useSendEmailVerification();

  const handleLogout = async (event: any) => {
    event.preventDefault();
    mutate();
  };

  useAddScriptToHead();

  return (
    <div className="flex items-center justify-center min-h-screen p-5 bg-gray-800 min-w-screen">
      <div className="max-w-xl p-8 text-center text-gray-800 bg-white shadow-xl lg:max-w-3xl rounded-3xl lg:p-12">
        <h3 className="text-2xl">
          Confirm your email address to use all the amazing features Export SDK
          has to offer!
        </h3>
        <h3 className="text-lg">{user?.email}</h3>
        <div className="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-32 h-32 mt-10 mb-10"
          >
            <circle cx={256} cy={256} r={256} fill="#ffad9e" />
            <path
              d="M408.245 179.465L110.761 329.519l180.107 180.107C407.319 493.764 498.866 399.61 510.7 281.924L408.245 179.465z"
              fill="#ff6262"
            />
            <path fill="#273b7a" d="M233.417 325.439h24.533v122.656h-24.533z" />
            <path
              d="M307.021 329.519h-196.26v-98.13c0-40.646 32.951-73.597 73.597-73.597h171.727"
              fill="#b20040"
            />
            <path fill="#940030" d="M356.087 157.793H241.635v171.726h65.386z" />
            <path
              d="M429.684 231.39c0-40.646-32.951-73.597-73.597-73.597s-73.597 32.951-73.597 73.597v98.13h147.196v-98.13h-.002z"
              fill="#273b7a"
            />
            <path
              d="M233.424 214.614a7.759 7.759 0 01-7.758-7.758v-90.372h-41.308a7.759 7.759 0 010-15.516h49.066a7.759 7.759 0 017.758 7.758v98.13a7.758 7.758 0 01-7.758 7.758z"
              fill="#e03a00"
            />
            <path fill="#ffc61b" d="M297.029 220.47h118.105v78.739H297.029z" />
            <path
              fill="#ffedb5"
              d="M415.141 220.472l-59.054 39.371-59.056-39.371z"
            />
          </svg>
        </div>
        <p>
          We're happy you're here. An email has been dispatched to verify your
          email. If you have not received an email you may request to have it
          resent.
        </p>
        <div className="mt-4">
          <GenericButton
            type="button"
            isLoading={isLoading}
            onClick={() => mutateSendEmailVerification()}
          >
            Resend Verification Email
          </GenericButton>
          <p className="mt-4 text-sm">
            Not you?{" "}
            <a
              href="#"
              className="text-blue-600 hover:underline"
              onClick={handleLogout}
            >
              Logout
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
