import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Invite, InviteStatus } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getOrganizationInvites = async (
  token: string,
  organizationId: string | undefined
) => {
  if (!organizationId) {
    return [];
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/invites`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.invites as Invite[];
};

export const useOrganizationInvites = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useQuery(
    ["organization-invites", organizationId],
    () => {
      return getOrganizationInvites(token as string, organizationId);
    },
    {
      enabled: !!organizationId && !!token,
      select: (data) =>
        data.filter((invite) => invite.status === InviteStatus.PENDING),
    }
  );
};
