import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { ApiKey } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";

const deleteApiKeyQuery = async (
  token: string,
  organizationId: string | undefined,
  id: string
): Promise<ApiKey> => {
  if (!organizationId) {
    throw Error("No active organization");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/api-keys/${id}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  const json = await response.json();
  return json.apiKey;
};

export const useDeleteApiKey = () => {
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const { data: token } = useIdToken();
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => deleteApiKeyQuery(token as string, organizationId, id),
    {
      onSuccess: (data) => {
        toast.success("Successfully deleted API key");
        queryClient.invalidateQueries("api-keys");
      },
      onError: () => {
        toast.success("Something bad happened!");
      },
    }
  );
};
