import {
  atom,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from "recoil";
import { TreeItem, getFlatDataFromTree } from "react-sortable-tree";
import {
  addNodeUnderParentElement,
  createNewElement,
  generateDefaultData,
  generateDefaultPageSettings,
  generateId,
} from "../../utils/editor.util";
import { TreeItemTypes } from "../../models/editor.model";
import { chain, defer, keyBy, round, uniq } from "lodash";
import traverse from "traverse";

// import { TreeItemTypes } from '../../models/editor.model';
// import { sampleStructure } from '../../mocks/editor.mock';

export const editorActiveTemplateId = atom<string | undefined>({
  key: "editor-active-template",
  default: undefined,
});

export const isEditorActiveState = selector<boolean>({
  key: "editor-is-active",
  get: ({ get }) => !!get(editorActiveTemplateId),
});

export const elementsState = atom<TreeItem[]>({
  key: "editor-elements",
  // default: sampleStructure
  default: [],
});

export const flatElementsState = selector({
  key: `editor-flat-elements`,
  get: ({ get }) =>
    getFlatDataFromTree({
      treeData: get(elementsState),
      getNodeKey: ({ node }) => node.id,
      ignoreCollapsed: false,
    }),
});

export const flatElementState = selectorFamily({
  key: `editor-flat-element`,
  get:
    (id: string | undefined) =>
    ({ get }) =>
      id
        ? get(flatElementsState).find((item) => item.node.id === id)
        : undefined,
});

export const flatElementIdsState = selector({
  key: "editor-flat-element-ids",
  get: ({ get }) => get(flatElementsState).map((element) => element.node.id),
});

export const activeElementIdState = atom<string | undefined>({
  key: "editor-active-element-id",
  default: undefined,
});

export const hoveredElementIdState = atom<string | undefined>({
  key: "editor-hovered-element-id",
  default: undefined,
});

export const addElementIdState = atom<string | undefined>({
  key: "editor-add-element-id",
  default: undefined,
});

export const dropElementIdState = atom<string | undefined>({
  key: "editor-drop-element-id",
  default: undefined,
});

export const activeSimiliarElementTypeState = atom<TreeItemTypes | undefined>({
  key: "editor-active-similar-type",
  default: undefined,
});

export const additionalElementIdsState = atom<string[]>({
  key: "editor-additional-element-ids",
  default: [],
});

export const elementsDataState = atom<any>({
  key: "editor-elements-data",
  default: {},
});

export const activeElementTypeState = selector<string | undefined>({
  key: "active-element-type-state",
  get: ({ get }) =>
    get(activeElementIdState) &&
    get(flatElementState(get(activeElementIdState))) &&
    get(flatElementState(get(activeElementIdState)))?.node?.type,
});

export const elementDataState = selectorFamily<any, any>({
  key: `editor-element-data`,
  get:
    (id: any) =>
    ({ get }) =>
      (get(elementsDataState) as any)?.[id] ?? {
        styles: null,
        text: "",
        imageUrl: "",
      },
  set:
    (id: any) =>
    ({ set, get }, newValue) =>
      set(elementsDataState, {
        ...get(elementsDataState),
        [id]: newValue,
      }),
});

export const usedColorsSelector = selector<string[]>({
  key: "used-colors-selector",
  get: ({ get }) => {
    const elementsData = get(elementsDataState) as any;
    const colors: string[] = [];

    traverse(elementsData).forEach(function (value) {
      if (
        this.key &&
        ["color", "backgroundColor", "borderColor"].includes(this.key)
      ) {
        colors.push(value);
      }
    });

    return chain(colors)
      .map((color) => color.toUpperCase().replace(/\s/g, ""))
      .filter((color) => color.length === 7 || color.length === 4)
      .uniq()
      .value();
  },
});

export const elementsLayoutState = atom<any>({
  key: "editor-elements-layout",
  default: {},
});

export const elementLayoutState = selectorFamily<any, any>({
  key: "editor-element-layout",
  get:
    (id: any) =>
    ({ get }) =>
      (get(elementsLayoutState) as any)?.[id] ?? {},
  set:
    (id: any) =>
    ({ set, get }, newValue) =>
      set(elementsLayoutState, {
        ...get(elementsLayoutState),
        [id]: newValue,
      }),
});

export const elementPageSettingsState = atom<{
  paddingTop: number;
  paddingLeft: number;
  paddingRight: number;
  paddingBottom: number;
  fontFamily: string;
  backgroundColor?: string;
}>({
  key: `editor-page-settings-state`,
  default: generateDefaultPageSettings(),
});

export const elementPageSettingsPaddingTopSelector = selector<number>({
  key: "editor-page-settings-padding-top",
  get: ({ get }) => get(elementPageSettingsState).paddingTop,
});

export const elementPageSettingsPaddingLeftSelector = selector<number>({
  key: "editor-page-settings-padding-left",
  get: ({ get }) => get(elementPageSettingsState).paddingLeft,
});

export const globalZoomState = atom<{
  zoomRatio: number;
  userZoom: number;
  pdfWidth: number;
  pdfHeight: number;
  adjustedHeight: number;
  adjustedWidth: number;
}>({
  key: "global-zoom-state",
  default: {
    zoomRatio: 1,
    userZoom: 1,
    pdfWidth: 595.28 * 1.25,
    pdfHeight: 841.89 * 1.25,
    adjustedHeight: 1,
    adjustedWidth: 1,
  },
});

export const globalFloatingGridState = atom<{
  rows: number;
  columns: number;
  gridWidth: number;
  gridHeight: number;
  width: number;
  height: number;
}>({
  key: `global-floating-grid-state`,
  default: {
    rows: 30,
    columns: 21,
    gridWidth: 0,
    gridHeight: 0,
    width: 0,
    height: 0,
  },
});

export const elementParentLayoutState = selectorFamily<any, any>({
  key: "element-parent-layout-state",
  get:
    (id: any) =>
    ({ get }) => {
      const parentId = get(flatElementState(id))?.parentNode?.id ?? "*";
      // if (!parentId) {
      //   return undefined;
      // }

      return get(elementLayoutState(parentId));
    },
});

export const editorPagesState = atom<any[]>({
  key: "editor-pages-state",
  default: [{ tree: [], data: {}, pageSettings: {}, id: undefined, name: "" }],
});

export const pagesLengthState = selector<number>({
  key: `editor-pages-length`,
  get: ({ get }) => get(editorPagesState)?.length,
});

export const pageIdsSelector = selector<string[]>({
  key: "editor-page-ids",
  get: ({ get }) => get(editorPagesState)?.map((page) => page.id),
  set: ({ get, set }, newValue) => {
    const pages = get(editorPagesState);
    if (newValue instanceof DefaultValue) {
      return set(editorPagesState, pages);
    }

    set(
      editorPagesState,
      newValue.map((id) => pages.find((page) => page.id === id))
    );
  },
});

export const pageNamesByIdIndexSelector = selector<{ [value: string]: string }>(
  {
    key: "editor-page-names-index",
    get: ({ get }) => {
      const pages = get(editorPagesState);
      return pages.reduce((pv, page) => {
        if (page.id) {
          pv[page.id] = page.name;
        }
        return pv;
      }, {});
    },
  }
);

export const pageNameByIdSelector = selectorFamily<
  string | undefined,
  string | undefined
>({
  key: "editor-page-name-by-id",
  get:
    (pageId) =>
    ({ get }) =>
      pageId && get(pageNamesByIdIndexSelector)?.[pageId],
  set:
    (pageId) =>
    ({ get, set }, newValue) => {
      const pages = get(editorPagesState);
      set(
        editorPagesState,
        pages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                name: newValue,
              }
            : page
        )
      );
    },
});

export const activePageState = atom<number>({
  key: "editor-active-page",
  default: 0,
});

export const isPreviewActiveState = atom<boolean>({
  key: "editor-is-preview-active",
  default: false,
});

export const previewJsonState = atom<any>({
  key: "editor-preview-json",
  default: {},
});

export const isUserActionState = atom<boolean>({
  key: "editor-is-user-action",
  default: false,
});

export const useCreateElement = () => {
  const recoilCallback = useRecoilCallback(
    ({ set, snapshot }) =>
      async (type: TreeItemTypes) => {
        const [activeElementId, addElementId] = await Promise.all([
          snapshot.getPromise(activeElementIdState),
          snapshot.getPromise(addElementIdState),
        ]);
        const usedElementId = addElementId || activeElementId;

        const [
          elements,
          elementsData,
          flatElement,
          globalGrid,
          pageSettings,
          globalZoom,
        ] = await Promise.all([
          snapshot.getPromise(elementsState),
          snapshot.getPromise(elementsDataState),
          snapshot.getPromise(flatElementState(usedElementId)),
          snapshot.getPromise(globalFloatingGridState),
          snapshot.getPromise(elementPageSettingsState),
          snapshot.getPromise(globalZoomState),
        ]);

        const elementId = generateId(type);
        const newNode = createNewElement(type, elementId);

        const updatedElements =
          usedElementId && type !== TreeItemTypes.FLOAT
            ? addNodeUnderParentElement(
                elements,
                flatElement as TreeItem,
                newNode
              )
            : [...elements, newNode];

        const updatedStyles = {} as any;

        if (type === TreeItemTypes.FLOAT) {
          updatedStyles.width =
            (globalGrid.gridWidth * 4) / globalZoom.zoomRatio;
          updatedStyles.height =
            (globalGrid.gridHeight * 4) / globalZoom.zoomRatio;
          updatedStyles.top = 0;
          updatedStyles.left = 0;
          updatedStyles.zIndex = 100;
        } else if (!usedElementId) {
          switch (type) {
            case TreeItemTypes.BOX:
              // updatedStyles.height = round(globalZoom.adjustedHeight / 5, 0);
              // updatedStyles.width = round(globalZoom.adjustedWidth / 5);
              break;

            case TreeItemTypes.ROW:
              // updatedStyles.height = round(globalZoom.adjustedHeight / 5, 0);
              // updatedStyles.width = round(globalZoom.adjustedWidth, 0);
              break;
          }
        } else if (usedElementId && type === TreeItemTypes.BOX) {
          updatedStyles.height = "100%";
          updatedStyles.width = "100%";
        }

        // const updatedStyles =
        // type === TreeItemTypes.FLOAT
        //   ? {
        //       width: `${(100 / globalGrid.columns) * 4}%`,
        //       height: `${(100 / globalGrid.rows) * 4}%`,
        //       top: `0%`,
        //       left: `0%`,
        //       zIndex: 100,
        //     }
        //   : type === TreeItemTypes.BOX
        //   ? {
        //       // height:
        //       //   (globalGrid.height -
        //       //     (pageSettings.paddingTop + pageSettings.paddingBottom) *
        //       //       globalZoom.zoomRatio) /
        //       //   (5 * globalZoom.zoomRatio),
        //       // width:
        //       //   (globalGrid.width -
        //       //     (pageSettings.paddingLeft + pageSettings.paddingRight) *
        //       //       globalZoom.zoomRatio) /
        //       //   (5 * globalZoom.zoomRatio),
        //       height: globalZoom.adjustedHeight / 5,
        //       width: globalZoom.adjustedWidth / 5,
        //     }
        //   : type === TreeItemTypes.ROW
        //   ? {
        //       height: globalZoom.adjustedHeight / 5,
        //       // width: globalGrid.width / (1 * globalZoom.zoomRatio),
        //       width: globalZoom.adjustedWidth,
        //       // width: "100%",
        //     }
        //   : {};

        const elementData = generateDefaultData(type, updatedStyles);

        const updatedElementsData = {
          ...elementsData,
          [elementId]: elementData,
        };

        set(elementsState, updatedElements);
        set(elementsDataState, updatedElementsData);
        defer(() => {
          set(activeElementIdState, elementId);
          set(addElementIdState, undefined);
          set(hoveredElementIdState, undefined);
        });
      },
    []
  );

  return (type: TreeItemTypes) => recoilCallback(type);
};
