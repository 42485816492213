import { useMutation } from "react-query";
import { useFunctions, useUser } from "reactfire";
import { httpsCallable } from "firebase/functions";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { useIdToken } from "../queries/useIdToken";

const handlePortal = async (
  token: string,
  organizationId: string | undefined
) => {
  if (!organizationId) {
    throw Error("No active organization.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/stripe-portal`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        returnUrl: window.location.origin,
      }),
    }
  );

  const json = await response.json();
  return json.link;
};

export const useHandlePortal = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useMutation(() => handlePortal(token as string, organizationId), {
    onSuccess: (link) => {
      window.location.href = link;
    },
  });
};
