import { useRecoilState, useRecoilValue } from "recoil";
import { set } from "monolite";

import InputNumber from "../../../components/InputNumber";
import TBLRGrid from "../../../components/TBLRGrid";
import EditorColorPicker from "../../../components/EditorColorPicker";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";
import { useState } from "react";

const TableStyle = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  return (
    <EditorAccordion label="Table header style">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Background color</span>
          <EditorInfo helpLabel="Background Color" />
        </div>

        <EditorColorPicker
          value={
            elementData?.table?.headerStyles?.backgroundColor
              ? elementData?.table?.headerStyles?.backgroundColor.replace(
                  "#",
                  ""
                )
              : ""
          }
          onChange={(value) =>
            updateElementData(
              set(
                elementData,
                ["table", "headerStyles", "backgroundColor"],
                value !== "transparent" ? `#${value}` : value
              )
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Padding</span>
          <EditorInfo helpLabel="Padding" />
        </div>

        <TBLRGrid
          topValue={elementData.table.headerStyles.paddingTop}
          leftValue={elementData.table.headerStyles.paddingLeft}
          rightValue={elementData.table.headerStyles.paddingRight}
          bottomValue={elementData.table.headerStyles.paddingBottom}
          handleTop={(value) =>
            updateElementData((elementData: any) =>
              set(elementData, ["table", "headerStyles", "paddingTop"], value)
            )
          }
          handleLeft={(value) =>
            updateElementData((elementData: any) =>
              set(elementData, ["table", "headerStyles", "paddingLeft"], value)
            )
          }
          handleRight={(value) =>
            updateElementData((elementData: any) =>
              set(elementData, ["table", "headerStyles", "paddingRight"], value)
            )
          }
          handleBottom={(value) =>
            updateElementData((elementData: any) =>
              set(
                elementData,
                ["table", "headerStyles", "paddingBottom"],
                value
              )
            )
          }
        />

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-6">
          <span className="text-sm text-gray-500">Border bottom</span>
          <EditorInfo helpLabel="Border Bottom" />
        </div>

        <EditorColorPicker
          value={
            elementData?.table?.headerStyles?.borderColor
              ? elementData?.table?.headerStyles?.borderColor.replace("#", "")
              : ""
          }
          onChange={(value) =>
            updateElementData(
              set(
                elementData,
                ["table", "headerStyles", "borderColor"],
                value !== "transparent" ? `#${value}` : value
              )
            )
          }
        />

        <div className="flex items-center mt-4">
          <InputNumber
            value={elementData?.table?.headerStyles?.borderBottomWidth ?? ""}
            onChange={(value) =>
              updateElementData(
                set(
                  elementData,
                  ["table", "headerStyles", "borderBottomWidth"],
                  Number(value)
                )
              )
            }
          />
          <div className="ml-3 text-sm">
            <label className="font-medium text-gray-700">Border width</label>
          </div>
        </div>
      </div>
    </EditorAccordion>
  );
};

export default TableStyle;
