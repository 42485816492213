import { useEffect, useState } from "react";
import { ArrowLeft, Save, UploadCloud } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import { useRecoilValue, useRecoilCallback, useSetRecoilState } from "recoil";
import {
  activePageState,
  editorActiveTemplateId,
  editorPagesState,
  elementPageSettingsState,
  elementsDataState,
  elementsState,
  hoveredElementIdState,
} from "./editor.atom";
import dayjs from "dayjs";

import Pages from "./Pages";
import Structure from "./Structure";
import NewTemplateModal from "./NewTemplateModal";
import { useTemplate } from "../../hooks/queries/useTemplate";
import { useUpdateTemplatePages } from "../../hooks/mutations/useUpdateTemplatePages";
import { useCreateTemplate } from "../../hooks/mutations/useCreateTemplate";
import { checkPageIds } from "../../utils/editor.util";

const LeftSide = () => {
  const navigate = useNavigate();
  const templateId = useRecoilValue(editorActiveTemplateId);
  const setHoveredId = useSetRecoilState(hoveredElementIdState);

  const { isLoading: isLoadingTemplatePages } = useUpdateTemplatePages();

  const { data: template, isFetched, refetch: refetchTemplate } = useTemplate();

  const rehydrateState = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (!template?.pages) return;

        set(editorPagesState, checkPageIds(template?.pages));
        set(elementsState, template?.pages[0].tree);
        set(elementsDataState, template?.pages[0].data);
        set(elementPageSettingsState, template?.pages[0].pageSettings);
      },
    [template]
  );

  useEffect(() => {
    if (isFetched) rehydrateState();
  }, [isFetched]);

  return (
    <>
      <div
        className="h-screen bg-white w-320px flex flex-col flex-0"
        onMouseEnter={() => setHoveredId(undefined)}
      >
        <div className="w-full h-16 flex items-center px-4">
          <button
            className="focus:outline-none"
            onClick={() => navigate("/templates")}
          >
            <ArrowLeft className="text-gray-500" size={20} />
          </button>
          <div className="ml-4">
            <div>
              {templateId === "new" ? null : (
                <span className="text-sm text-gray-800">{template?.name}</span>
              )}
            </div>
            <div className="flex items-center mt-1">
              {template?.updatedAt && (
                <span className="text-xs text-gray-400 mr-2">
                  {`Last saved ${dayjs(template?.updatedAt).format(
                    "MMM DD, LT"
                  )}`}
                </span>
              )}
              {isLoadingTemplatePages ? (
                <UploadCloud
                  size={16}
                  className="text-blue-400 animate-pulse"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-full flex-1 border-t-2 border-gray-50 flex flex-col">
          <Pages />
          <Structure />
        </div>
      </div>
    </>
  );
};

export default LeftSide;
