import { useCallback, useEffect } from "react";
import {
  PlusCircle,
  XCircle,
  Columns,
  Menu,
  List,
  Type,
  Box,
  Image,
} from "react-feather";
import { TreeItem } from "react-sortable-tree";
import { useSetRecoilState } from "recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { TreeItemTypes } from "../../models/editor.model";
import {
  addNodeUnderParentElement,
  createNewElement,
  generateDefaultData,
  generateId,
} from "../../utils/editor.util";
import {
  activeElementIdState,
  addElementIdState,
  elementLayoutState,
  elementsDataState,
  elementsState,
  flatElementState,
  hoveredElementIdState,
  useCreateElement,
} from "./editor.atom";

const AddElement = () => {
  const [addElementId, updateAddElementId] = useRecoilState(addElementIdState);
  const elementLayout = useRecoilValue(elementLayoutState(addElementId));
  const mainLayout = useRecoilValue(elementLayoutState("*"));
  const updateHoveredElementId = useSetRecoilState(hoveredElementIdState);

  const handleCreateElement = useCreateElement();

  useEffect(() => {
    if (addElementId) {
      updateHoveredElementId(undefined);
    }
  }, [addElementId]);

  if (!addElementId) return null;

  return (
    <div
      className="absolute left-0 right-0 w-full"
      style={{
        top:
          (elementLayout.top ?? 0) -
          (mainLayout.top ?? 0) +
          elementLayout.height -
          20,
      }}
    >
      <div className="rounded-md bg-white shadow-md mx-auto w-min">
        <div className="flex py-2 px-4">
          <button>
            <span className="text-red-400">New elements</span>
          </button>
          <button className="ml-4">
            <span className="text-gray-400">Saved elements</span>
          </button>
          <button
            className="ml-auto"
            onClick={() => updateAddElementId(undefined)}
          >
            <XCircle className="text-gray-400" size={20} />
          </button>
        </div>

        <div className="flex items-center p-2">
          <button
            className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
            onClick={() => handleCreateElement(TreeItemTypes.COLUMN)}
          >
            <Columns size={20} />
            <span className="text-sm mt-1">Column</span>
          </button>
          <button
            className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
            onClick={() => handleCreateElement(TreeItemTypes.BOX)}
          >
            <Box size={20} />
            <span className="text-sm mt-1">Box</span>
          </button>
          <button
            className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
            onClick={() => handleCreateElement(TreeItemTypes.TEXT)}
          >
            <Type size={20} />
            <span className="text-sm mt-1">Text</span>
          </button>
          <button
            className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
            onClick={() => handleCreateElement(TreeItemTypes.IMAGE)}
          >
            <Image size={20} />
            <span className="text-sm mt-1">Image</span>
          </button>
          <button
            className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
            onClick={() => handleCreateElement(TreeItemTypes.TABLE)}
          >
            <Menu size={20} />
            <span className="text-sm mt-1">Table</span>
          </button>
          <button
            className="w-24 h-24 rounded-md flex flex-col items-center justify-center text-gray-400 border border-gray-200 m-2 hover:bg-red-100 hover:border-transparent hover:text-red-400"
            onClick={() => handleCreateElement(TreeItemTypes.LIST)}
          >
            <List size={20} />
            <span className="text-sm mt-1">List</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddElement;
