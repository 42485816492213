import barhandles from "barhandles";
import { filterDeep, paths } from "deepdash-es/standalone";
import { chain, get, isString, keys, pick, round, toNumber, zip } from "lodash";
import { map, TreeItem, TreeNode, walk } from "react-sortable-tree";

const TABLE_ROW_STYLE_FIELDS = [
  "backgroundColor",
  "paddingTop",
  "paddingBottom",
  "paddingLeft",
  "paddingRight",
  "marginBottom",
  "borderBottomWidth",
  "borderColor",
];

const TABLE_TEXT_STYLE_FIELDS = [
  "fontSize",
  "fontWeight",
  "lineHeight",
  "textAlign",
  "color",
];

type Styles = {
  [name: string]: number | string | undefined;
};

/**
 * A simple way to proxy the styles that are used for text object
 * during the PDF rendering.
 * @param styles Yoga styles object
 * @returns Cleaned yoga styles object
 */
export const sanitizeTextStyles = (styles: Styles) => {
  const cleanStyles = {
    ...styles,
  };

  if (!!styles?.lineHeight && isString(styles.lineHeight)) {
    const lh = toNumber(styles.lineHeight.replace("px", ""));
    const fs = styles.fontSize as number;
    cleanStyles["lineHeight"] = round(lh / fs, 1);
    // cleanStyles["lineHeight"] = undefined;
  }

  return cleanStyles;
};

/**
 * A simple way ot proxy the styles that are used for text objects
 * within a table during the PDF rendering.
 * @param styles Yoga styles object
 * @returns Cleaned yoga styles object
 */
export const santizeTableStyles = (styles: Styles) => {
  const cleanStyles = {
    ...styles,
  };

  if (!!styles?.lineHeight && isString(styles.lineHeight)) {
    const lh = toNumber(styles.lineHeight.replace("px", ""));
    const fs = styles.fontSize as number;
    cleanStyles["lineHeight"] = round(lh / fs, 1);
  }

  return cleanStyles;
};

/**
 * Picks a subset of fields from a yoga styles object that are used
 * for the table row. The returned object is sanitized before being
 * returned.
 * @param styles Yoga styles object
 * @returns Cleaned yoga styles object
 */
export const getTableRowStyles = (styles: Styles) => {
  if (!styles) return {};
  const rowStyles = pick(styles, TABLE_ROW_STYLE_FIELDS);

  return santizeTableStyles(rowStyles);
};

/**
 * Picks a subset of fields from a yoga styles object that are used
 * for the text within a table. The returned object is sanitized
 * before being returned.
 * @param styles Yoga styles object
 * @returns Cleaned yoga styles object
 */
export const getTableTextStyles = (styles: Styles) => {
  if (!styles) return {};
  const textStyles = pick(styles, TABLE_TEXT_STYLE_FIELDS);

  return santizeTableStyles(textStyles);
};

/**
 * Takes a pages array and returns an empty json object used to template
 * @param pages list of page items
 * @returns JSON object of variables within the pages
 */
export const getTemplateJsonStructure = (pages: any[]) => {
  // Combined data objects from all pages
  const combinedData: Record<string, any> = chain(pages)
    .flatMap("data")
    .value()
    .reduce((acc: Record<string, any>, dataObject) => {
      return {
        ...acc,
        ...dataObject,
      };
    }, {});

  // Get tree ids minus list leafs
  // Those needs to be specially taken care of
  const filteredTreeIds = pages
    .map((page) =>
      map({
        treeData: page.tree,
        getNodeKey: ({ node }) => node.id,
        ignoreCollapsed: false,
        callback: ({ node }: TreeNode) =>
          node?.id?.includes("list") ? {} : node,
      })
    )
    .map((tree) => {
      const ids: string[] = [];
      walk({
        treeData: tree,
        getNodeKey: ({ node }) => node?.id,
        ignoreCollapsed: false,
        callback: ({ node }: TreeNode) => ids.push(node?.id),
      });

      return ids.filter(Boolean);
    })
    .flat();

  // Make a combined data object sans lists
  const filteredCombinedData = keys(combinedData)
    .filter((k) => filteredTreeIds.includes(k))
    .map((k) => combinedData[k]);

  // Look for handlebar statements within combined data
  const filtered = filterDeep(
    filteredCombinedData,
    (value) => isString(value) && value.includes("{{") && value.includes("}}")
  );

  // Export the handlebar statements
  const statements = paths(filtered)
    .filter((p) => !p.includes("list"))
    .map((p) => get(filtered, p));

  // Extract the handlebar variables
  // Reduce to single object with default of ""
  const variables = chain(statements)
    .map(barhandles.extractSchema)
    // .flatMap((obj) => keys(obj).filter((field) => !field.includes("_")))
    .flatMap((obj) => keys(obj).filter((field) => !field.startsWith("_")))
    .sort()
    .reduce((pv: any, i: string) => {
      pv[i] = "";
      return pv;
    }, {})
    .value();

  // Now extract out the tables from the raw pages
  const tableFiltered = filterDeep(
    pages,
    (value) => isString(value) && value.includes(":table")
  );

  // Find the paths and then their respective ids
  // Pull out each table's accessor and column accessors
  const tablePaths = paths(tableFiltered);
  const tableIds = tablePaths.map((p) => get(tableFiltered, p));
  const tableAccessors = tableIds.map(
    (tableId) => combinedData?.[tableId]?.table?.accessor
  );
  const tableFields = tableIds.map((tableId) =>
    chain(combinedData?.[tableId]?.table?.columnIds)
      .map((id) => combinedData?.[tableId]?.table?.columns?.[id]?.accessor)
      .reduce((pv: any, i: string) => {
        pv[i] = "";
        return pv;
      }, {})
      .value()
  );

  // Zip together the table accessors with the column accessors
  // Should product a single object keyed by the accessors with
  // an object of column accessors defaulted to ""
  const tableJson = zip(tableAccessors, tableFields)
    .map((combined) => ({
      [combined[0]]: [combined[1]],
    }))
    .reduce((pv, i) => {
      pv = {
        ...i,
      };
      return pv;
    }, {});

  // Now pull out the lists from the raw pages
  const filteredTreeLists = pages
    .map((page) => {
      const trees: TreeItem[] = [];
      const listIds: string[] = [];

      // First walk each page to pull out all the lists
      walk({
        treeData: page.tree,
        getNodeKey: ({ node }) => node?.id,
        ignoreCollapsed: false,
        callback: ({ node }: TreeNode) => {
          if (node?.type === "list") {
            trees.push(node);
          }
        },
      });

      // Iterate over each list and pull out the id
      trees.forEach((tree) => {
        walk({
          treeData: [tree],
          getNodeKey: ({ node }) => node?.id,
          ignoreCollapsed: false,
          callback: ({ node }: TreeNode) => listIds.push(node?.id),
        });
      });

      return listIds;
    })
    .filter((ids) => ids.length)
    .map((listIds) => {
      // Create a subset of data from the combined data of
      // the list and all the elements underneath
      const subset = listIds.reduce((pv: any, i) => {
        pv[i] = combinedData[i];
        return pv;
      }, {});

      // Find the main id of the list
      // and use it to pull out the list accessor
      const mainId = listIds.find((id) => id.includes(":list"));
      const accessor = mainId && subset?.[mainId]?.list.accessor;

      // Go through the subset data to find handlebar statements
      const subsetFiltered = filterDeep(
        subset,
        (value) =>
          isString(value) && value.includes("{{") && value.includes("}}")
      );

      // Pull out the handlebar statements
      const subsetStatements = paths(subsetFiltered).map((p) =>
        get(subsetFiltered, p)
      );

      // Extract the variables from the handle bar statements
      // and reduce to a single object with each field defaulted to ""
      const subsetVariables = chain(subsetStatements)
        .map(barhandles.extractSchema)
        .flatMap((obj) => keys(obj).filter((field) => !field.includes("_")))
        .sort()
        .reduce((pv: any, i: string) => {
          pv[i] = "";
          return pv;
        }, {})
        .value();

      // return an object keyed by the list accessor to any handlebar items within
      return {
        [accessor]: [subsetVariables],
      };
    })
    .reduce((pv, i) => {
      // combine the objects into one
      pv = {
        ...i,
      };
      return pv;
    }, {});

  // return a json object from the three operations above
  return {
    ...variables,
    ...tableJson,
    ...filteredTreeLists,
  };
};
