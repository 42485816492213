import { useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  additionalElementIdsState,
  elementDataState,
  elementsDataState,
} from "../../layouts/Editor/editor.atom";
import { set } from "monolite";
import { flatten, flattenDeep, isArray } from "lodash";

/**
 * This will take either a singular path or array of paths.
 * If it's an array of paths, it will update each path
 * @param data base object that will be updated
 * @param path either an array of strings for the path or the nested string of paths
 * @param value update value
 * @returns updated object
 */
const setHelper = (data: any, path: string[] | string[][], value: any) => {
  if (isArray(path[0])) {
    return (path as string[][]).reduce((pv, singular, index) => {
      pv = set(pv, singular, value);
      return pv;
    }, data as any);
  } else {
    return set(data, path as string[], value);
  }
};

export const useUpdateElementStyles = (activeElementId: string | undefined) => {
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  const additionalElementIds = useRecoilValue(additionalElementIdsState);
  const setElementsData = useSetRecoilState(elementsDataState);

  const updateStyles = useCallback(
    (path: string[] | string[][], value: any) => {
      if (!activeElementId) {
        return;
      }

      if (!additionalElementIds.length) {
        updateElementData((elementData: any) =>
          setHelper(elementData, path, value)
        );
      } else {
        // TODO: Possible optimization so we don't have to iterate through each id
        setElementsData((elementsData: any) => {
          const updatePaths: string[][] = [];

          [...additionalElementIds, activeElementId].forEach((elementId) => {
            const paths = isArray(path[0]) ? path : [path];
            paths.forEach((individualPath) => {
              updatePaths.push([elementId, ...(individualPath as string[])]);
            });
          });

          return setHelper(elementsData, updatePaths, value);

          // return [...additionalElementIds, activeElementId].reduce(
          //   (pv, id, index) => {
          //     pv = setHelper(
          //       index === 0 ? elementsData : pv,
          //       [id, ...path],
          //       value
          //     );
          //     return pv;
          //   },
          //   {} as any
          // );
        });
      }
    },
    [activeElementId, additionalElementIds]
  );

  return updateStyles;
};
