import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { ReactComponent as HomeOutlined } from "../../assets/icons/outlined/Home.svg";
import { ReactComponent as PaperOutlined } from "../../assets/icons/outlined/Paper.svg";
import { ReactComponent as FilterOutlined } from "../../assets/icons/outlined/Filter.svg";
import { ReactComponent as UsersOutlined } from "../../assets/icons/outlined/Users.svg";
import { ReactComponent as SettingOutlined } from "../../assets/icons/outlined/Setting.svg";
import { ReactComponent as WalletOutlined } from "../../assets/icons/outlined/Wallet.svg";
import { ReactComponent as InfoOutlined } from "../../assets/icons/outlined/Info-Circle.svg";
import { colors } from "../../theme";

import Link from "./Link";
import Sublink from "./Sublink";
import { useRecoilValue } from "recoil";
import { isEditorActiveState } from "../../layouts/Editor/editor.atom";

const VARIANTS = {
  open: { height: "auto", transition: { type: "tween" } },
  collapsed: { height: 0, transition: { type: "tween" } },
};

const DIV_VARIANTS = {
  collapsed: { opacity: 0.5, transition: { type: "tween" } },
  open: { opacity: 1, transition: { type: "tween" } },
};

const SideNavigation = () => {
  const { pathname } = useLocation();
  const isEditorActive = useRecoilValue(isEditorActiveState);

  const showSettings = pathname.includes("/settings");
  const showSubscription = pathname.includes("/subscription");

  if (isEditorActive) {
    return null;
  }

  return (
    <div className="fixed top-16 bottom-0 left-0 bg-white w-60 overflow-y-auto py-6 px-8 border-r border-gray-100">
      <Link
        label="Home"
        icon={<HomeOutlined color={colors.content} />}
        activeIcon={<HomeOutlined color={"white"} />}
        path="/"
      />

      <Link
        label="Templates"
        icon={<PaperOutlined color={colors.content} />}
        activeIcon={<PaperOutlined color={"white"} />}
        path="/templates"
      />

      <Link
        label="Debug Console"
        icon={<FilterOutlined color={colors.content} />}
        activeIcon={<FilterOutlined color={"white"} />}
        path="/console"
      />

      <Link
        label="Collaborators"
        icon={<UsersOutlined color={colors.content} />}
        activeIcon={<UsersOutlined color={"white"} />}
        path="/collaborators"
      />

      <Link
        label="Settings"
        icon={<SettingOutlined color={colors.content} />}
        activeIcon={<SettingOutlined color={"white"} />}
        path="/settings"
      />
      <AnimatePresence initial={false}>
        {showSettings && (
          <motion.section
            key="settings"
            className="overflow-y-hidden"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={VARIANTS}
          >
            <motion.div variants={DIV_VARIANTS} transition={{ duration: 0.8 }}>
              <Sublink path="/settings" label="Organization settings" />
              <Sublink path="/settings/keys" label="API keys" />
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>

      <Link
        label="Subscription"
        icon={<WalletOutlined color={colors.content} />}
        activeIcon={<WalletOutlined color={"white"} />}
        path="/subscription"
      />
      <AnimatePresence initial={false}>
        {showSubscription && (
          <motion.section
            key="subscription"
            className="overflow-y-hidden"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={VARIANTS}
          >
            <motion.div variants={DIV_VARIANTS} transition={{ duration: 0.8 }}>
              <Sublink path="/subscription" label="Current plan" />
              {/* <Sublink path="/subscription/manage" label="Manage payment" /> */}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>

      <a
        className={`flex bg-white items-center rounded-md py-2 mt-3 text-gray-600`}
        href="https://docs.exportsdk.com"
        target="_blank"
      >
        <InfoOutlined color={colors.content} />
        <div className="flex-1">
          <span className="ml-2 text-sm">Docs</span>
        </div>
      </a>
    </div>
  );
};

export default SideNavigation;
