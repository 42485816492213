import { FirebaseError } from "@firebase/util";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useUser } from "reactfire";

enum FirebaseErrorCode {
  WeakPassword = "auth/weak-password",
  WrongPassword = "auth/wrong-password",
  TooManyRequests = "auth/too-many-requests",
}

class UpdatePasswordError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UpdatePasswordError";
  }
}

export const useUpdatePassword = () => {
  const { data: user } = useUser();

  return useMutation(
    async ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      if (!user?.email) {
        throw new UpdatePasswordError(
          "Failed to update password, invalid account"
        );
      }

      try {
        const cred = EmailAuthProvider.credential(user.email, currentPassword);
        await reauthenticateWithCredential(user, cred);
      } catch (error) {
        if (!(error instanceof FirebaseError)) {
          throw error;
        }

        if (error.code === FirebaseErrorCode.WrongPassword) {
          throw new UpdatePasswordError("Invalid current password supplied");
        } else if (error.code === FirebaseErrorCode.TooManyRequests) {
          throw new UpdatePasswordError("Too many failed requests");
        } else {
          throw error;
        }
      }

      try {
        await updatePassword(user, newPassword);
        return;
      } catch (error) {
        if (
          error instanceof FirebaseError &&
          error.code === FirebaseErrorCode.WeakPassword
        ) {
          const message = parseFirebaseErrorMessage(error);
          if (message) {
            throw new UpdatePasswordError(message);
          }
        }

        throw error;
      }
    },
    {
      onSuccess: () => {
        toast.success("Password successfully updated!");
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    }
  );
};

function parseFirebaseErrorMessage(error: FirebaseError): string | null {
  const regEx = new RegExp(`Firebase: (.+) \\(${error.code}\\)\\.`, "i");
  const result = regEx.exec(error.message);

  if (result === null) {
    return null;
  } else {
    return result[1];
  }
}
