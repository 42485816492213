import { useEffect } from "react";
import { useNavigate } from "react-router";

export const RedirectComponent = ({ to }: { to: string }) => {
  const navigation = useNavigate();
  useEffect(() => {
    navigation(to);
  }, []);
  return null;
};
