import { useEffect } from "react";
import { motion } from "framer-motion";
import { useDebounce, useMeasure } from "react-use";
import { useRecoilValue } from "recoil";
import {
  activeElementIdState,
  elementPageSettingsPaddingLeftSelector,
  elementPageSettingsPaddingTopSelector,
  elementPageSettingsState,
  elementsState,
  globalZoomState,
} from "./editor.atom";
import { round } from "lodash";

import { ElementsContainer } from "./Element";
import Overlay from "./Overlay";
import { useRecoilState } from "recoil";
import { useSetRecoilState } from "recoil";

const CONTAINER_PADDING = 44;

const Main = () => {
  const [ref, { width, height }] = useMeasure();

  const pageSettingsPaddingTop = useRecoilValue(
    elementPageSettingsPaddingTopSelector
  );
  const pageSettingsPaddingLeft = useRecoilValue(
    elementPageSettingsPaddingLeftSelector
  );
  const [globalZoom, setGlobalZoom] = useRecoilState(globalZoomState);
  const setActiveId = useSetRecoilState(activeElementIdState);

  // useEffect(() => {
  //   const zoomRatio = round(width / globalZoom.pdfWidth, 2);

  //   setGlobalZoom({
  //     ...globalZoom,
  //     zoomRatio,
  //     adjustedHeight: height / zoomRatio - CONTAINER_PADDING,
  //     adjustedWidth: width / zoomRatio - CONTAINER_PADDING,
  //   });
  // }, [width, height, pageSettingsPaddingTop, pageSettingsPaddingLeft]);

  useDebounce(
    () => {
      // const zoomRatio = round(width / globalZoom.pdfWidth, 8);
      const zoomRatio = width / globalZoom.pdfWidth;

      setGlobalZoom({
        ...globalZoom,
        zoomRatio,
        adjustedHeight: height / zoomRatio - CONTAINER_PADDING,
        adjustedWidth: width / zoomRatio - CONTAINER_PADDING,
      });
    },
    250,
    [width, height, pageSettingsPaddingTop, pageSettingsPaddingLeft]
  );
  console.log(
    globalZoom.pdfWidth,
    globalZoom?.zoomRatio && `${globalZoom.zoomRatio * 100}%`
  );

  return (
    <>
      <div
        id="main-scrollable"
        className="flex-1 w-full relative overflow-y-auto bg-gray-100"
        onClick={() => setActiveId(undefined)}
      >
        <div
          ref={ref as any}
          className="absolute pb-8"
          style={{
            top: 40,
            left: 40,
            right: 40,
          }}
        >
          <motion.div
            className="bg-white cursor-pointer relative"
            style={{
              height: globalZoom.pdfHeight,
              width: globalZoom.pdfWidth,
            }}
            animate={{
              scale: globalZoom?.zoomRatio ?? 1,
              originX: 0,
              originY: 0,
            }}
          >
            <ElementsContainer />
          </motion.div>
        </div>

        <Overlay />
      </div>
    </>
  );
};

export default Main;
