import { useMutation } from "react-query";
import { useUser } from "reactfire";
import { updateEmail, User } from "firebase/auth";
import { useSendEmailVerification } from "./useSendEmailVerification";

const updateUser = (user: User, email: string) => updateEmail(user, email);

export const useUpdateUserEmail = () => {
  const user = useUser();
  const { mutate: mutateSendEmailVerification } = useSendEmailVerification();

  return useMutation((email: string) => updateUser(user.data as User, email), {
    onSuccess: () => {
      mutateSendEmailVerification();
    },
  });
};
