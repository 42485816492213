import "typeface-abril-fatface";
import "typeface-alegreya";
import "typeface-archivo";
import "typeface-arvo";
import "typeface-b612";
import "typeface-biorhyme";
import "typeface-cardo";
import "typeface-concert-one";
import "typeface-cormorant";
import "typeface-crimson-text";
import "typeface-exo-2";
import "typeface-fira-sans";
import "typeface-fjalla-one";
import "typeface-frank-ruhl-libre";
import "typeface-ibm-plex-serif";
import "typeface-karla";
import "typeface-lato";
import "typeface-lora";
import "typeface-merriweather";
import "typeface-montserrat";
import "typeface-noto-sans";
import "typeface-nunito";
import "typeface-old-standard-tt";
import "typeface-open-sans";
import "typeface-oswald";
import "typeface-oxygen";
import "typeface-playfair-display";
import "typeface-poppins";
import "typeface-pt-sans";
import "typeface-pt-serif";
import "typeface-rakkas";
import "typeface-roboto";
import "typeface-rubik";
import "typeface-source-sans-pro";
import "typeface-spectral";
import "typeface-titillium-web";
import "typeface-ubuntu";
import "typeface-varela";
import "typeface-vollkorn";
import "typeface-work-sans";
import "typeface-yatra-one";

import Header from "./Header";
import Main from "./Main";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import Preview from "./Preview";
import { useEffect } from "react";
import { useSetRecoilState, useRecoilCallback } from "recoil";
import {
  editorActiveTemplateId,
  elementsState,
  activeElementIdState,
  hoveredElementIdState,
  addElementIdState,
  dropElementIdState,
  elementsDataState,
  elementsLayoutState,
  elementPageSettingsState,
  editorPagesState,
  activePageState,
  isPreviewActiveState,
  previewJsonState,
} from "./editor.atom";
import { useParams } from "react-router-dom";

const Editor = () => {
  const { id } = useParams();
  const setActiveTemplateId = useSetRecoilState(editorActiveTemplateId);
  const resetRecoil = useRecoilCallback(({ reset }) => () => {
    reset(editorActiveTemplateId);
    reset(elementsState);
    reset(activeElementIdState);
    reset(hoveredElementIdState);
    reset(addElementIdState);
    reset(dropElementIdState);
    reset(elementsDataState);
    reset(elementsLayoutState);
    reset(elementPageSettingsState);
    reset(editorPagesState);
    reset(activePageState);
    reset(isPreviewActiveState);
    reset(previewJsonState);
  });

  useEffect(() => {
    setActiveTemplateId(id);
    return () => resetRecoil();
  }, [id]);

  return (
    <>
      <div className="flex">
        <LeftSide />

        <div className="flex-1 flex flex-col">
          <Header />
          <Main />
        </div>

        <RightSide />
      </div>

      <Preview />
    </>
  );
};

export default Editor;
