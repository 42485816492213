import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Template } from "../../models/api.model";
import { useIdToken } from "./useIdToken";

const getTemplates = async (
  token: string,
  organizationId: string | undefined
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/templates`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  const json = await response.json();
  return json.templates as Template[];
};

export const useTemplates = () => {
  const { data: token } = useIdToken();
  const organizationId = useRecoilValue(activeOrganizationIdState);

  return useQuery(
    ["templates", organizationId],
    () => {
      return getTemplates(token as string, organizationId);
    },
    { enabled: !!organizationId && !!token }
  );
};
