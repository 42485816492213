import { ReactNode, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ChevronDown } from "react-feather";
import { useRecoilValue } from "recoil";
import {
  activeElementIdState,
  activeElementTypeState,
} from "../../layouts/Editor/editor.atom";
import { TreeItemTypes } from "../../models/editor.model";

interface EditorAccordionProps {
  children: ReactNode;
  label: string;
}

const EditorAccordion = ({ children, label }: EditorAccordionProps) => {
  const [animationState, setAnimationState] = useState("closed");
  const activeElementId = useRecoilValue(activeElementIdState);
  const activeElementType = useRecoilValue(activeElementTypeState);

  useEffect(() => {
    if (activeElementType === TreeItemTypes.IMAGE && label === "Image") {
      setAnimationState("open");
    }
    if (activeElementType === TreeItemTypes.TEXT && label === "Text") {
      setAnimationState("open");
    }
  }, [activeElementId]);

  return (
    <motion.div initial="closed" animate={animationState}>
      <button
        className="focus:outline-none flex w-full items-center justify-between bg-gray-50 py-2 px-4 border-b-2 border-gray-100"
        onClick={() =>
          setAnimationState(animationState === "closed" ? "open" : "closed")
        }
      >
        <span className="uppercase text-xs font-bold text-gray-400 tracking-widest">
          {label}
        </span>
        <motion.div
          className="focus:outline-none"
          variants={{
            closed: {
              rotate: 0,
            },
            open: {
              rotate: 180,
            },
          }}
          transition={{
            type: "tween",
          }}
        >
          <ChevronDown className="text-gray-500" size={20} />
        </motion.div>
      </button>
      <motion.div
        className="overflow-hidden"
        variants={{
          closed: {
            height: 0,
          },
          open: {
            height: "auto",
          },
        }}
        transition={{
          type: "tween",
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default EditorAccordion;
