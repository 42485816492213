import { useEffect } from "react";

export const useAddScriptToHead = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      const adwords = document.createElement("script");
      const adwords2 = document.createElement("script");
      const adEvent = document.createElement("script");
      const adwords2Inner = document.createTextNode(
        "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-994015925');"
      );
      const adEventContent = document.createTextNode(
        "gtag('event', 'conversion', {'send_to': 'AW-994015925/4IniCPzEw_oBELX1_dkD'});"
      );
      const simpleAnalytics = document.createElement("script");
      const simpleAnalytics2 = document.createElement("noscript");
      const simpleAnalytics2Inner = document.createElement("img");

      adwords.src = "https://www.googletagmanager.com/gtag/js?id=AW-994015925";
      adwords.type = "text/javascript";
      adwords.defer = true;
      adwords2.appendChild(adwords2Inner);
      adEvent.appendChild(adEventContent);

      simpleAnalytics.src = "https://sa.exportsdk.com/latest.js";
      // simpleAnalytics["data-collect-dnt"] = "true"
      simpleAnalytics.defer = true;
      simpleAnalytics2Inner.src = "https://sa.exportsdk.com/noscript.gif";
      simpleAnalytics2Inner.alt = "";
      simpleAnalytics2Inner.referrerPolicy = "no-referrer-when-downgrade";
      simpleAnalytics2.appendChild(simpleAnalytics2Inner);

      document.head.appendChild(adwords);
      document.head.appendChild(adwords2);
      document.head.appendChild(simpleAnalytics);
      document.head.appendChild(simpleAnalytics2);
      document.head.appendChild(adEvent);

      return () => {
        document.head.removeChild(adwords);
        document.head.removeChild(adwords2);
        document.head.removeChild(simpleAnalytics);
        document.head.removeChild(simpleAnalytics2);
        document.head.removeChild(adEvent);
      };
    }
  }, []);
};
