import { xor } from "lodash";
import { useEffect, useRef } from "react";
import { Copy, Edit, MinusCircle, PlusCircle, Trash } from "react-feather";
import SortableTree, { GetNodeKeyFunction } from "react-sortable-tree";
import { useKeyPressEvent } from "react-use";
import { useRecoilState, useSetRecoilState } from "recoil";
import TreeTheme from "../../components/TreeTheme";
import { TreeItemTypes } from "../../models/editor.model";
import { deleteNode, duplicateNode } from "../../utils/editor.util";
import {
  activeElementIdState,
  activeSimiliarElementTypeState,
  additionalElementIdsState,
  elementsDataState,
  elementsState,
  hoveredElementIdState,
} from "./editor.atom";

const getNodeKey: GetNodeKeyFunction = ({ node }) => node.id;

function getNodeHTMLIdAttribute(nodeId: string): string {
  return `tree-node-${nodeId}`;
}

const Structure = () => {
  const [elements, updateElements] = useRecoilState(elementsState);
  const updateElementsData = useSetRecoilState(elementsDataState);
  const [activeElementId, updateActiveElementId] =
    useRecoilState(activeElementIdState);
  const [hoveredElementId, updateHoveredElementId] = useRecoilState(
    hoveredElementIdState
  );
  const [additionalElementIds, setAdditionalElementIds] = useRecoilState(
    additionalElementIdsState
  );

  const [activeSimilar, setActiveSimilar] = useRecoilState(
    activeSimiliarElementTypeState
  );

  const scrollContainer = useRef<HTMLDivElement>(null);

  useKeyPressEvent(
    "Shift",
    (e) => {
      if (!activeElementId) {
        return;
      }
      const type = activeElementId.split(":")[1] as TreeItemTypes;
      if (
        [
          TreeItemTypes.BOX,
          TreeItemTypes.TEXT,
          TreeItemTypes.ROW,
          TreeItemTypes.COLUMN,
        ].includes(type)
      ) {
        setActiveSimilar(type);
      }
    },
    (e) => {
      setActiveSimilar(undefined);
    }
  );

  useEffect(() => {
    setAdditionalElementIds([]);

    if (!scrollContainer.current || !activeElementId) {
      return;
    }

    const treeNode = document.getElementById(
      getNodeHTMLIdAttribute(activeElementId)
    );
    if (!treeNode) {
      return;
    }

    const scrollTop = calculateScrollTop(scrollContainer.current, treeNode);
    if (scrollTop !== null) {
      scrollContainer.current.scrollTop = scrollTop;
    }
  }, [activeElementId, setAdditionalElementIds]);

  return (
    <div className="flex-1 w-full relative border-t-2 border-gray-50">
      <div
        className="absolute top-0 bottom-0 left-0 right-0 overflow-y-scroll p-4"
        ref={scrollContainer}
      >
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm">Structure</span>
        </div>

        <SortableTree
          treeData={elements}
          onChange={(update) => updateElements(update)}
          getNodeKey={getNodeKey}
          theme={TreeTheme as any}
          isVirtualized={false}
          generateNodeProps={(rowInfo) => ({
            id: getNodeHTMLIdAttribute(rowInfo.node.id),
            onMouseEnter: () => {
              updateHoveredElementId(
                rowInfo.node.id !== activeElementId
                  ? rowInfo.node.id
                  : undefined
              );
            },
            onMouseLeave: () => {
              updateHoveredElementId(undefined);
            },
            onClick: (evt: any) => {
              // evt.stopPropagation();
              updateActiveElementId(
                rowInfo.node.id === activeElementId ? null : rowInfo.node.id
              );
            },
            onDragEnd: () => {
              updateActiveElementId(undefined);
            },
            buttons:
              activeSimilar && rowInfo.node.id.includes(`:${activeSimilar}`)
                ? [
                    <button
                      className="mt-1"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setAdditionalElementIds(
                          xor(additionalElementIds, [rowInfo.node.id])
                        );
                      }}
                    >
                      {additionalElementIds.includes(rowInfo.node.id) ? (
                        <MinusCircle
                          size={16}
                          className={
                            rowInfo.node.id === activeElementId
                              ? "text-white"
                              : "text-blue-500"
                          }
                        />
                      ) : (
                        <PlusCircle
                          size={16}
                          className={
                            rowInfo.node.id === activeElementId
                              ? "text-white"
                              : "text-blue-500"
                          }
                        />
                      )}
                    </button>,
                  ]
                : [
                    <button
                      className="mt-1"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updateActiveElementId(
                          rowInfo.node.id === activeElementId
                            ? null
                            : rowInfo.node.id
                        );
                      }}
                    >
                      <Edit
                        size={16}
                        className={
                          rowInfo.node.id === activeElementId
                            ? "text-white"
                            : "text-blue-500"
                        }
                      />
                    </button>,
                    <button
                      className="ml-2 mt-1"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        duplicateNode(
                          rowInfo.node,
                          rowInfo.parentNode,
                          updateElements,
                          updateElementsData,
                          updateActiveElementId
                        );
                      }}
                    >
                      <Copy
                        size={16}
                        className={
                          rowInfo.node.id === activeElementId
                            ? "text-white"
                            : "text-yellow-500"
                        }
                      />
                    </button>,
                    <button
                      className="ml-2 mt-1"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        deleteNode(
                          rowInfo.node,
                          rowInfo.path as string[],
                          updateElements,
                          updateElementsData,
                          updateActiveElementId
                        );
                      }}
                    >
                      <Trash
                        size={16}
                        className={
                          rowInfo.node.id === activeElementId
                            ? "text-white"
                            : "text-red-500"
                        }
                      />
                    </button>,
                  ],
            className:
              rowInfo.node.id === activeElementId
                ? "bg-red-400 text-white px-2 rounded-md"
                : additionalElementIds.includes(rowInfo.node.id)
                ? "bg-red-300 text-white px-2 rounded-md"
                : activeSimilar && rowInfo.node.id.includes(`:${activeSimilar}`)
                ? "border-red-300 px-2 rounded-md border border-dashed"
                : rowInfo.node.id === hoveredElementId
                ? "border-yellow-400 px-2 rounded-md border border-dashed"
                : undefined,
          })}
        />
      </div>
    </div>
  );
};

function calculateScrollTop(
  parent: HTMLElement,
  child: HTMLElement
): number | null {
  const parentBoundingRec = parent.getBoundingClientRect();
  const childBoundingRec = child.getBoundingClientRect();

  const childAboveParent =
    childBoundingRec.bottom < parentBoundingRec.top ||
    childBoundingRec.top < parentBoundingRec.top;
  const childBelowParent =
    childBoundingRec.top > parentBoundingRec.bottom ||
    childBoundingRec.bottom > parentBoundingRec.bottom;

  const halfOfScrollContainerHeight = parentBoundingRec.height / 2;
  const minScrollTop = 0;
  const maxScrollTop = parent.scrollHeight - parentBoundingRec.height;

  if (childAboveParent) {
    const scrollTop =
      parent.scrollTop -
      (parentBoundingRec.top -
        childBoundingRec.top +
        halfOfScrollContainerHeight);

    return Math.max(scrollTop, minScrollTop);
  } else if (childBelowParent) {
    const scrollTop =
      parent.scrollTop +
      (childBoundingRec.bottom -
        parentBoundingRec.bottom +
        halfOfScrollContainerHeight);

    return Math.min(scrollTop, maxScrollTop);
  } else {
    // If the child is not above or below the parent then it is currently visible, therefore no
    // change is needed to the parents scrollTop.
    return null;
  }
}

export default Structure;
