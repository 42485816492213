import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useMutation } from "react-query";

export function useSendPasswordResetEmail() {
  return useMutation(async (email: string) => {
    const auth = getAuth();

    await sendPasswordResetEmail(auth, email, {
      handleCodeInApp: true,
      url: `${window.location.origin}/reset-password`,
    });
  });
}
