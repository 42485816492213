import { useRecoilValue, useSetRecoilState } from "recoil";
import { Edit } from "react-feather";

import Select from "../../components/Select";
import ZoomDropdown from "./ZoomDropdown";

import Image from "./Configuration/Image";
import Layout from "./Configuration/Layout";
import Position from "./Configuration/Position";
import SizeSpace from "./Configuration/SizeSpace";
import Color from "./Configuration/Color";
import Typography from "./Configuration/Typography";
import TableHeaderStyle from "./Configuration/TableHeaderStyle";
import TableRowStyle from "./Configuration/TableRowStyle";
import TableConfig from "./Configuration/TableConfig";
import ListConfig from "./Configuration/ListConfig";
import PageSettings from "./Configuration/PageSettings";
import { activeElementIdState, hoveredElementIdState } from "./editor.atom";
import { useMemo } from "react";
import { TreeItemTypes } from "../../models/editor.model";
import PreviewButton from "./PreviewButton";
import SaveButton from "./SaveButton";

const RightSide = () => {
  const activeElementId = useRecoilValue(activeElementIdState);
  const activeElementType = useMemo(
    () => activeElementId?.split(":")?.[1] as TreeItemTypes,
    [activeElementId]
  );
  const setHoveredId = useSetRecoilState(hoveredElementIdState);

  return (
    <div
      className="h-screen bg-white w-320px flex flex-col"
      onMouseEnter={() => setHoveredId(undefined)}
    >
      <div className="w-full h-16 flex items-center px-4 space-x-2">
        <PreviewButton />
        {/* <button className="bg-gray-400 text-white px-3 py-2 rounded-2xl mr-2 text-sm leading-5">
          Debug
        </button> */}

        {/* <ZoomDropdown /> */}
        <SaveButton />
      </div>
      <div className="flex-1 w-full border-t-2 border-gray-50 relative">
        {!activeElementId ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 overflow-y-scroll">
            <div className="p-8 mb-4">
              <div className="flex flex-col items-center justify-center mt-12">
                <Edit className="text-gray-400 mb-4" size={24} />
                <span className="text-center text-gray-400">
                  Please select an element to begin editing
                </span>
              </div>
            </div>
            <PageSettings />
          </div>
        ) : (
          <div className="absolute top-0 bottom-0 left-0 right-0 overflow-y-scroll">
            <div className="p-4">
              {TreeItemTypes.ROW === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  Row settings
                </span>
              ) : null}

              {TreeItemTypes.COLUMN === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  Column settings
                </span>
              ) : null}

              {TreeItemTypes.BOX === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  Box settings
                </span>
              ) : null}

              {TreeItemTypes.TEXT === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  Text settings
                </span>
              ) : null}

              {TreeItemTypes.IMAGE === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  Image settings
                </span>
              ) : null}

              {TreeItemTypes.TABLE === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  Table settings
                </span>
              ) : null}

              {TreeItemTypes.LIST === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  List settings
                </span>
              ) : null}
              {TreeItemTypes.FLOAT === activeElementType ? (
                <span className="text-sm font-light text-gray-400">
                  Floating box settings
                </span>
              ) : null}
            </div>

            {TreeItemTypes.TABLE === activeElementType ? <TableConfig /> : null}
            {TreeItemTypes.TABLE === activeElementType ? (
              <TableHeaderStyle />
            ) : null}
            {TreeItemTypes.TABLE === activeElementType ? (
              <TableRowStyle />
            ) : null}
            {TreeItemTypes.LIST === activeElementType ? <ListConfig /> : null}
            {TreeItemTypes.TEXT === activeElementType ? <Typography /> : null}
            {TreeItemTypes.IMAGE === activeElementType ? <Image /> : null}
            {[
              TreeItemTypes.ROW,
              TreeItemTypes.COLUMN,
              TreeItemTypes.BOX,
              TreeItemTypes.FLOAT,
            ].includes(activeElementType) ? (
              <Layout />
            ) : null}

            {[TreeItemTypes.IMAGE, TreeItemTypes.TEXT].includes(
              activeElementType
            ) ? (
              <>
                <Position />
                <SizeSpace />
              </>
            ) : null}

            {[
              TreeItemTypes.ROW,
              TreeItemTypes.COLUMN,
              TreeItemTypes.BOX,
              // TreeItemTypes.TEXT,
            ].includes(activeElementType) ? (
              <>
                <Position />
                <SizeSpace />
                <Color />
              </>
            ) : null}

            {TreeItemTypes.FLOAT === activeElementType ? <Color /> : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default RightSide;
