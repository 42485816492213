import { useMutation, useQueryClient } from "react-query";
import { useProfile } from "../queries/useProfile";
import { useRecoilValue } from "recoil";
import { activeOrganizationIdState } from "../../layouts/Home/home.atom";
import { Invite, Profile } from "../../models/api.model";
import { useIdToken } from "../queries/useIdToken";
import toast from "react-hot-toast";

const createInvite = async (
  token: string,
  profile: Profile | undefined,
  organizationId: string | undefined,
  email: string
): Promise<Invite> => {
  if (!profile || !organizationId) {
    throw Error("Not logged in properly.");
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/invites`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.toLowerCase(),
        invitedById: profile.id,
      }),
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw Error(error.message);
  }

  const json = await response.json();
  return json.invite;
};

export const useCreateInvite = () => {
  const { data: token } = useIdToken();
  const { data: profile } = useProfile();
  const organizationId = useRecoilValue(activeOrganizationIdState);
  const queryClient = useQueryClient();

  return useMutation(
    ({ email }: any) =>
      createInvite(token as string, profile, organizationId, email),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("organization-invites");
        queryClient.invalidateQueries("collaborators");
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    }
  );
};
