import {
  Info,
  MoreHorizontal,
  MoreVertical,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Pause,
  Square,
} from "react-feather";
import { useRecoilState, useRecoilValue } from "recoil";
import { set } from "monolite";

import EditorButton from "../../../components/EditorButton";
import EditorAccordion from "../../../components/EditorAccordion";
import EditorInfo from "../../../components/EditorInfo";

import { activeElementIdState, elementDataState } from "../editor.atom";
import { useState } from "react";
import { useUpdateElementStyles } from "../../../hooks/custom/useUpdateElementStyles";

enum HelpItems {
  DIRECTION = "DIRECTION",
  ALIGN = "ALIGN",
  JUSTIFY = "JUSTIFY",
}

const Layout = () => {
  const [activeHelp, setActiveHelp] = useState<HelpItems>();
  const activeElementId = useRecoilValue(activeElementIdState);
  const [elementData, updateElementData] = useRecoilState(
    elementDataState(activeElementId)
  );

  const updateStyles = useUpdateElementStyles(activeElementId);

  if (!activeElementId) return null;

  return (
    <EditorAccordion label="Layout">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Layout direction</span>
          <EditorInfo helpLabel="Layout Direction" />
        </div>

        <div className="flex mb-2">
          <EditorButton
            iconComponent={
              <MoreHorizontal className="text-gray-600" size={12} />
            }
            label="Row"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "flexDirection"], "row")
              // )
              updateStyles(["styles", "flexDirection"], "row")
            }
            isSelected={elementData.styles.flexDirection === "row"}
          />
          <EditorButton
            iconComponent={<MoreVertical className="text-gray-600" size={12} />}
            label="Column"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "flexDirection"], "column")
              // )
              updateStyles(["styles", "flexDirection"], "column")
            }
            isSelected={elementData.styles.flexDirection === "column"}
          />
        </div>

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Align items</span>
          <EditorInfo helpLabel="Align Items" />
        </div>

        <div className="flex mb-2">
          <EditorButton
            iconComponent={<AlignLeft className="text-gray-600" size={12} />}
            label="Start"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "alignItems"], "flex-start")
              // )
              updateStyles(["styles", "alignItems"], "flex-start")
            }
            isSelected={elementData.styles.alignItems === "flex-start"}
          />
          <EditorButton
            iconComponent={<AlignCenter className="text-gray-600" size={12} />}
            label="Center"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "alignItems"], "center")
              // )
              updateStyles(["styles", "alignItems"], "center")
            }
            isSelected={elementData.styles.alignItems === "center"}
          />
          <EditorButton
            iconComponent={<AlignRight className="text-gray-600" size={12} />}
            label="End"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "alignItems"], "flex-end")
              // )
              updateStyles(["styles", "alignItems"], "flex-end")
            }
            isSelected={elementData.styles.alignItems === "flex-end"}
          />
        </div>

        <div className="border-b-2 border-gray-50 my-6"></div>

        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-500">Justify content</span>
          <EditorInfo helpLabel="Justify Content" />
        </div>

        <div className="flex mb-2 flex-wrap">
          <EditorButton
            iconComponent={<AlignLeft className="text-gray-600" size={12} />}
            label="Start"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "justifyContent"], "flex-start")
              // )
              updateStyles(["styles", "justifyContent"], "flex-start")
            }
            isSelected={elementData.styles.justifyContent === "flex-start"}
          />
          <EditorButton
            iconComponent={<AlignCenter className="text-gray-600" size={12} />}
            label="Center"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "justifyContent"], "center")
              // )
              updateStyles(["styles", "justifyContent"], "center")
            }
            isSelected={elementData.styles.justifyContent === "center"}
          />
          <EditorButton
            iconComponent={<AlignRight className="text-gray-600" size={12} />}
            label="End"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "justifyContent"], "flex-end")
              // )
              updateStyles(["styles", "justifyContent"], "flex-end")
            }
            isSelected={elementData.styles.justifyContent === "flex-end"}
          />
          <EditorButton
            iconComponent={<Pause className="text-gray-600" size={12} />}
            label="Space between"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "justifyContent"], "space-between")
              // )
              updateStyles(["styles", "justifyContent"], "space-between")
            }
            isSelected={elementData.styles.justifyContent === "space-between"}
          />
          <EditorButton
            iconComponent={<Square className="text-gray-600" size={12} />}
            label="Space around"
            onClick={() =>
              // updateElementData((elementData: any) =>
              //   set(elementData, ["styles", "justifyContent"], "space-around")
              // )
              updateStyles(["styles", "justifyContent"], "space-around")
            }
            isSelected={elementData.styles.justifyContent === "space-around"}
          />
        </div>
      </div>
    </EditorAccordion>
  );
};

export default Layout;
